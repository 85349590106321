import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import image1 from "../../assets/images/Products/Payment Link/Payment Link Image Top.png";
import imagelink from "../../assets/images/Products/Payment Link/Payment Link 2 section.png";
import imagelogo1 from "../../assets/images/Products/Payment Gateway/Bank Logo/axis bank.png";
import imagelogo2 from "../../assets/images/Products/Payment Gateway/Bank Logo/hdfc bank.png";
import imagelogo3 from "../../assets/images/Products/Payment Gateway/Bank Logo/icici bank.png";
import imagelogo4 from "../../assets/images/Products/Payment Gateway/Bank Logo/idbi bank.png";
import imagelogo5 from "../../assets/images/Products/Payment Gateway/Bank Logo/indus bank.png";
import imagelogo6 from "../../assets/images/Products/Payment Gateway/Bank Logo/yes bank.png";
import bestService1 from "../../assets/images/svg/awesome-1.svg";
import bestService2 from "../../assets/images/svg/awesome-2.svg";
import bestService3 from "../../assets/images/svg/awesome-3.svg";

import boostYourBussiness1 from "../../assets/images/Products/Payment Link/Payment Link Image Down Link.png";
import paymentGetwayfaq from "../../assets/images/faq-img.png";

import paymentGetwaysimage1 from "../../assets/images/why1.png";
import paymentGetwaysimage2 from "../../assets/images/why2.png";
import paymentGetwaysimage3 from "../../assets/images/why3.png";
import paymentGetwaysimage4 from "../../assets/images/why4.png";
import paymentGetwaysimage5 from "../../assets/images/how.png";

import paymentGetwaysimagetaskshape1 from "../../assets/images/shape/task-shape-1.png";
import paymentGetwaysimagetaskshape2 from "../../assets/images/shape/task-shape-2.png";
import paymentGetwaysimageUser2 from "../../assets/images/user/user-1.png";
import paymentGetwaysimageteamShape from "../../assets/images/shape/team-shape.png";
import Accordion from "react-bootstrap/Accordion";

function Paylink() {
  const sliderOptions = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Easy to Share - Custom & Quick Payment Link | FintechDaddy
        </title>
        <meta
          name="description"
          content="With FintechDaddy you can easily Create payment links & share them
effortlessly,Accept payments from credit card, debit card, net banking, UPI, wallets
etc.
"
        ></meta>
      </Helmet>

      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="">
                <div className="section-heading">
                  <h2 className="text-light">
                    Generate Payment Link & Get Paid Instantly
                  </h2>
                  <p className="text-light">
                    No Website No Worries. Easily Accept Payments With Payment
                    Link. Share Link To Customers via Sms, Whatapp And Get Paid
                    Instantly
                  </p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/Signup">
                    Know More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image">
                <img className="experience-img" src={image1} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="workflow-integration-area ptb-100 bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="task-img">
                <img className="task-img-1" src={imagelink} alt="image" />
                <img
                  className="task-shape-1"
                  src={paymentGetwaysimagetaskshape1}
                  alt="image"
                />
                {/* <img className="task-img-2" src={paymentGetwaysimagetask2} alt="image" />
                <img className="task-img-3" src={paymentGetwaysimagetask3} alt="image" /> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="history">
                <div className="section-heading">
                  <h2 className="text-light">
                    Generate Payment Links With Simple Steps
                  </h2>
                  {/* <p>Sell online without a website. Share Payment Links with customer via SMS, email or Whatsapp and get paid instantly via 120+ payment modes.</p> */}
                </div>
                <div className="">
                  <div className="v-progress">
                    <ul>
                      {/*     <li class="v-progress-item completed">Deepak</li> */}
                      <li className="v-progress-item inprogress">
                        STEP 1<p>Generate Your Payment link</p>
                      </li>
                      <li className="v-progress-item">
                        STEP 2<p>Enter Costumer contact details</p>
                      </li>
                      <li className="v-progress-item">
                        STEP 3<p>Accepts payments</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* shape images */}
        <img
          className="task-shape-2"
          src={paymentGetwaysimagetaskshape2}
          alt="image"
        />
        <img
          className="team-shape"
          src={paymentGetwaysimageteamShape}
          alt="image"
        />
      </div>

      {/* brands */}

      <div className="vbnmr-area pt-4 bg-color-5c5c5c">
        <div className="container">
          <OwlCarousel
            className="vbnmr-slider owl-carousel owl-theme"
            {...sliderOptions}
          >
            {sliderItems.map((item) => (
              <div className="sponsor-logo" key={item.id}>
                <img src={item.image} alt="image" />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>

      <div className="client-say-area client-say-testimonials-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            {/* <span class="subtitle">Our Testimonials</span> */}
            <h2 className="text-light">
              Accept Payment Anytime, Anywhere Use Any Channel As Your Checkout
            </h2>
          </div>
          <div className="row checkout">
            <div className="col-lg-3" src="001.html">
              <div className="say-card">
                <h4 className="text-light">Chat & SMS</h4>
                <p className="text-light">
                  Share payment links via SMS and Get Paid instantly
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-3" src="001.html">
              <div className="say-card">
                <h4 className="text-light">Email</h4>
                <p className="text-light">
                  Give Link On Customer E-mail And Work Smoothly.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-3" src="001.html">
              <div className="say-card">
                <h4 className="text-light">Social Media</h4>
                <p className="text-light">
                  Turn Your Social Site As Payment Checkout Page
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>

            <div className="col-lg-3">
              <div className="say-card" src="001.html">
                <h4 className="text-light">Digital Invoice</h4>
                <p className="text-light">
                  Add links to your invoices and get paid instantly.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Our Best Services Area */}

      {/* Start Boost Your Business */}
      <div className="experience-area pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="section-heading">
              <h2 className="text-light text-center">
                Why Growing Businesses Trust Us?
              </h2>
              {/* <p className="text-light">Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain, but because occasionally circumstances occur</p> */}
            </div>

            <div className="col-lg-6">
              <div className="grow business-experience ms-0 me-4">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-xl-12">
                    <div className="experience-card bg-transparent border border-1 py-2">
                      <div className="heading align-items-center mb-4">
                        {/* <div className="business-img">
                          <img src={boostYourBussiness2} alt="image" />
                        </div> */}
                        <h4 className="ps-0 text-light fs-4">
                          Easy Integration
                        </h4>
                      </div>
                      <p>
                        Create orders, and generate payment links in bulk by
                        uploading a simple .csv or .xlsx file. Save Time. Create
                        Payment Link Share And Get Paid
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-xl-12">
                    <div className="experience-card bg-transparent border border-1 py-2">
                      <div className="heading align-items-center mb-4">
                        {/* <div className="business-img">
                          <img src={boostYourBussiness3} alt="image" />
                        </div> */}
                        <h4 className="ps-0 text-light fs-4">On-Time Report</h4>
                      </div>
                      <p>
                        Get real-time reports on the transactions processed
                        through payment links and Grow Your Business{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-xl-12">
                    <div className="experience-card bg-transparent border border-1 py-2">
                      <div className="heading align-items-center mb-4">
                        {/* <div className="business-img">
                          <img src={boostYourBussiness3} alt="image" />
                        </div> */}
                        <h4 className="ps-0 text-light fs-4">Accessibility</h4>
                      </div>
                      <p>
                        Payment links can be shared via email, text message,
                        social media, or other channels, making them accessible
                        to customers wherever they are.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="experience-image">
                <img
                  className="experience-img"
                  src={boostYourBussiness1}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Boost Your Business */}
      <section className="bg-dark pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">
                    Join Our Partner Program
                  </h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>
                    {" "}
                    Know More <i className="flaticon-right" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>

      {/* Start Our Best Services Area */}
      <div className="best-service-area pt-100 pb-70 bg-color-100f0f benefits">
        <div className="container">
          <div className="section-title">
            <h2 className="text-light">Benefits Of Payment Links</h2>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
          </div>
          <div className="row links">
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card  text-center">
                <div className="awesome-image">
                  <img src={bestService1} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">
                    Benefits for Businesses
                  </a>
                </h4>
                <p className="text-light">
                  FintechDaddy Payment Links Option Help businesses streamline
                  their payment collection process, reduce payment processing
                  time, and improve cash flow.
                </p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card two text-center">
                <div className="awesome-image">
                  <img src={bestService2} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">
                    Ease of Use
                  </a>
                </h4>
                <p className="text-light">
                  Payment Link use for both businesses and customers make Life
                  Easy. payment links can be created quickly and easily, and
                  customers can make payments with just a few clicks.
                </p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <img src={bestService3} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">
                    Increased Sales
                  </a>
                </h4>
                <p className="text-light">
                  FintechDaddy offering customers the option to pay using a
                  payment link, businesses can reduce barriers to purchase and
                  increase sales.
                </p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="awesome-card  text-center">
                <div className="awesome-image">
                  <img src={bestService1} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">
                    Security
                  </a>
                </h4>
                <p className="text-light">
                  Payment links is secure, such as encryption, fraud prevention
                  measures, and compliance with industry standards.
                </p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card two text-center">
                <div className="awesome-image">
                  <img src={bestService2} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">
                    Customization
                  </a>
                </h4>
                <p className="text-light">
                  Payment links can be customized to match a business's
                  branding, and businesses can add details such as payment
                  terms, taxes, and shipping costs.
                </p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <img src={bestService3} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">
                    Integration
                  </a>
                </h4>
                <p className="text-light">
                  FintechDaddy Payment links can be integrated with a business's
                  existing payment systems, such as invoicing software or online
                  marketplaces.
                </p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ */}
      <div className="faq-area ptb-100 bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-image-">
                <img src={paymentGetwayfaq} alt="faq" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        What is a payment link?
                      </Accordion.Header>
                      <Accordion.Body>
                        A payment link is a URL or hyperlink that allows
                        customers to make a payment online. Payment links can be
                        used to accept various payment types such as credit
                        cards, debit cards, e-wallets, and bank transfers.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        How does a payment link work?
                      </Accordion.Header>
                      <Accordion.Body>
                        A business generates a payment link from FintechDaddy
                        and shares it with the customer via email, text message,
                        social media, or other channels. The customer clicks on
                        the link and is directed to a payment page where they
                        can enter their payment details and complete the
                        transaction.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Is it safe to use payment links?
                      </Accordion.Header>
                      <Accordion.Body>
                        Payment links are typically designed with security in
                        mind, using encryption and other security measures to
                        protect sensitive data. However, businesses should
                        ensure they are using a reputable payment gateway
                        provider Like FintechDaddy and follow best practices for
                        securing customer data.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Are there any fees associated with using payment links?
                      </Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy typically charge fees for processing
                        payments, which can include transaction fees, monthly
                        fees, or other charges. The fee structure can vary
                        depending on the provider and the specific services
                        offered.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Can payment links be customized with branding or design
                        elements?
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes, payment links can often be customized with business
                        branding, logos, and other design elements to provide a
                        consistent and professional look and feel.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Are payment links suitable for all businesses?
                      </Accordion.Header>
                      <Accordion.Body>
                        Payment links may not be suitable for all businesses or
                        transactions. Businesses may need to consider factors
                        such as the types of payments they can accept or the
                        level of customization they require when using payment
                        links.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paylink;
