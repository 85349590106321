import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import {Helmet} from "react-helmet";
import Tabs from "react-bootstrap/Tabs";
import styled from "styled-components";
//import "bootstrap/dist/css/bootstrap.css";
// import "../assets/developer/assets/plugins/bootstrap/css/bootstrap.min.css"
import { Carousel } from '@trendyol-js/react-carousel';
//import Editor from 'react-simple-code-editor';
import IntegrationSidebar from '../components/Integrationsidebar'

//import "../assets/developer/assets/plugins/bootstrap/css/bootstrap.min.css";
//import "../../node_modules/font-awesome/css/font-awesome.min.css";
import "../assets/developer/assets/plugins/prism/prism.css";
import "../assets/developer/assets/plugins/elegant_font/css/style.css";
// import "../assets/developer/assets/plugins/simplelightbox/simple-lightbox.min.css";
import "../assets/developer/assets/css/styles.css";

function Integration() {
    const CustomTabs = styled(Tabs)`
    border-bottom: 0.5px solid gray;
    color: white;
  `;
    const CustomTab = styled(Tab)`
    color: gray;
    font-size: 19px;

    :active {
      color: black;
      border-bottom: 3px solid blue;
    }
  `;
    const obj = `{ 
        "billing_person_name": "Thu Pham Anh",
        "fulfillment_status": "1",
        "payment_status": "1",
        "order_number": "sndbdnjkfdjk",
        "tax": "0",
        "type": "3",
        "total": 200,
        "phone": "7370672007",
        "subtotal": 200,
        "platform_id": "0",
        "return_url": "https://www.google.com",
        "currency": "INR",
        "email": "test@gmail.com"


    }`;


    const curl = `<?php

    $curl = curl_init();
    
    curl_setopt_array($curl, [
      CURLOPT_URL => "https://api.fintechdaddy.in/partner/orders",
      CURLOPT_RETURNTRANSFER => true,
      CURLOPT_ENCODING => "",
      CURLOPT_MAXREDIRS => 10,
      CURLOPT_TIMEOUT => 30,
      CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
      CURLOPT_CUSTOMREQUEST => "POST",
      CURLOPT_POSTFIELDS => json_encode([
        'billing_person_name' => 'Thu Pham Anh',
        'fulfillment_status' => '1',
        'payment_status' => '1',
        'order_number' => 'sndbdnjkfdjk',
        'tax' => '0',
        'type' => '3',
        'total' => 200,
        'phone' => '7370672007',
        'subtotal' => 200,
        'platform_id' => '0',
        'return_url' => 'https://www.google.com',
        'currency' => 'INR',
        'email' => 'test@gmail.com'
      ]),
      CURLOPT_HTTPHEADER => [
        "Accept: application/json",
        "Authorization: ",
        "Content-Type: application/json"
      ],
    ]);
    
    $response = curl_exec($curl);
    $err = curl_error($curl);
    
    curl_close($curl);
    
    if ($err) {
      echo "cURL Error #:" . $err;
    } else {
      echo $response;
    }'
    
}`;


    const GuzzleHttp = `<?php

$client = new \GuzzleHttp\Client();

$response = $client->request('POST', 'https://api.fintechdaddy.in/partner/orders', [
  'body' => '{
  "billing_person_name": "Thu Pham Anh",
  "fulfillment_status": "1",
  "payment_status": "1",
  "order_number": "sndbdnjkfdjk",
  "tax": "0",
  "type": "3",
  "total": 200,
  "phone": "7370672007",
  "subtotal": 200,
  "platform_id": "0",
  "return_url": "https://www.google.com",
  "currency": "INR",
  "email": "test@gmail.com"
}',
  'headers' => [
    'Accept' => 'application/json',
    'Authorization' => '',
    'Content-Type' => 'application/json',
  ],
]);

echo $response->getBody();`;

    const NodeJS = `const axios = require('axios').default;

const options = {
  method: 'POST',
  url: 'https://api.fintechdaddy.in/partner/orders',
  headers: {
    Authorization: '',
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  data: {
    billing_person_name: 'Thu Pham Anh',
    fulfillment_status: '1',
    payment_status: '1',
    order_number: 'sndbdnjkfdjk',
    tax: '0',
    type: '3',
    total: 200,
    phone: '7370672007',
    subtotal: 200,
    platform_id: '0',
    return_url: 'https://www.google.com',
    currency: 'INR',
    email: 'test@gmail.com'
  }
};

try {
  const { data } = await axios.request(options);
  console.log(data);
} catch (error) {
  console.error(error);
}`;


    const java = `AsyncHttpClient client = new DefaultAsyncHttpClient();
client.prepare("POST", "https://api.fintechdaddy.in/partner/orders")
  .setHeader("Authorization", "")
  .setHeader("Content-Type", "application/json")
  .setHeader("Accept", "application/json")
  .setBody("{\n  \"billing_person_name\": \"Thu Pham Anh\",\n  \"fulfillment_status\": \"1\",\n  \"payment_status\": \"1\",\n  \"order_number\": \"sndbdnjkfdjk\",\n  \"tax\": \"0\",\n  \"type\": \"3\",\n  \"total\": 200,\n  \"phone\": \"7370672007\",\n  \"subtotal\": 200,\n  \"platform_id\": \"0\",\n  \"return_url\": \"https://www.google.com\",\n  \"currency\": \"INR\",\n  \"email\": \"test@gmail.com\"\n}")
  .execute()
  .toCompletableFuture()
  .thenAccept(System.out::println)
  .join();

client.close();`;


    const CRestSharp = `var client = new RestClient("https://api.fintechdaddy.in/partner/orders");
var request = new RestRequest(Method.POST);
request.AddHeader("Authorization", "");
request.AddHeader("Content-Type", "application/json");
request.AddHeader("Accept", "application/json");
request.AddParameter("application/json", "{\n  \"billing_person_name\": \"Thu Pham Anh\",\n  \"fulfillment_status\": \"1\",\n  \"payment_status\": \"1\",\n  \"order_number\": \"sndbdnjkfdjk\",\n  \"tax\": \"0\",\n  \"type\": \"3\",\n  \"total\": 200,\n  \"phone\": \"7370672007\",\n  \"subtotal\": 200,\n  \"platform_id\": \"0\",\n  \"return_url\": \"https://www.google.com\",\n  \"currency\": \"INR\",\n  \"email\": \"test@gmail.com\"\n}", ParameterType.RequestBody);
IRestResponse response = client.Execute(request);`;


    const python = `import http.client

conn = http.client.HTTPSConnection("api.step2pay.online")

payload = "{\n  \"billing_person_name\": \"Thu Pham Anh\",\n  \"fulfillment_status\": \"1\",\n  \"payment_status\": \"1\",\n  \"order_number\": \"sndbdnjkfdjk\",\n  \"tax\": \"0\",\n  \"type\": \"3\",\n  \"total\": 200,\n  \"phone\": \"7370672007\",\n  \"subtotal\": 200,\n  \"platform_id\": \"0\",\n  \"return_url\": \"https://www.google.com\",\n  \"currency\": \"INR\",\n  \"email\": \"test@gmail.com\"\n}"

headers = {
    'Authorization': "",
    'Content-Type': "application/json",
    'Accept': "application/json"
}

conn.request("POST", "/partner/orders", payload, headers)

res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))`;


    const exampleJson = `{
    "order_id": 123,
    "order_number": "ASDFG",
    "amount": 100,
    "payment_status": 2,
    "payment_tx_id": "QWER12313ERTY",
    "vpa": "success@razorpay",
    "udf1": "TYFyt675RTftfcRR",
    "udf2": "HGSA1111",
    "udf3": "",
    }`








    const checkoutcurl = `<?php

    $curl = curl_init();
    
    curl_setopt_array($curl, [
      CURLOPT_URL => "https://api.fintechdaddy.in/payment/checkout/general",
      CURLOPT_RETURNTRANSFER => true,
      CURLOPT_ENCODING => "",
      CURLOPT_MAXREDIRS => 10,
      CURLOPT_TIMEOUT => 30,
      CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
      CURLOPT_CUSTOMREQUEST => "GET",
    ]);
    
    $response = curl_exec($curl);
    $err = curl_error($curl);
    
    curl_close($curl);
    
    if ($err) {
      echo "cURL Error #:" . $err;
    } else {
      echo $response;
    }`;


    const checkoutGuzzleHttp = `<?php

    $client = new \GuzzleHttp\Client();
    
    $response = $client->request('GET', 'https://api.fintechdaddy.in/payment/checkout/general');
    
    echo $response->getBody();`;

    const checkoutNodeJS = `const axios = require('axios').default;

    const options = {method: 'GET', url: 'https://api.fintechdaddy.in/payment/checkout/general'};
    
    try {
      const { data } = await axios.request(options);
      console.log(data);
    } catch (error) {
      console.error(error);
    }`;


    const checkoutjava = `AsyncHttpClient client = new DefaultAsyncHttpClient();
    client.prepare("GET", "https://api.fintechdaddy.in/payment/checkout/general")
      .execute()
      .toCompletableFuture()
      .thenAccept(System.out::println)
      .join();
    
    client.close();`;


    const checkoutCRestSharp = `using System.Net.Http.Headers;
    var client = new HttpClient();
    var request = new HttpRequestMessage
    {
        Method = HttpMethod.Get,
        RequestUri = new Uri("https://api.fintechdaddy.in/payment/checkout/general"),
    };
    using (var response = await client.SendAsync(request))
    {
        response.EnsureSuccessStatusCode();
        var body = await response.Content.ReadAsStringAsync();
        Console.WriteLine(body);
    }`;


    const checkoutpython = `import http.client

    conn = http.client.HTTPSConnection("api.step2pay.online")
    
    conn.request("GET", "/payment/checkout/general")
    
    res = conn.getresponse()
    data = res.read()
    
    print(data.decode("utf-8"))`;



    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>FintechDaddy | Step-by-Step Integration Documents</title>
                <meta name="description" content="Integrate FintechDaddy quick payment easily to your site. with our step by step guide
provided for you."></meta>
            </Helmet>
            <body className="body-green">
                <div className="page-wrapper">
                    <div className="doc-wrapper">
                        <div className="container-fluid ps-0">
                            <div className="doc-body row">
                                <div className="doc-content col-md-9 col-12 order-1 px-5">
                                    <div className="content-inner">
                                        <section id="Introduction" className="doc-section">
                                            <h2 className="section-title">Download</h2>
                                            <div className="section-block">
                                                <h1>Welcome to Fintechdaddy Docs!</h1>
                                                <p>
                                                    Thank you for building your checkout experience using Fintechdaddy. You will find as part of this documentation, all the resources to interface with Fintechdaddy. We aim to provide developers and designers the superpower to enable your customers to complete a transaction in less than 30 seconds, helping you reduce both abandonment during the checkout process as well as maximise payment success rates. </p>

                                                <p>There are many ways in which you can use Fintechdaddy to build a great experience for your consumers. You can use our various SDKs to build for your requirement. If your platform is currently not supported, do reach out to us on Fintechdaddy and we can help you out! If you are unhappy with our documentation, do reach out to Fintechdaddy with your feedback. We value very highly your time and would want integrating with Fintechdaddy to be a breeze for you and your team. Keep us on our toes and we promise to deliver for you.</p><p>We are currently supporting integrations on websites and mobile apps. We also support integrations with our API. If you need something else, please speak to us on Fintechdaddy. </p>
                                                <h3 id="web-checkout"><a href="#" data-id="web-checkout" class="anchor"><span>Web Checkout</span></a></h3>
                                                <p>This is the easiest way to integrate your website with Fintechdaddy. Find our detailed guide <a href="#webCheckOutCOde">available here</a></p>
                                                <h3 id="e-commerce-platforms"><a href="#" data-id="e-commerce-platforms" class="anchor"><span>E-commerce Platforms</span></a></h3>
                                                <p>All the common e-commerce platforms such as <a href="#wpIntegrationSteps">WooCommerce</a> amongst many others are supported by us, you can find detailed instructions here on how to integrate here. </p>

                                                <h3 id="server-kit-integration">
                                                    <a href="#" data-id="server-kit-integration" class="anchor"><span>Server Kit Integration</span></a>
                                                </h3>
                                                <p>You can easily integrate Fintechdaddy with your server with our server kits.</p>
                                                <ul>
                                                    <li><a href="#PHP">PHP</a></li>
                                                    <li><a href="#JAVA">Java</a></li>
                                                    <li><a href="#Python">Python</a></li></ul>

                                                <h3 id="mobile-apps"><a href="#" data-id="mobile-apps" class="anchor"><span>Mobile Apps</span></a></h3>
                                                <h5 id="native-integrations"><a href="#" data-id="native-integrations" class="anchor"><span>Native Integrations</span></a></h5>
                                                <ul><li><a href="#">Android</a></li><li><a href="/ios-native-sdk">iOS</a></li></ul>

                                                <h3 id="server"><a href="#" data-id="server" class="anchor"><span>Server</span></a></h3>

                                                <p>We support integrations with your server and provide full flexibility for you to imagine the entire checkout experience for your customers.  Read our detailed guide for <a href="#">server to server integrations</a>.</p>

                                            </div>
                                        </section>

                                        <section className="duringInt">
                                            <div className="duringIntigration" id="duringIntigration">
                                                <h3>Test Your integration</h3>
                                                <p>During the course of your integration, we would be happy to assist you in any way possible, please reach out to us on Fintechdaddy with your query.</p>

                                                <p>Fintechdaddy APIs are completely RESTful and all our responses are returned in JSON.</p>

                                                <p>You can use the Fintechdaddy API in test mode, which does not affect your live data or interact with the banking networks. The API key you use to authenticate the request determines whether the request is live mode or test mode.</p>
                                            </div>

                                            <div className="marchentTestKey" id="marchentTestKey">
                                                <h3>API Authorization</h3>

                                                <div className="authorization">
                                                    <pre class=" language-css">
                                                        <code class=" language-javascript">

                                                            <div class="sl-relative sl-whitespace-nowrap"><span class="sl-font-bold sl-pr-2">Live Server:</span><span aria-label="Live Server">https://api.fintechdaddy.in</span></div>

                                                            <div class="sl-relative sl-whitespace-nowrap"><span class="sl-font-bold sl-pr-2">Mock Server:</span><span aria-label="Live Server">https://api.fintechdaddy.in</span></div>
                                                        </code>
                                                    </pre>

                                                </div>

                                            </div>


                                            <div className="AuthApi" id="secrateKey">
                                                <div className="ordercreate">
                                                    <CustomTabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
                                                        <CustomTab eventKey="tab1" title="Generate Token">
                                                            <div id="react-aria-2-2124" class="sl-code-editor">
                                                                <pre>
                                                                    <code className="bdCode">{curl}</code>
                                                                </pre>
                                                            </div>
                                                        </CustomTab>
                                                        <CustomTab eventKey="tab2" title="200 ok Response">
                                                            <div id="react-aria-2-2124" class="sl-code-editor">
                                                                <pre>
                                                                    <code className="bdCode">{GuzzleHttp}</code>
                                                                </pre>
                                                            </div>
                                                        </CustomTab>
                                                        <CustomTab eventKey="tab3" title="400 Bad Request">
                                                            <pre>
                                                                <code className="bdCode">{NodeJS}</code>
                                                            </pre>

                                                        </CustomTab>

                                                        <CustomTab eventKey="tab4" title="401 Invaild">
                                                            <pre>
                                                                <code className="bdCode">{java}</code>
                                                            </pre>
                                                        </CustomTab>

                                                    </CustomTabs>
                                                </div>
                                            </div>

                                        </section>



                                        <section id="webIntegrationAPI" className="doc-section">
                                            <h2 className="section-title">Web Integration</h2>
                                            <div id="createToken" className="section-block">


                                            </div>
                                            <div id="createOrder" className="section-block">
                                                <h1 class="sl-text-heading">Create New Order</h1>
                                                <p>Create a new order. Make sure that your partner account is satisfy below conditions:</p>
                                                <ul>
                                                    <li>Has at least one actived sub-merchant account. And sub-merchant should be filled information of at least one payment service.</li>
                                                    <li>Order total amount is between of minimum and maximum value of at least one actived sub-merchant account.</li>
                                                </ul>
                                                <div class="code-block">
                                                    <h6>POST</h6>
                                                    <p><code>https://api.fintechdaddy.in/partner/orders</code></p>
                                                </div>
                                                <h3>Headers</h3>
                                                <h4>Authorization string  required</h4>
                                                <p> Bearer token with token is access token in response of login API</p>
                                                <div id="react-aria-2-2124" class="sl-code-editor">
                                                    <h6>Example:</h6>
                                                    <p className="bearerCode"><code>Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3N0YWdpbmcuc2VsbG9uYm9hcmQuY29tL2xvZ2luIiwiaWF0IjoxNjc0ODkxMDQzLCJleHAiOjE2Nzc0ODMwNDMsIm5iZiI6MTY3NDg5MTA0MywianRpIjoibm5XOUR2bnhaMUttTzhTMiIsInN1YiI6MTA2LCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.rr6x96DllJK5NrEwb8yroGvECpFAWv1ZCCxDWZ2QNzU</code></p>
                                                </div>

                                                <div className="ordercreate">
                                                    <CustomTabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
                                                        <CustomTab eventKey="tab1" title="Curl">
                                                            <div id="react-aria-2-2124" class="sl-code-editor">
                                                                <pre>
                                                                    <code className="bdCode">{curl}</code>
                                                                </pre>
                                                            </div>
                                                        </CustomTab>
                                                        <CustomTab eventKey="tab2" title="PHP">
                                                            <div id="react-aria-2-2124" class="sl-code-editor">
                                                                <pre>
                                                                    <code className="bdCode">{GuzzleHttp}</code>
                                                                </pre>
                                                            </div>
                                                        </CustomTab>
                                                        <CustomTab eventKey="tab3" title="NodeJS">
                                                            <pre>
                                                                <code className="bdCode">{NodeJS}</code>
                                                            </pre>

                                                        </CustomTab>

                                                        <CustomTab eventKey="tab4" title="Java">
                                                            <pre>
                                                                <code className="bdCode">{java}</code>
                                                            </pre>
                                                        </CustomTab>

                                                        <CustomTab eventKey="tab5" title="C#RestSharp">
                                                            <pre>
                                                                <code className="bdCode">{CRestSharp}</code>
                                                            </pre>
                                                        </CustomTab>


                                                        <CustomTab eventKey="tab6" title="Python">

                                                            <pre>
                                                                <code className="bdCode">{python}</code>
                                                            </pre>

                                                        </CustomTab>


                                                        <CustomTab eventKey="tab7" title="200  OK Responce ">
                                                            200  OK Responce
                                                        </CustomTab>

                                                    </CustomTabs>
                                                </div>



                                            </div>


                                            <div className="webhookAPi" id="webhookAPi">
                                                <h3>Webhook Structure</h3>
                                                <p>Once Fintechdaddy server received data from payment gateways after transaction success / failed, Fintechdaddy server will post JSON data to webhook URL (which can be filled in partner dashboard).</p>

                                                <div class="code-block">
                                                    <h6>Example of JSON data:</h6>
                                                    <pre class=" language-markup">
                                                        <code>{exampleJson}</code>
                                                    </pre>
                                                </div>

                                                <h3>Fields Description</h3>
                                                <div class="table-responsive">
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>

                                                                <th>Name</th>
                                                                <th>TYPE</th>
                                                                <th>DESCRIPTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>

                                                                <td>order_id</td>
                                                                <td>number</td>
                                                                <td>Order ID in Fintechdaddy system</td>
                                                            </tr>
                                                            <tr>

                                                                <td>order_number</td>
                                                                <td>string</td>
                                                                <td>Order number which is set when create order</td>
                                                            </tr>
                                                            <tr>

                                                                <td>amount</td>
                                                                <td>number</td>
                                                                <td>Order total amount (INR)</td>
                                                            </tr>

                                                            <tr>

                                                                <td>payment_status</td>
                                                                <td>string</td>
                                                                <td>Payment status.<br /> Payment status can be one of: 2 (Success) or 7 (Failed)</td>
                                                            </tr>


                                                            <tr>

                                                                <td>payment_tx_id</td>
                                                                <td>string</td>
                                                                <td>Transaction ID from payment gateway</td>
                                                            </tr>


                                                            <tr>

                                                                <td>vpa</td>
                                                                <td>string</td>
                                                                <td>VPA of customer</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>



                                        </section>

                                        <section id="code-section" className="doc-section">
                                            <h2 className="section-title">Code</h2>
                                            <div className="section-block">
                                                <p>
                                                    <a href="https://prismjs.com/" target="_blank">PrismJS</a> is used as the syntax highlighter here. You can <a href="https://prismjs.com/download.html" target="_blank">build your own version</a> via their website should you need to.
                                                </p>

                                            </div>



                                        </section>
                                        <section id="callouts-section" className="doc-section">
                                            <h2 className="section-title">Ecommerce plugins</h2>
                                            <div className="section-block">
                                                <p>Fintechdaddy offer various plugins for seamless integration that will allow your ecommerce website to accept the payment from your customer.
                                                </p>
                                            </div>

                                            <div className="wordpress" id="wordpress">
                                                <h1>Fintechdaddy Checkout for WooCommerce</h1>
                                                <p>Fintechdaddy supports easy acceptance of payments on your WooCommerce store. We have created a simple plugin that you can install on your store. This guide will explain the step by step process to integrate the plugin.</p>
                                                <h1>Compatibilities and Dependencies</h1>
                                                <ul>
                                                    <li>Wordpress v3.9.2 or higher</li>
                                                    <li>Woocommerce v2.4 or higher</li>
                                                    <li>PHP v7.3.0 or higher</li>
                                                    <li>php-cURL</li>
                                                </ul>
                                            </div>

                                            <div className="wpIntegrationSteps" id="wpIntegrationSteps">
                                                <h1>Integration Steps</h1>
                                                <p>You need to follow the following steps to start accepting payments on your store using Nimbbl Checkout</p>
                                            </div>
                                            <div className="wpInstall" id="wpInstall">
                                                <h2>Install Plugin</h2>
                                                <p>Download the plugin and install manually.</p>
                                                <ol>
                                                    <li>Download the latest <a href="%PUBLIC_URL%/custom_crul_payment_gateway.zip" target="_blank" download>Source code zip file</a>.</li>
                                                    <li>Upload zip file to your /wp-content/plugins/ directory.</li>
                                                </ol>

                                                <div><img src="https://docs.nimbbl.tech/media/upload-plugin.png" /></div>

                                            </div>


                                        </section>

                                        <section id="serverKitintegration" className="serverket">
                                            <h3>Server Kit Integrations</h3>
                                            <p>You can easily integrate Fintechdaddy with your server. Checkout our server kits which will allow you to collect payment from your customers.</p>
                                            <ol>
                                                <li>PHP</li>
                                                <li>JAVA</li>
                                                <li>Python</li>
                                            </ol>
                                        </section>


                                        <section id="PHP" className="phpint">
                                            <h3>PHP API</h3>
                                            <p>The API follows the following practices.</p>

                                            <ul>
                                                <li>Namespaced under Fintechdaddy\Api.</li>
                                                <li>Initialize Fintechdaddy sdk with access key and secret key.</li>
                                                <li>Call $api-class-function() to access the API.</li>
                                                <li>All requests and responses are communicated over JSON.</li>
                                                <li>Options are passed as an array instead of multiple arguments, wherever possible.</li>
                                            </ul>

                                            <h3>Prerequisites</h3>
                                            <ul>
                                                <li>PHP version should be 5.3 or higher.</li>
                                            </ul>

                                            <h3>Integration Steps</h3>
                                            <h3>Download Fintechdaddy PHP Server Kit.</h3>
                                            <p>Download the latest file from the releases section  <a href="#" target="_blank" rel="noopener">Fintechdaddy-php-sdk</a>.</p>

                                            <ol>
                                                <li>
                                                    <p>v1.0.0 <a href="#" target="_blank" rel="noopener">Fintechdaddy-php-sdk.zip</a></p></li>
                                                <li>
                                                    <p>v2.0.0 <a href="#" target="_blank" rel="noopener">Fintechdaddy-php-sdk.zip</a></p>
                                                </li>

                                            </ol>

                                            <div className="row">
                                                <h3>Integrate Orders API.</h3>

                                                <CustomTabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
                                                    <CustomTab eventKey="tab1" title="Curl">
                                                        <div id="react-aria-2-2124" class="sl-code-editor">
                                                            <pre>
                                                                <code className="bdCode">{curl}</code>
                                                            </pre>
                                                        </div>
                                                    </CustomTab>
                                                    <CustomTab eventKey="tab2" title="Guzzle">
                                                        <div id="react-aria-2-2124" class="sl-code-editor">
                                                            <pre>
                                                                <code className="bdCode">{GuzzleHttp}</code>
                                                            </pre>
                                                        </div>
                                                    </CustomTab>
                                                </CustomTabs>
                                            </div>

                                        </section>



                                        {/* <section id="Java" className="javaint">
                                            <h3>Java API</h3>
                                            <p>The API follows the following practices.</p>

                                            <ul>
                                                <li>Namespaced under Fintechdaddy\Api.</li>
                                                <li>Initialize Fintechdaddy sdk with access key and secret key.</li>
                                                <li>Call $api-class-function() to access the API.</li>
                                                <li>All requests and responses are communicated over JSON.</li>
                                                <li>Options are passed as an array instead of multiple arguments, wherever possible.</li>
                                            </ul>

                                            <h3>Prerequisites</h3>
                                            <ul>
                                                <li>Java version should be 1.8 or higher.</li>
                                            </ul>

                                            <h3>Integration Steps</h3>


                                            <div className="row">
                                                <h3>Integrate Orders API.</h3>

                                                <CustomTabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
                                                    <CustomTab eventKey="tab1" title="AsyncHttpClient">
                                                        <div id="react-aria-2-2124" class="sl-code-editor">
                                                            <pre>
                                                                <code className="bdCode">{curl}</code>
                                                            </pre>
                                                        </div>
                                                    </CustomTab>
                                                    <CustomTab eventKey="tab2" title="NetHttp">
                                                        <div id="react-aria-2-2124" class="sl-code-editor">
                                                            <pre>
                                                                <code className="bdCode">{GuzzleHttp}</code>
                                                            </pre>
                                                        </div>
                                                    </CustomTab>

                                                    <CustomTab eventKey="tab3" title="OKHttp">
                                                        <div id="react-aria-2-2124" class="sl-code-editor">
                                                            <pre>
                                                                <code className="bdCode">{GuzzleHttp}</code>
                                                            </pre>
                                                        </div>
                                                    </CustomTab>

                                                </CustomTabs>
                                            </div>

                                        </section> */}

                                        <section className="APISEC">

                                        </section>

                                        <section className="WebCheckOutAPI">

                                            <h3>Checkout</h3>
                                            <div className="webGET">
                                                <span>GET</span>  https://api.fintechdaddy.in/payment/checkout/general
                                            </div>

                                            <p>After create order success, please use property id from response and pass it as param of this API. It will show checkout screen for that order.</p>

                                            <div className="webCheckOutCOde" id="webCheckOutCOde">
                                                <CustomTabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
                                                    <CustomTab eventKey="tab1" title="Curl">
                                                        <div id="react-aria-2-2124" class="sl-code-editor">
                                                            <pre>
                                                                <code className="bdCode">{checkoutcurl}</code>
                                                            </pre>
                                                        </div>
                                                    </CustomTab>
                                                    <CustomTab eventKey="tab2" title="PHP">
                                                        <div id="react-aria-2-2124" class="sl-code-editor">
                                                            <pre>
                                                                <code className="bdCode">{checkoutGuzzleHttp}</code>
                                                            </pre>
                                                        </div>
                                                    </CustomTab>
                                                    <CustomTab eventKey="tab3" title="NodeJS">
                                                        <pre>
                                                            <code className="bdCode">{checkoutNodeJS}</code>
                                                        </pre>

                                                    </CustomTab>

                                                    <CustomTab eventKey="tab4" title="Java">
                                                        <pre>
                                                            <code className="bdCode">{checkoutjava}</code>
                                                        </pre>
                                                    </CustomTab>

                                                    <CustomTab eventKey="tab5" title="C#RestSharp">
                                                        <pre>
                                                            <code className="bdCode">{checkoutCRestSharp}</code>
                                                        </pre>
                                                    </CustomTab>


                                                    <CustomTab eventKey="tab6" title="Python">

                                                        <pre>
                                                            <code className="bdCode">{checkoutpython}</code>
                                                        </pre>

                                                    </CustomTab>


                                                    <CustomTab eventKey="tab7" title="200  OK Responce ">
                                                        200  OK Responce
                                                    </CustomTab>

                                                </CustomTabs>
                                            </div>

                                        </section>


                                    </div>
                                </div>

                                <IntegrationSidebar />



                            </div>
                        </div>
                    </div>
                </div>
            </body>

        </div>
    );
}

export default Integration;