import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from "react-helmet";
import image1 from '../../assets/images/Products/Payment page/Payment page.png';
import imagelogo1 from '../../assets/images/Products/Payment Gateway/Bank Logo/axis bank.png';
import imagelogo2 from '../../assets/images/Products/Payment Gateway/Bank Logo/hdfc bank.png';
import imagelogo3 from '../../assets/images/Products/Payment Gateway/Bank Logo/icici bank.png';
import imagelogo4 from '../../assets/images/Products/Payment Gateway/Bank Logo/idbi bank.png';
import imagelogo5 from '../../assets/images/Products/Payment Gateway/Bank Logo/indus bank.png';
import imagelogo6 from '../../assets/images/Products/Payment Gateway/Bank Logo/yes bank.png';
import bestService1 from '../../assets/images/svg/awesome-1.svg';
import bestService2 from '../../assets/images/svg/awesome-2.svg';
import bestService3 from '../../assets/images/svg/awesome-3.svg';
import paymentGetwayfaq from '../../assets/images/faq-img.png';

import paymentGetwaysimage1 from '../../assets/images/why1.png';
import paymentGetwaysimage2 from '../../assets/images/why2.png';
import paymentGetwaysimage3 from '../../assets/images/why3.png';
import paymentGetwaysimage4 from '../../assets/images/why4.png';
import paymentGetwaysimage5 from '../../assets/images/how.png';

import paymentGetwaysimagetask1 from '../../assets/images/Products/Payment page/Payment page Create Your Custom Payment.png';
import paymentGetwaysimagepage from '../../assets/images/Products/Payment page/Payment-Page-Centre.png';

import paymentGetwaysimagetaskshape1 from '../../assets/images/shape/task-shape-1.png';
import paymentGetwaysimagetaskshape2 from '../../assets/images/shape/task-shape-2.png';

import paymentGetwaysimageteamShape from '../../assets/images/shape/team-shape.png';
import Accordion from 'react-bootstrap/Accordion';

function Paymentpage() {


  const sliderOptions = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      }
    }
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create Your Custom Payment Page For Your Brand | FintechDaddy</title>
        <meta name="description" content="Design your own customize page with ease & increase your sales with our Streamlined, secure & user-friendly payment page.
"></meta>
      </Helmet>
      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">Collect Payment with Custom Payment Page</h2>
                  <p className="text-light">FintechDaddy Payment page is the easiest way to accept payment with Zero coding.</p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/contact-us">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image">
                <img className="experience-img" src={image1} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="workflow-integration-area ptb-100 bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="task-img">
                <img className="task-img-1" src={paymentGetwaysimagetask1} alt="image" />
                <img className="task-shape-1" src={paymentGetwaysimagetaskshape1} alt="image" />
                {/* <img className="task-img-2" src={paymentGetwaysimagetask2} alt="image" /> */}
                {/* <img className="task-img-3" src={paymentGetwaysimagetask3} alt="image" /> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="history">
                <div className="section-heading">
                  <h2 className="text-light">Create Your Custom Payment Page For Your Brand</h2>
                  <p className='py-3 px-2 border rounded' style={{ lineHeight: "1.5" }}>With our payment page, you can create a unique and visually appealing checkout process that reflects your brand's identity.</p>
                </div>
                {/* <div className="history-card d-flex align-items-center position-relative bg-color-5c5c5c">
                  <img className="flex-shrink-0 me-3" src={paymentGetwaysimageUser2} alt="image" />
                  <div className="warp">
                    <h4 className="text-light">Paige Turner</h4>
                    <p className="text-light">On the other hand, we denounce with righus indignan and dislike men who are so beguiled and delized</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* shape images */}
        <img className="task-shape-2" src={paymentGetwaysimagetaskshape2} alt="image" />
        <img className="team-shape" src={paymentGetwaysimageteamShape} alt="image" />
      </div>

      {/* brands */}
      <div className="vbnmr-area pt-4 bg-color-5c5c5c">
        <div className="container">
          <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions}>
            {sliderItems.map(item => (
              <div className="sponsor-logo" key={item.id}>
                <img src={item.image} alt="image" />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>

      {/* Start Our Best Services Area */}
      <div className="best-service-area pt-100 pb-70 bg-color-100f0f benefits">
        <div className="container">
          <div className="section-title">
            <h2 className="text-light">How Fintech Payment Page Help You To Grow Your Business</h2>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
          </div>
          <div className="row Four-sec">
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card  text-center">
                <div className="awesome-image">
                  <img src={bestService1} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Custom Brand Pages</a>
                </h4>
                <p className="text-light">Customize your Payment Page As Like Your Website Ex Colour Buttons Etc Its Look Good And Attracted Customer To Your Store</p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card two text-center">
                <div className="awesome-image">
                  <img src={bestService2} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Custom  Fields</a>
                </h4>
                <p className="text-light">Collect Information Of Customers Which Important To Your Business. Add & Modify Fields. Ex- Name, Mobile No , E-mail</p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <img src={bestService3} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Track Your Payment</a>
                </h4>
                <p className="text-light">Track all Your Payment With Just one Click. Fintech Daddy Dashboard is very easy to read data.</p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="awesome-card  text-center">
                <div className="awesome-image">
                  <img src={bestService1} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">No-Code Required</a>
                </h4>
                <p className="text-light">Zero-Coding Knowledge. Don’t Worry FintechDaddy Directly Integrated Payment Link With Your Payment Model
                </p>
                {/* <a href="#" className="text-success">Join Now</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="workflow-integration-area ptb-100 bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="task-img">
                <img className="task-img-1" src={paymentGetwaysimagepage} alt="image" />
                <img className="task-shape-1" src={paymentGetwaysimagetaskshape1} alt="image" />
                {/* <img className="task-img-2" src={paymentGetwaysimagetask2} alt="image" /> */}
                {/* <img className="task-img-3" src={paymentGetwaysimagetask3} alt="image" /> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="history">
                <div className="section-heading">
                  <h2 className="text-light">Easily Integrate Payment Page</h2>
                  {/* <p>Sell online without a website. Share Payment Links with customer via SMS, email or Whatsapp and get paid instantly via 120+ payment modes.</p> */}
                </div>
                <div className="">
                  <div className="v-progress">
                    <ul>
                      {/*     <li class="v-progress-item completed">Deepak</li> */}
                      <li className="v-progress-item inprogress">STEP 1
                        <p>Set Up an Account</p>
                      </li>
                      <li className="v-progress-item">STEP 2
                        <p>Configure Account Settings</p>
                      </li>
                      <li className="v-progress-item">STEP 3
                        <p>Integrate with Your Platform</p>
                      </li>
                      <li className="v-progress-item">STEP 4
                        <p>Design Your Payment Page</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* shape images */}
        <img className="task-shape-2" src={paymentGetwaysimagetaskshape2} alt="image" />
        <img className="team-shape" src={paymentGetwaysimageteamShape} alt="image" />
      </div>


      <div className="client-say-area client-say-testimonials-area pt-100 pb-70 bg-color-100f0f payment_page">
        <div className="container">
          <div className="section-title">
            {/* <span class="subtitle">Our Testimonials</span> */}
            <h2 className="text-light">Benefit Of FintechDaddy Payment Gateway Page</h2>
          </div>
          <div className="row benefits">
            <div className="col-lg-4" src="">
              <div className="say-card">
                <h4 className="text-light">Increased Sales</h4>
                <p className="text-light">A well-designed payment gateway page can help to reduce cart abandonment and increase sales by providing a clear and user-friendly checkout process.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="">
              <div className="say-card">
                <h4 className="text-light">Multiple Payment Options</h4>
                <p className="text-light">FintechDaddy can support multiple payment types, such as credit cards, debit cards, e-wallets, and bank transfers, allowing businesses to offer their customers a range of payment options.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="">
              <div className="say-card">
                <h4 className="text-light">Customization</h4>
                <p className="text-light">Payment gateway page can be customized with business branding, logos, and other design elements to provide a consistent and professional look and feel.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="">
                <h4 className="text-light">Security</h4>
                <p className="text-light">FintechDaddy are typically designed with security in mind, using encryption and other security measures to protect sensitive data.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="">
                <h4 className="text-light">Fraud Protection</h4>
                <p className="text-light">We Provide fraud protection measures such as real-time transaction monitoring, address verification, and risk scoring to help prevent fraudulent transactions.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="">
                <h4 className="text-light">Data Management</h4>
                <p className="text-light">FintechDaddy can also help businesses to manage customer & transaction data, providing detailed reporting & analytics to optimize their payment processing workflows.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>


          </div>
        </div>
      </div>


      <section className=" bg-dark pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">Join Our Partner Program</h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>  Know More <i className="flaticon-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>

      {/* FAQ */}
      <div className="faq-area ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-image-">
                <img src={paymentGetwayfaq} alt="faq" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is a payment gateway page?</Accordion.Header>
                      <Accordion.Body>
                        A payment gateway page is a secure webpage that allows customers to enter their payment details and complete a transaction online.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How Does FintechDaddy Payment Page Work?</Accordion.Header>
                      <Accordion.Body>
                        When a customer makes a purchase, they are directed to the payment gateway page where they can enter their payment information. FintechDaddy then processes the transaction and returns the result to the merchant
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Is it safe to use FintechDaddy payment gateway page?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy Payment Page are typically designed with security in mind, using encryption and other security measures to protect sensitive data.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>What payment types are supported by FintechDaddy payment gateway page?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy can support multiple payment types, such as credit cards, debit cards, e-wallets, and bank transfers.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Can FintechDaddy payment gateway page be customized with branding or design elements?</Accordion.Header>
                      <Accordion.Body>
                        Yes, FintechDaddy can often be customized with business branding, logos, and other design elements to provide a consistent and professional look and feel.
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Paymentpage;