import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import resellerimage from '../../assets/images/Pages/Reseller/Reseller Top.png';
import imagelogo1 from '../../assets/images/payment/bhim.png';
import imagelogo2 from '../../assets/images/payment/upi.png';
import imagelogo3 from '../../assets/images/payment/gpay.png';
import imagelogo4 from '../../assets/images/payment/phonepe.png';
import imagelogo5 from '../../assets/images/payment/paytm.png';
import imagelogo6 from '../../assets/images/payment/rupay.png';
import imagelogo7 from '../../assets/images/payment/visa.png';
import imagelogo8 from '../../assets/images/payment/mastercard.png';
import paymentGetwayfaq from '../../assets/images/faq-img.png';

import bestService1 from '../../assets/images/svg/awesome-1.svg';
import bestService2 from '../../assets/images/svg/awesome-2.svg';
import bestService3 from '../../assets/images/svg/awesome-3.svg';

import boostYourBussiness1 from '../../assets/images/experience-img.png';
import boostYourBussiness2 from '../../assets/images/svg/business-1.svg';
import boostYourBussiness3 from '../../assets/images/svg/business-2.svg';

import paymentGetwaysimage1 from '../../assets/images/why1.png';
import paymentGetwaysimage2 from '../../assets/images/why2.png';
import paymentGetwaysimage3 from '../../assets/images/why3.png';
import paymentGetwaysimage4 from '../../assets/images/why4.png';
import paymentGetwaysimage5 from '../../assets/images/how.png';

import paymentGetwaysimageprocess1 from '../../assets/images/shape/graphic-shape-3.png';
import paymentGetwaysimageprocess2 from '../../assets/images/paying-img.png';
import paymentGetwaysimageprocess3 from '../../assets/images/Pages/Reseller/reseller 2 section.png';

import paymentGetwaysimageprocess4 from '../../assets/images/graphics-img-2.png';
import paymentGetwaysimageprocess5 from '../../assets/images/graphics-img-3.png';
import paymentGetwaysimageprocess6 from '../../assets/images/shape/graphic-shape-1.png';
import paymentGetwaysimageprocess7 from '../../assets/images/shape/graphic-shape-2.png';

import paymentGetwaysimagetask1 from '../../assets/images/shape/service-shape-3.png';
import paymentGetwaysimagetask2 from '../../assets/images/shape/service-shape-2.png';
import paymentGetwaysimagetask3 from '../../assets/images/shape/service-shape-5.png';
import paymentGetwaysimagetaskshape1 from '../../assets/images/shape/service-shape-1.png';
import paymentGetwaysimagetaskshape2 from '../../assets/images/shape/task-shape-2.png';
import paymentGetwaysimageUser2 from '../../assets/images/user/reseller men icon.png';
import { NavLink } from "react-router-dom";
import testiminal from '../../assets/images/user/reseller Girl Icon.png';
import Accordion from 'react-bootstrap/Accordion';
function Reseller() {


  const sliderOptions = {
    loop: true,
    margin: 40,
    autoplay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 3
      },
      600: {
        items: 2
      },
      1000: {
        items: 8
      }
    }
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 },
    { id: 7, image: imagelogo7 },
    { id: 8, image: imagelogo8 }
  ];

  const sliderOptions1 = {
    loop: true,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 2
      }
    }
  };

  const sliderItems1 = [
    { id: 1, image_data: testiminal, text_data: 'Swati Sharma', description_data: '“FintechDaddy  has been a solid partner to rely on for our payment solutions and we are a heavy user of Payouts and Auto Collect. The best part is they are very customer centric and provide solutions with small TAT.”' },
    { id: 2, image_data: paymentGetwaysimageUser2, text_data: 'Gaurav Rastogi', description_data: '"FintechDaddy  gives us next day settlements. For a startup like us it means money reaches our account without being blocked anywhere."' },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FintechDaddy Reseller Program | Refer & Earn</title>
        <meta name="description" content="Join Our Reseller Program, where you can refer customers & boost your earnings
through promoting our advanced financial solutions as a reseller"></meta>
      </Helmet>

      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div>
                <div className="section-heading">
                  {/* <h2 className="text-light">Payment gateway reseller program Offered Great Commission</h2> */}
                  <h2 className="text-light">Make 50% Commission Today by Referring Business</h2>
                  <p className="text-light">By joining our reseller program today you can earn 50% Share on all processing for merchants you refer to FintechDaddy</p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/contact-us">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image d-flex justify-content-center">
                <img className="experience-img" src={resellerimage} alt="image" />
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <div className="container">
                <div className="partners mt-5">
                  <div className="header text-light">Supports all UPI Apps</div>
                  <div className="partner-img">
                    <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions}>
                      {sliderItems.map(item => (
                        <div className="partner-img" key={item.id}>
                          <img src={item.image} alt="image" className='lazy loaded' />
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="call-overlay py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 col-xl-6">
              <div className="call-item text-center text-sm-left d-flex">
                <div className="call-icon">
                  <img src={Affiliateimage} alt="call" />
                </div>
                <div className="call-content">
                  <h5 className="title text-light">Ready To Start Your Earnings Through Our Referal Program</h5>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-6 text-center text-sm-left text-md-right">
              <div className="button-group">
                <a className="banner-btn" href="#">learn More <i className="flaticon-right" /></a>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="services-area one pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            {/* <span className="sub-title">Become a partner now</span> */}
            <h2 className="text-light">Become a partner now</h2>
            <p>FintechDaddy provides an advanced, flexible, full feature set along with its payment services that are known as one of the most easily integrated solutions available</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card one">
                <i className="flaticon-software" />
                <div className="info">
                  <h4>
                    <a href="">98% Account Approval Rate</a>
                  </h4>

                </div>
                <img className="service-shape-3 bounce" src={paymentGetwaysimagetask1} alt="image" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card two">
                <i className="flaticon-customer-service" />
                <div className="info">
                  <h4>
                    <a href="">Seamless Integration</a>
                  </h4>

                </div>
                <img className="service-shape-2 bounce" src={paymentGetwaysimagetask2} alt="image" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card three">
                <i className="flaticon-layer" />
                <div className="info">
                  <h4>
                    <a href="">Direct Transferred to Bank Account</a>
                  </h4>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card four">
                <i className="flaticon-united" />
                <div className="info">
                  <h4>
                    <a href="">Improved Customer Experience</a>
                  </h4>

                </div>
                <img className="service-shape-5 bounce" src={paymentGetwaysimagetask3} alt="image" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card five">
                <i className="flaticon-growth" />
                <div className="info">
                  <h4>
                    <a href="">Flexible Payment Solution</a>
                  </h4>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* shape images */}
        <img className="service-shape-1 rotate" src={paymentGetwaysimagetaskshape1} alt="image" />
        <img className="service-shape-4 rotate" src={paymentGetwaysimagetaskshape2} alt="image" />
      </div>

      <div className="modern-graphic-area-one ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="section-title text-center mb-0">
              {/* <span className="sub-title">Subscriptions On Payment Button</span> */}
              <h2 className="text-light">The Perfect Reseller Partnership is Waiting For You</h2>
              <p className="text-light">FintechDaddy  is one of the trusted names in the payment processing due to its extensive experience.</p>
            </div>
            <div className="col-lg-6">
              <div className="modern-info">


                <div className="profile-authentication-area">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="register-form bg-color-0b0808">
                          <h2 className="text-light">Register</h2>
                          <div className='form'>
                            <div className="form-group">
                              <label className="text-light">Name</label>
                              <input type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label className="text-light">Phone</label>
                              <input type="text" className="form-control" />
                            </div>
                            <button type="submit" className="default-btn">Become Partner</button>
                            {/* <a className="common-btn two" href="#">Learn More</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ul>
                    <li>
                      <i className="flaticon-check" />Distinctively brand adaptive innovation
                    </li>
                    <li>
                      <i className="flaticon-check" />You need to be sure there isn't anything
                    </li>
                  </ul> 
                <a className="common-btn two" href="#">Learn More</a>*/}
                <img className="graphic-shape-3 rotate" src={paymentGetwaysimageprocess1} alt="image" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="graphics-img">
                <img className="paying-img" src={paymentGetwaysimageprocess2} alt="paying-img" data-cues="zoomIn" data-disabled="true" />
                <img className="graphics-img-1" src={paymentGetwaysimageprocess3} alt="image" />
                {/* shape images */}
                {/* <img className="graphics-img-2 moveVertical" src={paymentGetwaysimageprocess4} alt="image" /> */}
                {/* <img className="graphics-img-3 moveHorizontal" src={paymentGetwaysimageprocess5} alt="image" /> */}
                {/* <img className="graphic-shape-1" src={paymentGetwaysimageprocess6} alt="image" /> */}
                {/* <img className="graphic-shape-2 moveVertical" src={paymentGetwaysimageprocess7} alt="image" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------- */}

      <div className="client-say-area client-say-testimonials-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            {/* <span class="subtitle">Our Testimonials</span> */}
            <h2 className="text-light">Benefits of Participating in a Reseller Program</h2>
          </div>
          <div className="row reseller">
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Additional Revenue</h4>
                <p className="text-light">Resellers can earn additional revenue by selling the products or services of the company they are partnering with.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Low Start-up Costs</h4>
                <p className="text-light">Reseller programs typically require low start-up costs, making them an accessible option for anyone interested in starting a business.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Brand Recognition</h4>
                <p className="text-light">Resellers can leverage the brand recognition and reputation of the company they are partnering with to build their own brand.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Access To a Wider Customer Base</h4>
                <p className="text-light">Resellers can tap into the customer base of the company they are partnering with, helping them to expand their reach and sell products or services.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Training & Support</h4>
                <p className="text-light">Companies often provide training and support to their resellers, helping them to sell products or services more effectively and efficiently.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Flexibility</h4>
                <p className="text-light">Reseller programs are often flexible, allowing resellers to work from home or set their own schedules.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>

          </div>
        </div>
      </div>



      <section className="bg-color-100f0f pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">Join Our Partner Program</h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/contact-us" className="common-btn ms-2">
                  <span>  Know More <i className="flaticon-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>

      {/* Start Testimonials Area */}
      <div className="reseller client testimonials-area testimonials-area-2 pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-heading">
            {/* <span className="sub-title">Testimonials</span> */}
            <h2>Our Partner Experience</h2>
          </div>

          <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions1}>
            {sliderItems1.map(item => (

              <div className="testimonials-box testimonials-card">
                <p className="text-light py-3">{item.description_data}</p>
                <div className="box d-flex position-relative align-items-center mb-0">
                  <img className="flex-shrink-0 me-3" src={item.image_data} alt="image" />
                  <div className="name">
                    <h4 className="text-light">{item.text_data}</h4>
                    <span className='text-light'>Freelancer</span>
                  </div>
                </div>

                <i className="flaticon-straight-quotes" />
              </div>
            ))}
          </OwlCarousel>

        </div>
      </div>

      {/* End Testimonials Area */}


      {/* FAQ */}
      <div className="faq-area ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-image-">
                <img src={paymentGetwayfaq} alt="faq" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is a reseller program?</Accordion.Header>
                      <Accordion.Body>
                        A reseller program is a business strategy where a company allows other businesses or individuals (resellers) to sell its products or services under their own brand name. The resellers earn a commission or discount on the services they sell.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How does FintechDaddy reseller program work?</Accordion.Header>
                      <Accordion.Body>
                        In FintechDaddy reseller program, the reseller purchases services from the company they are partnering with at a discounted rate, and then resells them under their own brand name at a markup price. The reseller earns a commission or discount on the services they sell.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What are the benefits of participating in FintechDaddy reseller program?</Accordion.Header>
                      <Accordion.Body>
                        The benefits of participating in FintechDaddy reseller program include additional revenue, low start-up costs, brand recognition, training and support, access to a wider customer base, and flexibility.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Who is a good fit for FintechDaddy reseller program?</Accordion.Header>
                      <Accordion.Body>
                        Reseller programs are a good fit for individuals or businesses looking for additional revenue streams, or those who are interested in starting their own business with low start-up costs. Resellers who have an existing customer base or a strong online presence are also good candidates for reseller programs.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>How do I join FintechDady reseller program?</Accordion.Header>
                      <Accordion.Body>
                        To join FintechDaddy reseller program, you typically need to apply through the company's website or contact their sales team. The company will review your application and may ask for additional information about your business or marketing strategy. Once you are approved, you will receive access to the reseller program and its benefits.
                      </Accordion.Body>
                    </Accordion.Item>



                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reseller;