import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import image1 from '../../assets/images/Pages/white lable/white label top.png';
import image2 from '../../assets/images/Pages/white lable/merchant white label.png';
import image3 from '../../assets/images/Pages/white lable/Affiliate white label.png';
import image4 from '../../assets/images/Pages/white lable/Manager white label.png';
import image5 from '../../assets/images/Pages/white lable/Admin white label.png';
import image6 from '../../assets/images/Pages/white lable/Payout Whitelavel.png';
import imagelogo1 from '../../assets/images/payment/bhim.png';
import imagelogo2 from '../../assets/images/payment/upi.png';
import imagelogo3 from '../../assets/images/payment/gpay.png';
import imagelogo4 from '../../assets/images/payment/phonepe.png';
import imagelogo5 from '../../assets/images/payment/paytm.png';
import imagelogo6 from '../../assets/images/payment/rupay.png';
import imagelogo7 from '../../assets/images/payment/visa.png';
import imagelogo8 from '../../assets/images/payment/mastercard.png';
import paymentGetwayfaq from '../../assets/images/faq-img.png';

import paymentGetwaysimage1 from '../../assets/images/why1.png';
import paymentGetwaysimage2 from '../../assets/images/why2.png';
import paymentGetwaysimage3 from '../../assets/images/why3.png';
import paymentGetwaysimage4 from '../../assets/images/why4.png';
import paymentGetwaysimage5 from '../../assets/images/how.png';

import paymentGetwaysimagetask1 from '../../assets/images/shape/service-shape-3.png';
import paymentGetwaysimagetask2 from '../../assets/images/shape/service-shape-2.png';
import paymentGetwaysimagetask3 from '../../assets/images/shape/service-shape-5.png';
import paymentGetwaysimagetaskshape1 from '../../assets/images/shape/service-shape-1.png';
import paymentGetwaysimagetaskshape2 from '../../assets/images/shape/task-shape-2.png';

import Accordion from 'react-bootstrap/Accordion';
function Whitelevel() {

  const sliderOptions = {
    loop: true,
    margin: 40,
    autoplay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 3
      },
      600: {
        items: 2
      },
      1000: {
        items: 8
      }
    }
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 },
    { id: 7, image: imagelogo7 },
    { id: 8, image: imagelogo8 }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FintechDaddy White-Label Solution | Build Your Own Brand</title>
        <meta name="description" content="Unlock the power of customization with our white label solutions. Elevate your brand
by offering customized payment services under your brand name."></meta>
      </Helmet>

      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">Fintech Daddy Provide White Label Payment Gateway Solution</h2>
                  <p className="text-light">India No 1 White Label Gateway Service Provider FintechDaddy Work Seamlessly. </p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/contact-us">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image d-flex justify-content-center">
                <img className="experience-img" src={image1} alt="image" />
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <div className="container">
                <div className="partners mt-5">
                  <div className="header text-light">Supports all UPI Apps</div>
                  <div className="partner-img">
                    <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions}>
                      {sliderItems.map(item => (
                        <div className="partner-img" key={item.id}>
                          <img src={item.image} alt="image" className='lazy loaded' />
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about-area ptb-100 bg-dark">
        <div class="container">
          <div className="section-title">
            <h2 className="text-light">What Will You Get In White Label</h2>
            <p>White label solutions allow businesses to customize and brand a product or service as their own.
            </p>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-content">
                <div class="section-heading">
                  {/* <span class="sub-title">About Us</span> */}
                  <h2>Merchant Panel</h2>
                  <p>FintechDaddy White label merchant panel refers to a customizable online platform, that allows businesses to provide their merchants with a branded and tailored experience.</p>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Transaction </h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Settlement</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Payment Link</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Payment Page</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Payment Button</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Reporting</h4>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-image">
                <img class="about-3" src={image2} alt="about-img" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="about-area ptb-100 bg-color-100f0f">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-image">
                <img class="about-3 m-0" src={image3} alt="about-img" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-content">
                <div class="section-heading">
                  {/* <span class="sub-title">About Us</span> */}
                  <h2>Affiliate Panel</h2>
                  <p>The affiliate panel allows affiliates to create and manage their accounts. They can sign  up, access their profile, update personal information, manage payment preferences.</p>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Affiliate Account </h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Earning</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Reporting</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Help</h4>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="about-area ptb-100 bg-dark">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-content">
                <div class="section-heading">
                  {/* <span class="sub-title">About Us</span> */}
                  <h2>Manager Panel</h2>
                  <p>The White label solution allows businesses to customize the manager panel with their brand's visual elements, such as logo, colors, and overall design.</p>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Partner Account</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Merchant List</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>MDR </h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Merchant Management </h4>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-image">
                <img class="about-3 m-0" src={image4} alt="about-img" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="about-area ptb-100 bg-color-100f0f">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-image">
                <img class="about-3 m-0" src={image5} alt="about-img" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-content">
                <div class="section-heading">
                  {/* <span class="sub-title">About Us</span> */}
                  <h2>Admin Panel</h2>
                  <p>The admin panel provides functionalities for managing users and their access levels within the system.</p>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Create User</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Roles & Permission</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Manage User</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Switching</h4>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="about-area ptb-100 bg-dark">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-content">
                <div class="section-heading">
                  {/* <span class="sub-title">About Us</span> */}
                  <h2>Payout Panel</h2>
                  <p>The payout panel provides functionalities for clients or users to create and manage their accounts.</p>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Quick Transfer</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Bulk Transfer</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Add Beneficiary</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="check-card d-flex position-relative align-items-center">
                      <i class="flaticon-tick"></i>
                      <div class="info">
                        <h4>Create Contact</h4>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-image">
                <img class="about-3 m-0" src={image6} alt="about-img" />
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className="services-area one pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="section-title">
            {/* <span className="sub-title">On-Demand Settlements</span> */}
            <h2 className="text-light">What Is The Benefit Of White Label Gateway</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card one">
                <i className="flaticon-software" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Custom Branding</a>
                  </h4>
                  <p>With a white label, businesses can customize the user interface and branding to match their own brand.</p>
                </div>
                <img className="service-shape-3 bounce" src={paymentGetwaysimagetask1} alt="image" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card two">
                <i className="flaticon-customer-service" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Improved Customer Experience</a>
                  </h4>
                  <p>By offering a white label payment gateway, businesses can provide a seamless and integrated payment experience for their customers.</p>
                </div>
                <img className="service-shape-2 bounce" src={paymentGetwaysimagetask2} alt="image" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card three">
                <i className="flaticon-layer" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Cost Saving</a>
                  </h4>
                  <p>Using FintechDaddy white label, businesses can save on the costs associated with developing and maintaining their own payment processing infrastructure.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card four">
                <i className="flaticon-united" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Time Saving</a>
                  </h4>
                  <p>By outsourcing the technical aspects of payment processing to a third-party provider, businesses can save time and resources.</p>
                </div>
                <img className="service-shape-5 bounce" src={paymentGetwaysimagetask3} alt="image" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card five">
                <i className="flaticon-growth" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Enhanced Security & Compliance</a>
                  </h4>
                  <p>Fintechdaddy always takes care of its clients and makes sure that our platform is safe & compliant with industry regulations and standards.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card five">
                <i className="flaticon-growth" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Access To Advanced Features</a>
                  </h4>
                  <p>We often offer advanced features such as fraud detection, chargeback management, and multi-currency support, which can help businesses expand their customer.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* shape images */}
        <img className="service-shape-1 rotate" src={paymentGetwaysimagetaskshape1} alt="image" />
        <img className="service-shape-4 rotate" src={paymentGetwaysimagetaskshape2} alt="image" />
      </div>

      {/* <div className="modern-graphic-area-one ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 mb-3">
              <h2 className="text-light">What Industry Experts Say About White Lable Gateway Solution</h2>
              <p className="text-light text-center">Industry experts generally agree that white label payment gateways offer several advantages for businesses, including enhanced brand recognition, cost savings, and improved customer experience.</p>
            </div>
            <div className="col-lg-6">
              <div className="modern-info">
                <div className="section-heading">

                </div>
                <ul>
                  <li>
                    <i className="flaticon-check" />For example, David Marcus, the former president of PayPal and current head of Facebook's cryptocurrency division, has said that white label payment gateways can help businesses build customer trust and loyalty by providing a seamless and integrated payment experience.

                  </li>
                  <li>
                    <i className="flaticon-check" />Similarly, Chris Britt, the CEO of the digital banking platform Chime, has noted that white label payment gateways can help businesses save on the costs associated with developing and maintaining their own payment processing infrastructure, while also providing a more customized and tailored experience for their customers.
                  </li>

                  <li>
                    <i className="flaticon-check" />In addition, industry analysts such as Forrester Research have highlighted the importance of white label payment gateways in enabling businesses to meet the evolving needs of their customers, particularly in industries such as e-commerce and mobile payments.
                  </li>

                </ul>
                <a className="common-btn two" href="#">Learn More</a>
                <img className="graphic-shape-3 rotate" src={paymentGetwaysimageprocess1} alt="image" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="graphics-img">
                <img className="paying-img" src={paymentGetwaysimageprocess2} alt="paying-img" data-cues="zoomIn" data-disabled="true" />
                <img className="graphics-img-1" src={paymentGetwaysimageprocess3} alt="image" />
                <img className="graphics-img-2 moveVertical" src={paymentGetwaysimageprocess4} alt="image" />
                <img className="graphics-img-3 moveHorizontal" src={paymentGetwaysimageprocess5} alt="image" />
                <img className="graphic-shape-1" src={paymentGetwaysimageprocess6} alt="image" />
                <img className="graphic-shape-2 moveVertical" src={paymentGetwaysimageprocess7} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* ------------------------- */}

      {/* <div className="client-say-area client-say-testimonials-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            <h2 className="text-light">Benefit Of Fintech Daddy White Label Gateway </h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Custom branding</h4>
                <p className="text-light">With a white label payment gateway, businesses can customize the user interface and branding to match their own brand, providing a seamless and integrated payment experience for their customers.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Improved customer experience</h4>
                <p className="text-light">By offering a white label payment gateway, businesses can provide a seamless and integrated payment experience for their customers, without having to redirect them to a third-party payment gateway.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Cost savings</h4>
                <p className="text-light">By using a white label payment gateway, businesses can save on the costs associated with developing and maintaining their own payment processing infrastructure.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Time savings</h4>
                <p className="text-light">By outsourcing the technical aspects of payment processing to a third-party provider, businesses can save time and resources that can be used for other aspects of their operations.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Enhanced security and compliance</h4>
                <p className="text-light">White label payment gateway providers are typically responsible for ensuring that their platform is secure and compliant with industry regulations and standards, which can help businesses avoid costly penalties and reputational damage.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Access to advanced features</h4>
                <p className="text-light">White label payment gateway providers often offer advanced features such as fraud detection, chargeback management, and multi-currency support, which can help businesses expand their customer base and improve their payment processing capabilities.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>

          </div>
        </div>
      </div> */}

      {/* Start Our Best Services Area */}
      {/* <div className="best-service-area pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="section-title">
            <h2 className="text-light">How Fintech Daddy White Lable Gateway Solution Work’s</h2>
            <p>Fintech Daddy white label payment gateway works by allowing businesses to process payments under their own brand name, while using the technology and services of a third-party payment gateway provider.
            </p>
          </div>
          <div className="row">
            <h5 className="text-center text-light my-3">Here is a basic overview of how Fintech Daddy white label payment gateway works:</h5>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card  text-center">
                <div className="awesome-image">
                  <img src={bestService1} alt="image" />
                </div>
                <h4>
                </h4>
                <p className="text-light">The business partners with a white label payment gateway provider to access their payment processing technology and services.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card two text-center">
                <div className="awesome-image">
                  <img src={bestService2} alt="image" />
                </div>
                <h4>
                </h4>
                <p className="text-light">Fintech Daddy payment gateway provider integrates their payment processing technology with the business's website or application, allowing customers to make payments directly on the business's platform.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <img src={bestService3} alt="image" />
                </div>
                <h4>
                </h4>
                <p className="text-light">The business customizes the payment gateway user interface and branding to match their own brand, providing a seamless and integrated payment experience for their customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End Our Best Services Area */}

      {/* Start Boost Your Business */}
      {/* <div className="experience-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5" >
              <div className="experience-image">
                <img className="experience-img" src={boostYourBussiness1} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="business-experience">
                <div className="section-heading">
                  <h2 className="text-light">Enjoy Build Your Business Experience</h2>
                  <p className="text-light">Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain, but because occasionally circumstances occur</p>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-xl-6">
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img src={boostYourBussiness2} alt="image" />
                        </div>
                        <h4 className="text-light">Business Solutions</h4>
                      </div>
                      <p>Which is the same as saying through shrinking from toil Ipain</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-xl-6">
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img src={boostYourBussiness3} alt="image" />
                        </div>
                        <h4 className="text-light">Market Strategy</h4>
                      </div>
                      <p>Which is the same as saying through shrinking from toil Ipain</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End Boost Your Business */}
      <section className="bg-dark pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">Join Our Partner Program</h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>  Know More <i className="flaticon-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>

      {/* FAQ */}
      <div className="faq-area ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-image-">
                <img src={paymentGetwayfaq} alt="faq" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is FintechDaddy white label payment gateway?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy white label payment gateway is a payment processing solution that allows businesses to process payments under their own brand name, while using the technology and services of a third-party payment gateway provider.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How does FintechDaddy white label payment gateway work?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy white label payment gateway works by allowing businesses to process payments under their own brand name, while using the technology and services of a third-party payment gateway provider. The provider integrates their payment processing technology with the business's website or application, and the business customizes the user interface and branding to match their own brand.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What are the benefits of using  white label payment gateway?</Accordion.Header>
                      <Accordion.Body>
                        The benefits of using  white label payment gateway include improved brand recognition, enhanced customer experience, cost savings, time savings, and access to advanced features such as fraud detection, chargeback management etc.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>How do I choose  FintechDaddy white label payment gateway provider?</Accordion.Header>
                      <Accordion.Body>
                        When choosing FintechDaddy white label payment gateway provider, consider factors such as their reputation, level of customer support, pricing and fees, security and compliance, and range of features and services offered. It's also important to ensure that the provider's technology is compatible with your business's website or application.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>What types of businesses can benefit from using FintechDaddy white label payment gateway?
                      </Accordion.Header>
                      <Accordion.Body>
                        Any type of business that accepts payments online can benefit from using a white label payment gateway, including e-commerce stores, mobile apps, subscription services, and more.

                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whitelevel;