import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import bannerpayment from '../../assets/images/banner1.jpg';
import paymentGetwayfaq from '../../assets/images/faq-img.png';
import { NavLink } from "react-router-dom";
import { Row } from 'react-bootstrap';
function Faqs() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Start Page Banner Area */}
      <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="page-banner-heading text-center">
                <h1 style={{ color: 'white' }}>Frequently Asked Questions</h1>
                <ul>
                  <li>
                    <NavLink className="nav-link active" to="/">Home</NavLink>
                  </li>
                  <li>FAQ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FAQ */}
      <div className="faq-area py-5 bg-color-100f0f">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <div className="solution">
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="00">
                      <Accordion.Header>Which platforms can I integrate the payment gateway with?</Accordion.Header>
                      <Accordion.Body>
                        Our payment gateway supports a wide range of platforms, including e-commerce websites, mobile apps, and online marketplaces. We provide integration plugins, APIs, and SDKs for popular platforms like Shopify, WooCommerce, Magento, and more.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="01">
                      <Accordion.Header>Is the integration process complex?</Accordion.Header>
                      <Accordion.Body>
                        Not at all. We offer user-friendly integration guides, documentation, and developer support to make the process smooth. Depending on your technical expertise, you can choose from simple plug-and-play options to custom API integrations.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="02">
                      <Accordion.Header>Can I customize the payment process on my website?</Accordion.Header>
                      <Accordion.Body>
                        Absolutely. FintechDaddy allows you to customize the payment flow to match your brand's look and feel. You can design seamless checkout experiences and embed payment forms directly on your website.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="03">
                      <Accordion.Header>How do I handle failed transactions or refunds?</Accordion.Header>
                      <Accordion.Body>
                        Our integration includes mechanisms to handle failed transactions and initiate refunds seamlessly. You can set up notifications for transaction statuses and manage refunds directly through our user-friendly dashboard.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="04">
                      <Accordion.Header>How do I get started with FintechDaddy integration?</Accordion.Header>
                      <Accordion.Body>
                        Getting started is easy. Simply reach out to our team or visit our website to access integration guides, APIs, and documentation. We'll guide you through the steps to integrate our payment gateway seamlessly into your platform.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is FintechDaddy payment button?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy payment button is a clickable button that allows customers to make a payment directly from a website or app. It is typically designed to be simple and easy to use, with a clear call-to-action and minimal distractions.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How does FintechDaddy payment button work?</Accordion.Header>
                      <Accordion.Body>
                        When a customer clicks on payment button, they are typically directed to a payment gateway page where they can enter their payment information and complete the transaction. FintechDaddy Payment buttons can be used to accept various payment types such as credit cards, debit cards, e-wallets, and bank transfers.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What are the benefits of using FintechDaddy payment button?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy Payment buttons can help businesses to increase conversions, improve the customer experience, and provide a more efficient and secure way for customers to make payments.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>What payment options can be accepted with FintechDaddy payment button?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy Payment buttons can typically accept various payment types such as credit cards, debit cards, e-wallets, and bank transfers.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Is it safe to use FintechDaddy payment button?</Accordion.Header>
                      <Accordion.Body>
                        Yes, FintechDaddy payment button designed with security in mind, using encryption and other security measures to protect sensitive data such as credit card numbers and personal information. However.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>What is a QR code gateway?</Accordion.Header>
                      <Accordion.Body>
                        A QR code gateway is a type of payment gateway that uses QR codes to facilitate transactions. Customers can use their mobile devices to scan the QR code and complete a transaction.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>How do I use FintechDaddy QR code gateway?</Accordion.Header>
                      <Accordion.Body>
                        To use FintechDaddy QR code gateway, you need a mobile device with a QR code scanner app. Once you have the app, you simply scan the QR code provided FintechDaddy, and follow the instructions to complete the transaction.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Is using FintechDaddy QR code gateway secure?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy QR code gateways typically use encryption and other security measures to protect customer information and prevent fraud.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>What types of transactions can be processed using FintechDaddy QR code gateway?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy QR code gateway can be used to process a variety of transactions, including retail purchases, bill payments, and donations.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9">
                      <Accordion.Header>What if I have trouble scanning the QR code?</Accordion.Header>
                      <Accordion.Body>
                        If you have trouble scanning the QR code, try adjusting the lighting or angle of your device. If the problem persists, you may need to contact the FintechDaddy Support for assistance.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="10">
                      <Accordion.Header>What is a payment link?</Accordion.Header>
                      <Accordion.Body>
                        A payment link is a URL or hyperlink that allows customers to make a payment online. Payment links can be used to accept various payment types such as credit cards, debit cards, e-wallets, and bank transfers.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                      <Accordion.Header>How does a payment link work?</Accordion.Header>
                      <Accordion.Body>
                        A business generates a payment link from FintechDaddy and shares it with the customer via email, text message, social media, or other channels. The customer clicks on the link and is directed to a payment page where they can enter their payment details and complete the transaction.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="12">
                      <Accordion.Header>Is it safe to use payment links?</Accordion.Header>
                      <Accordion.Body>
                        Payment links are typically designed with security in mind, using encryption and other security measures to protect sensitive data. However, businesses should ensure they are using a reputable payment gateway provider Like FintechDaddy and follow best practices for securing customer data.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13">
                      <Accordion.Header>Are there any fees associated with using payment links?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy typically charge fees for processing payments, which can include transaction fees, monthly fees, or other charges. The fee structure can vary depending on the provider and the specific services offered.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="14">
                      <Accordion.Header>Can payment links be customized with branding or design elements?</Accordion.Header>
                      <Accordion.Body>
                        Yes, payment links can often be customized with business branding, logos, and other design elements to provide a consistent and professional look and feel.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="15">
                      <Accordion.Header>Are payment links suitable for all businesses?</Accordion.Header>
                      <Accordion.Body>
                        Payment links may not be suitable for all businesses or transactions. Businesses may need to consider factors such as the types of payments they can accept or the level of customization they require when using payment links.

                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="16">
                      <Accordion.Header>What is invoice payment?</Accordion.Header>
                      <Accordion.Body>
                        Invoice payment is a process by which customers pay for goods or services that have been provided by a business, based on an invoice that has been issued.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="17">
                      <Accordion.Header>How does FintechDaddy invoice payment work?</Accordion.Header>
                      <Accordion.Body>
                        After a business provides goods or services to a customer, an invoice is issued detailing the amount owed and the payment terms. The customer then makes a payment to the business based on the invoice
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="18">
                      <Accordion.Header>What are the benefits of FintechDaddy invoice payment?</Accordion.Header>
                      <Accordion.Body>
                        Benefits of FintechDaddy invoice payment include faster payment processing, increased accuracy, improved cash flow management, greater convenience, reduced administrative burden, and enhanced security.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="19">
                      <Accordion.Header>What payment methods are typically accepted for FintechDaddy invoice payments?</Accordion.Header>
                      <Accordion.Body>
                        Payment methods for invoice payments can vary, but typically include credit or debit cards, bank transfers, and online payment platforms.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="20">
                      <Accordion.Header>What happens if a customer does not pay an invoice on time?</Accordion.Header>
                      <Accordion.Body>
                        If a customer does not pay an invoice on time, the business may need to follow up with the customer to request payment. This may involve sending reminders, issuing late payment fees, or taking legal action if necessary.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="21">
                      <Accordion.Header>What is a payment gateway page?</Accordion.Header>
                      <Accordion.Body>
                        A payment gateway page is a secure webpage that allows customers to enter their payment details and complete a transaction online.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="22">
                      <Accordion.Header>How Does FintechDaddy Payment Page Work?</Accordion.Header>
                      <Accordion.Body>
                        When a customer makes a purchase, they are directed to the payment gateway page where they can enter their payment information. FintechDaddy then processes the transaction and returns the result to the merchant
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="23">
                      <Accordion.Header>Is it safe to use FintechDaddy payment gateway page?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy Payment Page are typically designed with security in mind, using encryption and other security measures to protect sensitive data.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="24">
                      <Accordion.Header>What payment types are supported by FintechDaddy payment gateway page?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy can support multiple payment types, such as credit cards, debit cards, e-wallets, and bank transfers.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="25">
                      <Accordion.Header>Can FintechDaddy payment gateway page be customized with branding or design elements?</Accordion.Header>
                      <Accordion.Body>
                        Yes, FintechDaddy can often be customized with business branding, logos, and other design elements to provide a consistent and professional look and feel.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="26">
                      <Accordion.Header>What is the Pay Now option?</Accordion.Header>
                      <Accordion.Body>
                        The "Pay Now" option is a feature of FintechDaddy that allows customers to make secure online payments quickly and easily without the need for additional steps such as creating an account or logging in.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="27">
                      <Accordion.Header>Is the Pay Now option secure?</Accordion.Header>
                      <Accordion.Body>
                        Yes, the Pay Now option is a secure way to make online payments. FintechDaddy use advanced security measures such as encryption and tokenization to protect customers' payment information.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="28">
                      <Accordion.Header>Do I need to create an account to use the Pay Now option?</Accordion.Header>
                      <Accordion.Body>
                        No, you do not need to create an account to use the Pay Now option. Simply enter your payment information and complete the transaction.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="29">
                      <Accordion.Header>Can I use the Pay Now option for all types of purchases?</Accordion.Header>
                      <Accordion.Body>
                        It depends on what type of business you run. The "Pay Now" option may not be available for all types of purchases or on all websites.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="30">
                      <Accordion.Header>How fast is the Pay Now option?</Accordion.Header>
                      <Accordion.Body>
                        The Pay Now option is a fast way to make online payments. Transactions are usually processed within seconds or minutes.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="31">
                      <Accordion.Header>Why should you choose to be a part of the FintechDaddy Partner program?</Accordion.Header>
                      <Accordion.Body>
                        The Fintech Daddy Partner Program is the most rewarding B2B affiliate program in India. As a Fintech Daddy partner, you not only get to earn unbeatable commissions but also offer a delightful and convenient digital payments experience for your customers.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="32">
                      <Accordion.Header>How much can I earn with the FintechDaddy Partner Program?</Accordion.Header>
                      <Accordion.Body>
                        With the FintechDaddy Partner Program, you get paid on a recurring basis, depending on how many transactions your affiliates complete with Fintech Daddy.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="33">
                      <Accordion.Header>How much time does the partner onboarding take?</Accordion.Header>
                      <Accordion.Body>
                        Once you submit the contact form, you will get a call from our team.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="34">
                      <Accordion.Header>I am a FintechDaddy merchant, can I also register as Partner?</Accordion.Header>
                      <Accordion.Body>
                        Yes, an existing FintechDaddy merchant can register as a Partner as well. In such a scenario you will be managing your merchant account and also get access to a sub dashboard where you can add new customer accounts.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="35">
                      <Accordion.Header>What is a reseller program?</Accordion.Header>
                      <Accordion.Body>
                        A reseller program is a business strategy where a company allows other businesses or individuals (resellers) to sell its products or services under their own brand name. The resellers earn a commission or discount on the services they sell.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="36">
                      <Accordion.Header>How does FintechDaddy reseller program work?</Accordion.Header>
                      <Accordion.Body>
                        In FintechDaddy reseller program, the reseller purchases services from the company they are partnering with at a discounted rate, and then resells them under their own brand name at a markup price. The reseller earns a commission or discount on the services they sell.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="37">
                      <Accordion.Header>What are the benefits of participating in FintechDaddy reseller program?</Accordion.Header>
                      <Accordion.Body>
                        The benefits of participating in FintechDaddy reseller program include additional revenue, low start-up costs, brand recognition, training and support, access to a wider customer base, and flexibility.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="38">
                      <Accordion.Header>Who is a good fit for FintechDaddy reseller program?</Accordion.Header>
                      <Accordion.Body>
                        Reseller programs are a good fit for individuals or businesses looking for additional revenue streams, or those who are interested in starting their own business with low start-up costs. Resellers who have an existing customer base or a strong online presence are also good candidates for reseller programs.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="39">
                      <Accordion.Header>How do I join FintechDady reseller program?</Accordion.Header>
                      <Accordion.Body>
                        To join FintechDaddy reseller program, you typically need to apply through the company's website or contact their sales team. The company will review your application and may ask for additional information about your business or marketing strategy. Once you are approved, you will receive access to the reseller program and its benefits.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="40">
                      <Accordion.Header>What is FintechDaddy white label payment gateway?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy white label payment gateway is a payment processing solution that allows businesses to process payments under their own brand name, while using the technology and services of a third-party payment gateway provider.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="41">
                      <Accordion.Header>How does FintechDaddy white label payment gateway work?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy white label payment gateway works by allowing businesses to process payments under their own brand name, while using the technology and services of a third-party payment gateway provider. The provider integrates their payment processing technology with the business's website or application, and the business customizes the user interface and branding to match their own brand.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="42">
                      <Accordion.Header>What are the benefits of using  white label payment gateway?</Accordion.Header>
                      <Accordion.Body>
                        The benefits of using  white label payment gateway include improved brand recognition, enhanced customer experience, cost savings, time savings, and access to advanced features such as fraud detection, chargeback management etc.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="43">
                      <Accordion.Header>How do I choose  FintechDaddy white label payment gateway provider?</Accordion.Header>
                      <Accordion.Body>
                        When choosing FintechDaddy white label payment gateway provider, consider factors such as their reputation, level of customer support, pricing and fees, security and compliance, and range of features and services offered. It's also important to ensure that the provider's technology is compatible with your business's website or application.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="44">
                      <Accordion.Header>What types of businesses can benefit from using FintechDaddy white label payment gateway?
                      </Accordion.Header>
                      <Accordion.Body>
                        Any type of business that accepts payments online can benefit from using a white label payment gateway, including e-commerce stores, mobile apps, subscription services, and more.

                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Faqs;