import React, { useEffect } from 'react';
import { useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'react-tabs/style/react-tabs.css';
import CountUp from 'react-countup';
import { Helmet } from "react-helmet";
// import 'node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'

import slider from '../../assets/images/Home_page/section 1 dashborad top.png';
import homeShape1 from '../../assets/images/shape/home-shape-1.png';
import homeShape2 from '../../assets/images/shape/home-shape-2.png';
import homeShape3 from '../../assets/images/shape/home-shape-3.png';
import homeShape4 from '../../assets/images/shape/home-shape-4.png';

import startaboutArea1 from '../../assets/images/about-img.png';
import startaboutArea2 from '../../assets/images/Home_page/Graph data Image.png';
import startaboutArea3 from '../../assets/images/Home_page/Graph left Image.jpg';
import startaboutArea4 from '../../assets/images/Home_page/Graph down Image copy.png';

import subscribe1 from '../../assets/images/shape/news-shape-1.png';
import subscribe2 from '../../assets/images/shape/news-shape-2.png';
import subscribe3 from '../../assets/images/shape/news-shape-3.png';
import subscribe4 from '../../assets/images/shape/news-shape-4.png';

import featurearea from '../../assets/images/shape/feater-shape1.png';

import counterimage1 from '../../assets/images/shape/funfact-shape-1.png';
import counterimage2 from '../../assets/images/shape/funfact-shape-2.png';

import mainCategoryImage1 from '../../assets/images/works-img-1.png';
import mainCategoryImage2 from '../../assets/images/works-img.png';
import mainCategoryImage3 from '../../assets/images/works-img-4.png';
import mainCategoryImage4 from '../../assets/images/works-img-2.png';
import mainCategoryImage5 from '../../assets/images/Home_page/Graph Right.png';

import workflow1 from '../../assets/images/and-img.png';
import workflow2 from '../../assets/images/user/user-1.png';

import blogImage1 from '../../assets/images/blog/latest-1.jpg';
import blogImage2 from '../../assets/images/svg/user.svg';
import blogImage3 from '../../assets/images/svg/comment.svg';
import blogImage4 from '../../assets/images/blog/latest-2.jpg';
import blogImage5 from '../../assets/images/svg/user.svg';
import paymentGetwaysimageUser2 from '../../assets/images/user/user-1.png';
import blogImage6 from '../../assets/images/svg/comment.svg';
import blogImage8 from '../../assets/images/blog/latest-3.jpg';
import testiminal from '../../assets/images/user/user-2.png';




function Home() {


    const sliderOptions1 = {
        loop: true,
        margin: 20,
        autoplay: true,
        autoplayTimeout: 3000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 2
            }
        }
    };

    const sliderItems1 = [
        { id: 1, image_data: testiminal, text_data: 'Swati Sharma', description_data: '“Fintech Daddy  has been a solid partner to rely on for our payment solutions and we are a heavy user of Payouts and Auto Collect. The best part is they are very customer centric and provide solutions with small TAT.”' },
        { id: 2, image_data: paymentGetwaysimageUser2, text_data: 'Gaurav Rastogi', description_data: '"Fintech Daddy  gives us next day settlements. For a startup like us it means money reaches our account without being blocked anywhere."' },
    ];


    const ULplantab = {
        paddingLeft: '0',
        textAlign: 'center',
        listStyleType: 'none'
    }
    const colorplanTab =
    {
        borderRadius: '20px',
        textAlign: 'center',
        background: '#171717',
        paddingTop: '50px',
        paddingBottom: '30px',
    }
    const icheck = {
        position: 'relative',
        top: '2px',
        right: '10px',
        fontSize: '18px',
        color: '#fd7529',
    }
    const ipanel = {
        color: '#ff0000',
        position: 'relative',
        top: '2px',
        right: '10px',
        fontSize: '18px',
    }
    const tabButton = {
        // marginLeft: '36%',
        // padding: '12px',
        // width: '30%',
        // textAlign: 'center',
        // marginBottom: '20%',
    }
    const tabpanelUpper = {
        paddingBottom: '60px',
        background: 'black',
        paddingTop: '75px',
    }
    const pricingCardTab = {
        borderRadius: '36px',
    }
    const tabh2 = {
        color: '#ffffff',
    }
    const sectiontitletab = {
        marginBottom: '30px',
        textAlign: 'center',
    }
    const subtitletab = {
        border: 'none',
        padding: '0',
        marginBottom: '15px',
    }

    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        // Start Banner Area
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>India's Fastest Smart-Routing Payment Gateway | FintechDaddy</title>
                <meta name="description" content="FintechDaddy offers convenient payment options and empowering your businesses
with exceptional customer satisfaction for secure & efficient payment options"></meta>
            </Helmet>

            {/* {Start Banner Area} */}
            <div class="home-area bg-color-100f0f">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="home-content">
                                <span>Big Daddy's of Fintech</span>
                                <h1>India's Fastest Smart-Routing Payment Gateway</h1>
                                <p>FintechDaddy payment gateway provider would partner with multiple payment processors and banks. This would allow customers to make payments through a wide range of channels, including credit and debit cards, net banking, mobile wallets, and more.</p>
                                <div class="button-group gap-4">
                                    <a class="banner-btn" href="/Signup">Get Started</a>
                                    <a class="banner-btn-2" href="#">Intro Video</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="home-img">
                                <img
                                    src={slider}
                                    alt="banner-img"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <img class="home-shape-1" src={homeShape1} alt="home-shape" />
                <img class="home-shape-2" src={homeShape2} alt="home-shape" />
                {/* <img class="home-shape-3 rotate" src={homeShape3} alt="home-shape" /> */}
                <img class="home-shape-4 rotate" src={homeShape4} alt="home-shape" />
            </div>
            {/* {Ens Banner Area } */}
            {/* Start Counter Area */}
            <div class="counter-area funfact-area pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-6">
                            <div class="count-info">

                                <h3 class="odometer odometer-auto-theme" data-count="34000"> <CountUp start={0} end={34000} /></h3>
                                <span class="target">+</span>
                                <p>Merchant</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6">
                            <div class="count-info two">
                                <h3 class="odometer odometer-auto-theme" data-count="50000"><CountUp start={0} end={50000} /></h3>
                                <span class="target">+</span>
                                <p>No. Of Transaction</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-6">
                            <div class="count-info three">
                                <h3 class="odometer odometer-auto-theme" data-count="5000"><CountUp start={0} end={5000} /></h3>
                                <span class="target">+</span>
                                <p>TXN/Sec Capability</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-6">
                            <div class="count-info four">
                                <h3 class="odometer odometer-auto-theme" data-count="2500"><CountUp start={0} end={2500} /></h3>
                                <span class="target">+</span>
                                <p>Account Served</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="funfact-shape-1" src={counterimage1} alt="funfact-shape" />
                <img class="funfact-shape-2" src={counterimage2} alt="funfact-shape" />
            </div>
            {/* End Counter Area */}
            {/* Start About Area */}
            <div class="about-area ptb-100 bg-color-100f0f">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-image">
                                <img class="about-img" src={startaboutArea1} alt="about-img" />
                                <img class="about-3" src={startaboutArea2} alt="about-img" style={{ width: "70%" }} />
                                <img class="about-1" src={startaboutArea3} alt="about-img" />
                                <img class="about-2" src={startaboutArea4} alt="about-img" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-content">
                                <div class="section-heading">
                                    {/* <span class="sub-title">About Us</span> */}
                                    <h2>Our Amazing Features</h2>
                                    <p>We offer a range of features that make it easy and convenient for customers to make payments and for businesses to receive them. Here are some amazing features:</p>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 col-sm-6 col-md-6">
                                        <div class="check-card d-flex position-relative align-items-center">
                                            <i class="flaticon-tick"></i>
                                            <div class="info">
                                                <h4>High Success Ratio</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6 col-md-6">
                                        <div class="check-card d-flex position-relative align-items-center">
                                            <i class="flaticon-tick"></i>
                                            <div class="info">
                                                <h4>Smart Routing Technology</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6 col-md-6">
                                        <div class="check-card d-flex position-relative align-items-center">
                                            <i class="flaticon-tick"></i>
                                            <div class="info">
                                                <h4>Instant Activation</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6 col-md-6">
                                        <div class="check-card d-flex position-relative align-items-center">
                                            <i class="flaticon-tick"></i>
                                            <div class="info">
                                                <h4>Easy Integration</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6 col-md-6">
                                        <div class="check-card d-flex position-relative align-items-center">
                                            <i class="flaticon-tick"></i>
                                            <div class="info">
                                                <h4>Instant Settlement</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6 col-md-6">
                                        <div class="check-card d-flex position-relative align-items-center">
                                            <i class="flaticon-tick"></i>
                                            <div class="info">
                                                <h4>UPI/QR Intent</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area  */}
            {/* Start Feature Area */}
            <div class="feature-area ptb-100 bg-color-0b0909">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="feature-info">
                                <div class="section-heading">
                                    {/* <span class="sub-title">Our Awesome Features</span> */}
                                    <h2>Looking For a Payment Gateway in India ? </h2>
                                    <p>With  Very Simple & Easy Steps You Can Connect With Us. And Boost Your Business Like Rocket</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="single-features">
                                <div class="check-card d-flex position-relative align-items-center">
                                    <div class="warp">
                                        <i class="flaticon-diagram"></i>
                                    </div>
                                    <div class="info">
                                        <h4>Register on our website</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="single-features two">
                                <div class="check-card d-flex position-relative align-items-center">
                                    <div class="warp">
                                        <i class="flaticon-problem-solving"></i>
                                    </div>
                                    <div class="info">
                                        <h4>Complete KYC</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="single-features three">
                                <div class="check-card d-flex position-relative align-items-center">
                                    <div class="warp">
                                        <i class="flaticon-browser"></i>
                                    </div>
                                    <div class="info">
                                        <h4>Start Accepting Payments</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img class="feater-shape rotate" src={featurearea} alt="feater-shape" />
            </div>
            {/* End Feature Area */}
            {/* Start How It Works Area */}
            <div class="works-area ptb-100 bg-color-100f0f">
                <div class="container">
                    <div class="section-title">
                        {/* <span class="sub-title">How It Works</span> */}
                        <h2>The Way To Collect Payment</h2>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="works-categories">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="single-works">
                                            <div class="d-flex position-relative align-items-center">
                                                <div class="works">
                                                    <div class="check-card d-flex position-relative align-items-center">
                                                        <div class="warp">
                                                            <span>1</span>
                                                        </div>
                                                        <div class="works-info">
                                                            <h4>UPI Intent</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="single-works">
                                            <div class="d-flex position-relative align-items-center">
                                                <div class="works">
                                                    <div class="check-card d-flex position-relative align-items-center">
                                                        <div class="warp">
                                                            <span>2</span>
                                                        </div>
                                                        <div class="works-info">
                                                            <h4>QR Intent</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="single-works">
                                            <div class="d-flex position-relative align-items-center">
                                                <div class="works">
                                                    <div class="check-card d-flex position-relative align-items-center">
                                                        <div class="warp">
                                                            <span>3</span>
                                                        </div>
                                                        <div class="works-info">
                                                            <h4>UPI Collect</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="popup-image">
                                    <img class="works-img-1" src={mainCategoryImage1} alt="image" />
                                    <a class="popup-youtube popup-btn" onClick={() => setOpen(true)}>
                                        <i class="flaticon-play"></i>
                                        {/* <React.Fragment>
                                            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="zvw_DMamBhM" onClose={() => setOpen(false)} />
                                        </React.Fragment> */}


                                    </a><br></br>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="works-image">
                                <img class="works-img" src={mainCategoryImage2} alt="image" />
                                <img class="works-img-4" src={mainCategoryImage3} alt="image" />
                                <img class="works-img-2" src={mainCategoryImage4} alt="image" />
                                <img class="works-img-3" src={mainCategoryImage5} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End How It Works Area */}
            {/* {Start Our Best Services Area} */}
            <div class="service-area pt-100 pb-70 bg-color-0b0909">
                <div class="container">
                    <div class="section-heading">
                        <div class="row align-items-end">
                            <div class="col-lg-6 col-sm-7">
                                {/* <span class="sub-title">Our Awesome Products</span> */}
                                <h2>Our Best Service</h2>
                            </div>
                            <div class="col-lg-6 col-sm-5">
                                <div class="blog-btn">
                                    <a class="common-btn" href="#">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center home-services">
                        <div class="col-lg-4 col-md-6" src="#" >
                            <div class="service-card">
                                <div class="icon">
                                    <i class="flaticon-software"></i>
                                </div>
                                <h4>
                                    <a href="#">Security</a>
                                </h4>
                                <p>FintechDaddy provide a high level of security to protect sensitive customer and payment information.</p>
                                {/* <i class="flaticon-software software"></i> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" src="#" >
                            <div class="service-card">
                                <div class="icon">
                                    <i class="flaticon-layer"></i>
                                </div>
                                <h4>
                                    <a href="#">Multiple Payment Options</a>
                                </h4>
                                <p>Our Gateway offer a range of payment options, including credit/debit cards, net banking, mobile wallets, and other payment methods that are popular in the region.</p>
                                {/* <i class="flaticon-layer software"></i> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" src="#" >
                            <div class="service-card">
                                <div class="icon">
                                    <i class="flaticon-customer-service"></i>
                                </div>
                                <h4>
                                    <a href="#">Fast & Efficient Processing</a>
                                </h4>
                                <p>FintechDaddy Has ability to process payments quickly and efficiently, with minimal downtime or interruptions.</p>
                                {/* <i class="flaticon-customer-service software"></i> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" src="#" >
                            <div class="service-card">
                                <div class="icon">
                                    <i class="flaticon-layer"></i>
                                </div>
                                <h4>
                                    <a href="#">Easy Integration</a>
                                </h4>
                                <p>Our Gateway is easy to integrate with a range of websites and mobile apps, with a simple API and easy-to-use developer tools.</p>
                                {/* <i class="flaticon-layer software"></i> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" src="#" >
                            <div class="service-card">
                                <div class="icon">
                                    <i class="flaticon-customer-service"></i>
                                </div>
                                <h4>
                                    <a href="#">Robust Reporting & Analytics</a>
                                </h4>
                                <p>FintechDaddy Provide businesses Full detailed reporting and analytics tools to help them track payment activity and identify trends and opportunities.</p>
                                {/* <i class="flaticon-customer-service software"></i> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" src="#" >
                            <div class="service-card">
                                <div class="icon">
                                    <i class="flaticon-layer"></i>
                                </div>
                                <h4>
                                    <a href="#">Excellent Customer Support</a>
                                </h4>
                                <p>We provide responsive and helpful customer support, with 24/7 availability and multiple channels of communication.</p>
                                {/* <i class="flaticon-layer software"></i> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Our Best Services Area */}
            {/* Start Workflow Integration Area */}
            <div class="workflow-integration-area workflow-integration-area-2 ptb-100 bg-color-100f0f">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="task-img" data-group="images">
                                <img class="and-img" src={workflow1} alt="image" />
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="history">
                                <div class="section-heading">
                                    {/* <span class="sub-title">Workflow Integration</span> */}
                                    <h2>Complete Data Reports And Analysis History</h2>
                                    <p className="lh-sm fs-5">Customer Can Easily Access Their data reports and analysis history provided by Fintech Daddy. Its help businesses to manage their payment processing activities effectively and gain valuable insights into their customers' behavior.</p>
                                </div>

                                {/* <div class="history-card d-flex align-items-center position-relative">
                                    <img class="flex-shrink-0 me-3" src={workflow2} alt="image" />
                                    <div class="warp">
                                        <h4>Paige Turner</h4>
                                        <p>On the other hand, we denounce with righus indignan and dislike men who are so beguiled and delized</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Workflow Integration Area */}
            {/* Start Testimonials Area */}



            {/* <div className="client testimonials-area testimonials-area-2 pt-100 pb-70 bg-color-0b0808">
                <div className="container">
                    <div className="section-heading">
                        <h2>Our Client Feedback</h2>
                    </div>

                    <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions1}>
                        {sliderItems1.map(item => (

                            <div className="testimonials-box testimonials-card">
                                <div className="box d-flex position-relative align-items-center">
                                    <img className="flex-shrink-0 me-3" src={item.image_data} alt="image" />
                                    <div className="name">
                                        <h4 className="text-light">{item.text_data}</h4>
                                    </div>
                                </div>
                                <p className="text-light">{item.description_data}</p>
                                <i className="flaticon-straight-quotes" />
                            </div>
                        ))}
                    </OwlCarousel>

                </div>
            </div> */}

            {/* End Testimonials Area */}
            {/* Start latest Area */}
            <div className="latest-area pt-100 pb-70 bg-dark">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title">Our Blog</span>
                        <h2>See Our Latest News</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="latest-card">
                                <div className="latest-img">
                                    <a href="/Blog-page">
                                        <img src={blogImage1} alt="image" />
                                    </a>
                                    <div className="date">
                                        <p>25 July 2022</p>
                                    </div>
                                </div>
                                <div className="latest-body">
                                    <ul>
                                        <li>
                                            <img src={blogImage2} alt="image" />
                                            By <a href="/Blog-page">Admin</a>
                                        </li>
                                        <li>
                                            <img src={blogImage3} alt="image" />
                                            No Comment
                                        </li>
                                    </ul>
                                    <h4>
                                        <a href="/Blog-page">The Best Remote UX And UI Design Conferences</a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="latest-card">
                                <div className="latest-img">
                                    <a href="/Blog-page">
                                        <img src={blogImage4} alt="image" />
                                    </a>
                                    <div className="date">
                                        <p>27 July 2022</p>
                                    </div>
                                </div>
                                <div className="latest-body">
                                    <ul>
                                        <li>
                                            <img src={blogImage5} alt="image" />
                                            By <a href="/Blog-page">Admin</a>
                                        </li>
                                        <li>
                                            <img src={blogImage6} alt="image" />
                                            No Comment
                                        </li>
                                    </ul>
                                    <h4>
                                        <a href="/Blog-page">Technology Is Very Important Present And Future</a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="latest-card">
                                <div className="latest-img">
                                    <a href="/Blog-page">
                                        <img src={blogImage8} alt="image" />
                                    </a>
                                    <div className="date">
                                        <p>29 July 2022</p>
                                    </div>
                                </div>
                                <div className="latest-body">
                                    <ul>
                                        <li>
                                            <img src={blogImage5} alt="image" />
                                            By <a href="/Blog-page">Admin</a>
                                        </li>
                                        <li>
                                            <img src={blogImage6} alt="image" />
                                            No Comment
                                        </li>
                                    </ul>
                                    <h4>
                                        <a href="/Blog-page">Most Finance Chart Powerful Features To Build Anything</a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End latest Area */}

            {/* Start Get Started Instantly Area */}
            <div class="newsletter-area ptb-100 bg-color-100f0f">
                <div class="container">
                    <form class="newsletter-form">
                        <div class="section-heading">
                            <span class="sub-title">Get Started Instantly</span>
                            <h2>Get The Only New Update From This Newsletter</h2>
                        </div>
                        <div class="form-group newsletter-form">
                            <input type="email" name="email" class="form-control" placeholder="Enter Your Email" required autocomplete="off" />

                            <button type="submit" class="newsletter-btn">Subscribe</button>
                            <div id="validator-newsletter" class="form-result"></div>
                        </div>
                    </form>
                </div>

                <img class="news-shape-1" src={subscribe1} alt="news-shape" />
                <img class="news-shape-2 rotate" src={subscribe2} alt="news-shape" />
                <img class="news-shape-3 rotate" src={subscribe3} alt="news-shape" />
                <img class="news-shape-4" src={subscribe4} alt="news-shape" />
            </div>
            {/* End Get Started Instantly Area */}








        </div>

    );

}

export default Home;