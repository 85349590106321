import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import image1 from '../../assets/images/paybill.svg';
import { NavLink } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
function Documents() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FintechDaddy | On-Boarding Required Documents List</title>
        <meta name="description" content="Easily onboard by following the required documents list specifically tailored to match
your business entity's needs.
"></meta>
      </Helmet>

      {/* Start Page Banner Area */}
      <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="page-banner-heading text-center">
                <h1 style={{ color: 'white' }}>Documents Required</h1>
                <ul>
                  <li>
                    <NavLink className="nav-link active" to="/">Home</NavLink>
                    {/* <a href="index.html">Home</a> */}
                  </li>
                  <li>Documents Required</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Banner Area */}
      <div className="experience-area pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">Documents required for Payment Gateway</h2>
                  <p className="text-light">Get help with the documents required and onboarding compliance tips for Fintech checkout with multiple payment gateway aggregators.</p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image d-flex justify-content-center">
                <img className="experience-img" src={image1} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-area ptb-100 bg-dark">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle text-center d-block">Document list</span>
                  <h2 className="text-light text-center">Document list for Fintech checkout services</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Documents required For Private Limited/Public Limited</Accordion.Header>
                      <Accordion.Body>
                        <div class="card-body">
                          <ul>
                            <li>Proof for Business ID - Certificate of Incorporation</li>
                            <li>Proof of Business Existence - Company PAN</li>
                            <li>Proof of Business working - Current account statement for the last three
                              months or whichever amount of time from the activation of the account.
                              OR a cancelled cheque copy </li>
                            <li>Proof of Identity of Business Owners and Authorized Signatory:
                              Government approved Authorized Signatory identity proof (Like Aadhar
                              card/Voter Card/DL/Passport etc.)</li>
                            <li>Board Resolution / Authorisation Letter for the Signing Authority duly
                              signed and stamped by at least two Directors / Trustee /Members </li>
                            <li>FSSAI Certificate for any food-related business</li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Documents required For Trust/Society</Accordion.Header>
                      <Accordion.Body>
                        <div className="card-body">
                          <p className="font-weight-normal h6">In addition to the above list for Private
                            Limited/Public Limited, Trust/Society would require the following documents
                          </p>
                          <ul>
                            <li>Form 80G </li>
                            <li>Form 12A </li>
                            <li>Society Registration Certificate</li>
                            <li>Trust Act Certificate issued by Government for Trust</li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Documents required for a Sole Proprietorship</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>Proof for Business ID - Any one of these - Sales Tax Registration OR VAT
                            Registration OR Shops and Establishments Certificate OR Trade License,
                            TIN</li>
                          <li>Proof of Business working - Current account statement for three months
                            or whichever amount of time from the activation of the account</li>
                          <li>Proof of Identity of Business Owners - PAN Card of the authorized
                            signatory</li>
                          <li>Proof of address of Business Owners - Government approved Identity and
                            address Proof of Proprietor (Like personal Aadhar card/Voter
                            Card/DL/Passport.)</li>
                          <li>GST Document or Declaration of GST Non-Enrolment (if turnover more than
                            ₹20 lacs)</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Documents required for Partnership/LLP</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>Proof of Business Identity - Any one of these Sales Tax Registration OR
                            VAT /TIN Registration OR Shops and Establishments Certificate OR Trade
                            License</li>
                          <li>Proof of Business Existence - Company PAN</li>
                          <li>Proof of Business working - Current account statement for the last three
                            months or whichever amount of time from the activation of the account.
                            OR a cancelled cheque copy </li>
                          <li>Proof of Identity of Business Owners and Authorized Signatory:
                            Government approved Authorized Signatory identity proof (Like Aadhar
                            card/Voter Card/DL/Passport etc.)</li>
                          <li>Authorization letter for the Signing Authority duly signed and stamped
                            by at least two partners</li>
                          <li>Partnership Deed/ LLP Deed</li>
                        </ul>

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Documents required for NGO/Non-Profit Organisations</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>Business Registration Proof: Trust Reg Cert/Notarized copy of the Trust
                            Deed</li>
                          <li>Business PAN: PAN Card in the name of the registered NGO</li>
                          <li>3 months bank account statements in the name of the registered business
                          </li>
                          <li>Both sides of Passport/Aadhaar/Election Id Card/DL of the Authorized
                            Signatory</li>
                          <li>Form 80G (Issued by the Income Tax Dept)</li>
                          <li>FCRA registration certificate (If accepting foreign donations)</li>
                        </ul>

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Documents required for School/College
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>Trust Registration Certificate(if the university is operated under a
                            Trust)</li>
                          <li>The affiliation certificate will work </li>
                          <li>PAN Card of the university</li>
                          <li>Cancelled Cheque</li>
                          <li>Address proof of authorised signatory of the university</li>
                          <li>PAN of authorised signatory</li>
                        </ul>

                      </Accordion.Body>
                    </Accordion.Item>


                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="workflow-integration-area ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 border rounded mx-auto py-5 bg-color-5c5c5c">
              <div className="history">
                <div className="section-heading mb-0">
                  <h4 className="text-light">Standard guidelines to be followed for every document:</h4>
                  <ul className="text-light mb-0">
                    <li>All documents need to be signed and stamped by an authorized signatory</li>
                    <li>Documents uploaded should be clear and valid</li>
                    <li>Supported formats for documents are JPG, PNG, PDF</li>
                    <li>Photo should be taken from the front and information should be visible clearly
                    </li>
                    <li>Documents should not be handwritten</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-area ptb-100 bg-dark">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle text-center d-block">Onboarding Checklist</span>
                  <h2 className="text-light text-center">Onboarding Checklist for accepting online payments</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>About Us</Accordion.Header>
                      <Accordion.Body>
                        <div className="card-body">
                          <p>This page must include all your business details, most importantly those
                            listed below:</p>
                          <ol type="A">
                            <li>Mission statement</li>
                            <li>A brief company description</li>
                            <li>Corporate history <br /> This page should state why people must buy your
                              product or get this service from you. What is it that sets you apart?
                              You can share your brand/company story, talk about founders and your
                              values.</li>
                          </ol>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Contact Us</Accordion.Header>
                      <Accordion.Body>
                        <div className="card-body">
                          <p>Make sure you include all the relevant details, including</p>
                          <ol type="A">
                            <li>Your business phone number and email id </li>
                            <li>A short contact form using fields that’ll help you understand who’s
                              contacting you</li>
                            <li>Easy to find, so that a visitor can instantly get in touch should they
                              need to</li>
                            <li>Explain why someone should contact you, and describe how you can help
                              solve your visitors’ problems</li>
                            <li>If available, add links to your social media accounts like Twitter,
                              Facebook, Instagram, and LinkedIn to give visitors a way to engage with
                              you</li>
                          </ol>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Product/Services Catalogue + Pricing</Accordion.Header>
                      <Accordion.Body>
                        <div class="card-body">
                          <p>The ideal catalogue is a good mix of all the below-mentioned points:</p>
                          <ol type="A">
                            <li>Showcase products and services in a visually appealing way</li>
                            <li>Product image </li>
                            <li>Product description</li>
                            <li>Product pricing</li>
                          </ol>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Privacy Policy</Accordion.Header>
                      <Accordion.Body>
                        <div class="card-body">
                          <p>A privacy policy is a statement that indicates a firm’s or website’s policy
                            on receiving and releasing information about a visitor. It needs to be clear
                            and straightforward.</p>
                          <ol type="A">
                            <li>Personal data - reveal the types of personal data your site or app
                              collects and how it is collected</li>
                            <li>How and why you use the information - explain to your users how this
                              data collection can benefit them and how it is used by your business
                            </li>
                            <li>Whether you distribute their personal data to anyone else and under what
                              conditions you do. Explain the rights of the users </li>
                          </ol>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Terms & Conditions</Accordion.Header>
                      <Accordion.Body>
                        <div class="card-body">
                          <p>Your customers must be able to view and follow the listed terms and
                            conditions.
                          </p>
                          <ol type="A">
                            <li>Make consumers accept the TnC agreement at sign up or log in</li>
                            <li>If you entertain advertisements from third parties, you should disclose
                              those relationships even if you are not paid for them</li>
                            <li>Subscription services (like SaaS apps) must incorporate payment terms in
                              their Terms &amp; Conditions</li>
                            <li>The termination clause contains your capacity to administer the Terms &amp;
                              Conditions</li>
                          </ol>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Returns, Refunds & Cancellation Policy </Accordion.Header>
                      <Accordion.Body>
                        <div class="card-body">
                          <p>The following vital information must be included in your policy:
                          </p>
                          <ol type="A">
                            <li>How can a customer initiate a return?</li>
                            <li>In how many days do customers have to initiate a return to get it?</li>
                            <li>Terms of returns (must be unworn, in original packaging, etc.)</li>
                            <li>Limitations on returnable items (no returns on software or
                              undergarments, etc.)</li>
                            <li>What form do you provide a refund in (the primary mode of payment, store
                              credit only, etc.)</li>
                            <li>If you or the customer pays return shipping costs</li>
                            <li>If you have any restocking or other fees, you deduct from refunds</li>
                          </ol>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>


                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ */}
      <div className="faq-area ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="faq-image">
                <img className="faq-img" src="assets/images/faq-img.png" alt="faq-img" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What are the conditions to get a payment gateway?</Accordion.Header>
                      <Accordion.Body>
                        Payment gateway documentation varies with your business entity type. At Fintech, we get your approvals from multiple payment gateway aggregators. This saves you the time and effort in sharing the same documents across multiple aggregators.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Is there any guideline to follow to get a payment gateway for my business?</Accordion.Header>
                      <Accordion.Body>
                        While sharing documents to get a payment gateway, broadly, two things need to be taken care of:
                        <ul>
                          <li> 1. Provide proper documentation as listed. </li>
                          <li>2. Website/app with updated policy and mandatory pages</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Is GST a mandatory document for payment gateway approval?</Accordion.Header>
                      <Accordion.Body>
                        GST is not mandatory if your annual business turnover is less than ₹20 lakhs.
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Documents;