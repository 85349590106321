import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import OwlCarousel from 'react-owl-carousel';
import slider from '../../image/slider.jpg';
import bannerpayment from '../../assets/images/banner1.jpg';
import image1 from '../../assets/images/Products/Payout/Payouts Image Top Right.png';
import imagelogo1 from '../../assets/images/Products/Payment Gateway/Bank Logo/axis bank.png';
import imagelogo2 from '../../assets/images/Products/Payment Gateway/Bank Logo/hdfc bank.png';
import imagelogo3 from '../../assets/images/Products/Payment Gateway/Bank Logo/icici bank.png';
import imagelogo4 from '../../assets/images/Products/Payment Gateway/Bank Logo/idbi bank.png';
import imagelogo5 from '../../assets/images/Products/Payment Gateway/Bank Logo/indus bank.png';
import imagelogo6 from '../../assets/images/Products/Payment Gateway/Bank Logo/yes bank.png';
import bestService1 from '../../assets/images/svg/awesome-1.svg';
import bestService2 from '../../assets/images/svg/awesome-2.svg';
import bestService3 from '../../assets/images/svg/awesome-3.svg';

import boostYourBussiness1 from '../../assets/images/Products/Payout/How Payout Work.png';
import boostYourBussiness2 from '../../assets/images/svg/business-1.svg';
import boostYourBussiness3 from '../../assets/images/svg/business-2.svg';

import paymentGetwaysimage1 from '../../assets/images/why1.png';
import paymentGetwaysimage2 from '../../assets/images/why2.png';
import paymentGetwaysimage3 from '../../assets/images/why3.png';
import paymentGetwaysimage4 from '../../assets/images/why4.png';
import paymentGetwaysimage5 from '../../assets/images/how.png';

import paymentGetwaysimageprocess1 from '../../assets/images/Products/Payout/payout Image 1.png';
import paymentGetwaysimageprocess22 from '../../assets/images/Products/Payout/payout image 2.png';
import paymentGetwaysimageprocess33 from '../../assets/images/Products/Payout/Payouts Image 3.png';

import paymentGetwaysimageprocess2 from '../../assets/images/paying-img.png';
import paymentGetwaysimageprocess3 from '../../assets/images/Products/Payout/FintechDaddy Payout Features.png';

import paymentGetwaysimageprocess4 from '../../assets/images/graphics-img-2.png';
import paymentGetwaysimageprocess5 from '../../assets/images/graphics-img-3.png';
import paymentGetwaysimageprocess6 from '../../assets/images/shape/graphic-shape-1.png';
import paymentGetwaysimageprocess7 from '../../assets/images/shape/graphic-shape-2.png';
import paymentGetwaysimageprocess70 from '../../assets/images/Products/Payout/Instant payout in real time.png';


import paymentGetwaysimageprocessbar1 from '../../assets/images/shape/process-shape-1.png';
import paymentGetwaysimageprocessbar2 from '../../assets/images/shape/process-shape-2.png';



import paymentGetwaysimagetask1 from '../../assets/images/Products/Payout/No Devloper No worry iMage.png';
import paymentGetwaysimagetask2 from '../../assets/images/task-img-2.png';
import paymentGetwaysimagetask3 from '../../assets/images/task-img-3.png';
import paymentGetwaysimagetaskshape1 from '../../assets/images/shape/task-shape-1.png';
import paymentGetwaysimagetaskshape2 from '../../assets/images/shape/task-shape-2.png';
import paymentGetwaysimageUser2 from '../../assets/images/user/user-1.png';
import paymentGetwaysimageteamShape from '../../assets/images/shape/team-shape.png';
import { NavLink } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

function Payout() {

  const sliderOptions = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      }
    }
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FintechDaddy Secure Payout Solutions - Instant & Bulk Transfer</title>
        <meta name="description" content="Instantly transfer payments or execute bulk transactions with ease and precision to
Streamline your payments with our efficient & secure payout solution.
"></meta>
      </Helmet>
      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">India’s Most Trusted Payout Solution</h2>
                  <p className="text-light">Payouts helps you make vendor payments and customer payouts efficiently. Pay vendors, do instant customer refunds, etc instantly 24x7, even on a bank holiday.</p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/Signup">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="experience-image">
                <img className="experience-img" src={image1} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="process-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            <h2 className="text-light">Benefit of The Full Stack payout capabilities</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img className="process-1" src={paymentGetwaysimageprocess1} alt="image" />
                </div>
                <h4 className="text-light">Payouts to Bank Accounts</h4>
                <p>24*7, IMPS, NEFT, RTGS and UPI even on Bank Holidays*</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img className="process-1" src={paymentGetwaysimageprocess22} alt="image" />
                </div>
                <h4 className="text-light">Payouts to Cards</h4>
                <p>24*7 IMPS, NEFT, Visa Direct and Mastercard</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img className="process-1" src={paymentGetwaysimageprocess33} alt="image" />
                </div>
                <h4 className="text-light">Payouts to Wallets</h4>
                <p>GooglePe, Paytm, Phonepay Etc.</p>
              </div>
            </div>

          </div>
        </div>
        {/* shape-images */}
        <div>
          {/* <img className="process-shape-1" src={paymentGetwaysimageprocessbar1} alt="process-shape" /> */}
        </div>
        <div>
          {/* <img className="process-shape-2" src={paymentGetwaysimageprocessbar2} alt="process-shape" /> */}
        </div>
      </div>

      <div className="workflow-integration-area ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="task-img">
                <img className="task-img-1" src={paymentGetwaysimagetask1} alt="image" />
                {/* <img className="task-shape-1" src={paymentGetwaysimagetaskshape1} alt="image" /> */}
                {/* <img className="task-img-2" src={paymentGetwaysimagetask2} alt="image" /> */}
                {/* <img className="task-img-3" src={paymentGetwaysimagetask3} alt="image" /> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="history">
                <div className="section-heading">
                  <h2 className="text-light">No Developer, Not Worry</h2>
                  <p className='fs-6 py-4 px-2 rounded border'>✔️ Keep it Simple with Dashboard Payouts</p>
                  <p className='fs-6 py-4 px-2 rounded border d-flex'><span>✔️</span> Transferred From the Payment Platform's Account to the Seller's Account.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* shape images */}
        <img className="task-shape-2" src={paymentGetwaysimagetaskshape2} alt="image" />
        <img className="team-shape" src={paymentGetwaysimageteamShape} alt="image" />
      </div>

      {/* brands */}

      <div className="vbnmr-area pt-4 bg-color-5c5c5c">
        <div className="container">
          <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions}>
            {sliderItems.map(item => (
              <div className="sponsor-logo" key={item.id}>
                <img src={item.image} alt="image" />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>


      <div className="modern-graphic-area-one ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-7">
              <div className="modern-info payout">
                <div className="section-heading">
                  {/* <span className="sub-title">Subscriptions On Payment Button</span> */}
                  <h2 className="text-light">FintechDaddy Payout Features at Your Fingertips</h2>
                  <p className="text-light">Easy and Convenient Payouts That Puts The Needs of Your Payees First</p>
                </div>
                <ul>
                  <li>
                    <i className="flaticon-check" />Instant Beneficiary additions
                    <p>No cool-off period between adding beneficiary and making payments.</p>
                  </li>
                  <li>
                    <i className="flaticon-check" />Searchable Transactions
                    <p>Find any transaction Of Anytime In Seconds</p>
                  </li>
                  <li>
                    <i className="flaticon-check" />Approvals Workflow
                    <p>Control Approval of Each Transaction & Stay on Top of Your Cash Flow.</p>
                  </li>
                </ul>
                <a className="common-btn two" href="/contact-us">Learn More</a>
                {/* <img className="graphic-shape-3 rotate" src={paymentGetwaysimageprocess1} alt="image" /> */}
              </div>
            </div>

            <div className="col-lg-5">
              <div className="graphics-img">
                <img className="paying-img" src={paymentGetwaysimageprocess2} alt="paying-img" data-cues="zoomIn" data-disabled="true" />
                <img className="graphics-img-1" src={paymentGetwaysimageprocess3} alt="image" />
                {/* shape images */}
                {/* <img className="graphics-img-2 moveVertical" src={paymentGetwaysimageprocess4} alt="image" /> */}
                {/* <img className="graphics-img-3 moveHorizontal" src={paymentGetwaysimageprocess5} alt="image" /> */}
                <img className="graphic-shape-1" src={paymentGetwaysimageprocess6} alt="image" />
                {/* <img className="graphic-shape-2 moveVertical" src={paymentGetwaysimageprocess7} alt="image" /> */}
              </div>
            </div>

          </div>
        </div>
      </div>


      {/* FAQ */}
      <div className="faq-area ptb-100 bg-dark">
        <div className="container">
          <div className="row">

            <div className="col-lg-5">
              <div className="">
                <img className="faq-img" src={paymentGetwaysimageprocess70} alt="faq-img" />
              </div>
            </div>

            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  {/* <span className="subtitle">FAQ</span> */}
                  <h2 className="text-light">Instant Payout In Real-Time </h2>
                </div>
                <div className="faq-content">
                  <Accordion show>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Quick Transfer</Accordion.Header>
                      <Accordion.Body>
                        Do Instant Payout With Quick Transfer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Bulk Payout</Accordion.Header>
                      <Accordion.Body>
                        Do Bulk Payout By Just Uploading Excel Or Google Sheet. Track Status in Real Time.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      {/* Start Boost Your Business */}
      <div className="experience-area pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="section-heading">
              <h2 className="text-light text-center">How Payout Work </h2>
              {/* <p className="text-light">Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain, but because occasionally circumstances occur</p> */}
            </div>
            <div className="col-lg-5" >
              <div className="experience-image">
                <img className="experience-img" src={boostYourBussiness1} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="business-experience payout_rule">

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-xl-12">
                    <div className="experience-card bg-transparent border border-1">
                      {/* <div className="heading align-items-center">
                     
                      </div> */}
                      <p>✔️All Payments and Payouts are Processed According to Indian Time, The processed Date May Not Be The Same as Your Local Time Zone.</p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-xl-12">
                    <div className="experience-card bg-transparent border border-1">
                      {/* <div className="heading align-items-center">
                       
                        <h4 className="ps-0 text-light fs-4">Security</h4>
                      </div> */}
                      <p>✔️Your Payout Schedule Refers to How Often  Fintech Daddy Sends Money to Your Bank Account.</p>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-xl-12">
                    <div className="experience-card bg-transparent border border-1">
                      {/* <div className="heading align-items-center">
                       
                        <h4 className="ps-0 text-light fs-4">Instant Settlement</h4>
                      </div> */}
                      <p>✔️In India, Payouts are Always Automatic. You Can Change This in The Dashboard to Weekly Automatic, Monthly Automatic, or Manual Payouts. When Selecting a Weekly or Monthly Schedule, You Can Specify The Day of The Week or Month That You Want Payouts to Arrive in Your Bank Account. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Start Boost Your Business */}
      {/* <div className="experience-area pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5" >
              <div className="experience-image">
                <img className="experience-img" src={boostYourBussiness1} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="business-experience">
                <div className="section-heading">
                  <h2 className="text-light">Here is why growing businesses trust Fintech Daddy  Payment Gateway</h2>
                  <p className="text-light">Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain, but because occasionally circumstances occur</p>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-xl-6">
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img src={boostYourBussiness2} alt="image" />
                        </div>
                        <h4 className="text-light">Built for very high scale</h4>
                      </div>
                      <p>Fintech Daddy Payment Gateway offers reliability and scales with you. Process 4000 transactions per second smoothly without any downtimes</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-xl-6">
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img src={boostYourBussiness3} alt="image" />
                        </div>
                        <h4 className="text-light">Security</h4>
                      </div>
                      <p>Our payment gateway should provide robust security measures, such as encryption, tokenization, and secure SSL connections. Highlight these measures in your content to show customers that their payment information is safe with you.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End Boost Your Business */}
      <section className="bg-dark pt-100 pb-70">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2">Join Our Partner Program</h2>
                  {/* <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>  Know More <i className="flaticon-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>


      {/* End Work Process Area */}
    </div>
  );
}

export default Payout;