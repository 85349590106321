import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import paymentGetwaysimage from '../../assets/images/001.png';
import paymentGetwaysimage1 from '../../assets/images/pay_methods_branding.png';

function PaymentsLinks() {
  const [Links, setLinks] = useState([]);
  useEffect(() => {
    loadlist();
  }, []);

  const loadlist = async () => {
    const segment = window.location.pathname.split("/");
    console.log(segment);
    axios.post("https://api.fintechdaddy.in/Payment-Details-Api-link", {
      id: segment['2'],
    })
      .then(res => {
        const link = res.data.data;
        console.log(link);
        setLinks(link);
      })
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Easy to Share - Custom & Quick Payment Link | FintechDaddy</title>
        <meta name="description" content="With FintechDaddy you can easily Create payment links & share them
effortlessly,Accept payments from credit card, debit card, net banking, UPI, wallets
etc.
"></meta>
      </Helmet>
      <div className="main-contact-area creation">
        <div className="container">

          <div className="row mt-5">
            <div className="col-lg-6">
              <div className="single-contact-bg" >
                <div className="single-contact-info">
                  <div className="d-flex align-items-center">
                    <h4>Shopdry</h4>
                  </div>
                  <div className="form-group mt-1">
                    <img src={Links.image} className="rounded-circle border border-dark p-2" style={{ width: "100px", height: "100px" }} />

                  </div>

                </div>
                <div className="single-contact-info follow-us">
                  <label className="mb-2 pb-1 text-dark fw-bold fs-6">Share this on:</label>
                  <ul className="social-link ps-0">
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i class="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i class="bx bxl-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/" target="_blank">
                        <i class="bx bxl-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i class="bx bxl-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="single-contact-info follow-us">
                  <label className="mb-2 pb-1 text-dark fw-bold fs-6">Contact Us:</label>
                  <ul className="social-link ps-0">
                    <li className="w-75 d-inline">
                      <i class="flaticon-envelope me-2"></i>
                      <span>{Links.email}</span>
                    </li>
                  </ul>

                  <ul className="social-link ps-0">
                    <li className="w-75 d-inline">
                      <i class="flaticon-phone-call me-2"></i>
                      <span>{Links.phone}</span>
                    </li>
                  </ul>

                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label className="text-dark fw-bold fs-6">Terms and Conditions:</label>
                  </div>
                  <div className="col-lg-12 col-sm-6">
                    <p className="pe-md-5 text-dark">{Links.termcondition}</p>
                  </div>

                  <hr className="mt-3 px-5" />

                  <div className="col-lg-12 col-sm-6">
                    <img src={paymentGetwaysimage} className="rounded" style={{ width: "100px" }} />
                    <p className="pe-md-5 mt-2 text-dark">Want to create page like this for your Business? Visit <a href="">Fintech Payment Pages</a> to get started!</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="main-contact-wrap card shadow mt-5 p-4">
                <h4>Payment Details</h4>
                <div className="row align-items-baseline">
                  <div className="col-lg-4">
                    <label>Amount</label>
                  </div>
                  <div className="col-lg-8 col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        required=""
                        data-error="Please enter your name"
                        value={'₹ ' + Links.amount}
                        readOnly
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Email</label>
                  </div>
                  <div className="col-lg-8 col-sm-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        required=""
                        data-error="Please enter your email"
                        value={Links.payment_email}
                        readOnly
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Phone</label>
                  </div>
                  <div className="col-lg-8 col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        required=""
                        data-error="Please enter your number"
                        className="form-control"

                        value={Links.payment_mobile}
                        readOnly
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Add New</label>
                  </div>
                  <div className="col-lg-8 col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="msg_subject"
                        id="msg_subject"
                        className="form-control"
                        required=""
                        data-error="Please enter your subject"

                        value={Links.payment_subject}
                        readOnly
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <img src={paymentGetwaysimage1} className="bg-light p-3 rounded" style={{ width: "220px" }} />
                  </div>
                  <div className="col-lg-6 col-md-6 text-md-end">
                    <button
                      type="submit"
                      className="default-btn disabled w-auto h-auto mt-5"
                      style={{ pointerEvents: "all", cursor: "pointer" }}
                    >

                      <span>{'Pay ₹ ' + Links.amount}</span>
                    </button>
                    <div id="msgSubmit" className="h3 text-center hidden" />
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
}

export default PaymentsLinks;