import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import OwlCarousel from 'react-owl-carousel';
import slider from '../../image/slider.jpg';
import bannerpayment from '../../assets/images/banner1.jpg';
import image1 from '../../assets/images/Products/QR Code/qr code 1 section.png';
import imagelogo1 from '../../assets/images/payment/bhim.png';
import imagelogo2 from '../../assets/images/payment/upi.png';
import imagelogo3 from '../../assets/images/payment/gpay.png';
import imagelogo4 from '../../assets/images/payment/phonepe.png';
import imagelogo5 from '../../assets/images/payment/paytm.png';
import imagelogo6 from '../../assets/images/payment/rupay.png';
import imagelogo7 from '../../assets/images/payment/visa.png';
import imagelogo8 from '../../assets/images/payment/mastercard.png';

import paymentGetwaysimage1 from '../../assets/images/why1.png';
import paymentGetwaysimage2 from '../../assets/images/why2.png';
import paymentGetwaysimage3 from '../../assets/images/why3.png';
import paymentGetwaysimage4 from '../../assets/images/why4.png';
import paymentGetwaysimage5 from '../../assets/images/how.png';
import testiminal from '../../assets/images/user/user-2.png';

import paymentGetwaysimageprocess1 from '../../assets/images/shape/graphic-shape-3.png';
import paymentGetwaysimageprocess3 from '../../assets/images//Products/QR Code/qr code 2 section.png';

import paymentGetwayfaq from '../../assets/images/faq-img.png';
import paymentGetwaysimageprocess7 from '../../assets/images/shape/graphic-shape-2.png';

import paymentGetwaysimagetaskshape2 from '../../assets/images/shape/task-shape-2.png';
import paymentGetwaysimageUser2 from '../../assets/images/user/user-1.png';
import paymentGetwaysimageteamShape from '../../assets/images/shape/team-shape.png';
import { NavLink } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
function Qrcode() {

  const sliderOptions = {
    loop: true,
    margin: 40,
    autoplay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 3
      },
      600: {
        items: 2
      },
      1000: {
        items: 8
      }
    }
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 },
    { id: 7, image: imagelogo7 },
    { id: 8, image: imagelogo8 }
  ];

  const sliderOptions1 = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  const sliderItems1 = [
    { id: 1, image: testiminal, text_data: 'Sunita', description_data: '"The fact that this QR Code Option supports multiple payment options is a huge plus. I was able to choose the payment method that worked best for me, which made the checkout process even more convenient."' },
    { id: 2, image: paymentGetwaysimageUser2, text_data: 'Akash', description_data: '"The mobile-friendly design of the QR Code on this website is really impressive. I was able to easily make a payment on my phone without any issues, which is not always the case with other websites."' },
    // { id: 3, image: paymentGetwaysimageUser2, text_data: 'Amit Sachdeva', description_data: '"I appreciate the security measures that are in place when using the Fintech Daddy payment button on this website. I feel confident that my payment information is being protected, which is important to me as a customer."' },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Secure QR Code Payments - Scan & Pay with FintechDaddy</title>
        <meta name="description" content="Enjoy hassle-free payments using FintechDaddy. easily scan, pay, & enjoy secure
transactions with our user-friendly QR code solutions."></meta>
      </Helmet>

      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">100X Your business with all new QR Codes Payment Gateway Intergation From FintechDaddy</h2>
                  {/* <p className="text-light">Choose Between Bharatpay QR UPI QR And 9+ Features</p> */}
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/Signup">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image d-flex justify-content-center">
                <img className="qr-img" src={image1} alt="image" />
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <div className="container">
                <div className="partners mt-5">
                  <div className="header text-light">TRUSTED BY MANY BRANDS</div>
                  <div className="partner-img">
                    <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions}>
                      {sliderItems.map(item => (
                        <div className="partner-img" key={item.id}>
                          <img src={item.image} alt="image" className='lazy loaded' />
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* brands */}
      {/* <div className="vbnmr-area pt-100 pb-70 bg-color-5c5c5c"> </div> */}
      {/* brands */}
      <div className="modern-graphic-area-one ptb-100 bg-dark">
        <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-6">
              <div className="graphics-img">
                {/* <img className="paying-img" src={paymentGetwaysimageprocess2} alt="paying-img" data-cues="zoomIn" data-disabled="true" /> */}
                <img className="img-fluid" src={paymentGetwaysimageprocess3} alt="image" />
                {/* shape images */}
                {/* <img className="graphics-img-2 moveVertical" src={paymentGetwaysimageprocess4} alt="image" /> */}
                {/* <img className="graphics-img-3 moveHorizontal" src={paymentGetwaysimageprocess5} alt="image" /> */}
                {/* <img className="graphic-shape-1" src={paymentGetwaysimageprocess6} alt="image" /> */}
                <img className="graphic-shape-2 moveVertical" src={paymentGetwaysimageprocess7} alt="image" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="modern-info">
                <div className="section-heading">
                  {/* <span className="sub-title">Subscriptions On Payment Button</span> */}
                  <h2 className="text-light">How To  Generate Your  QR CODE</h2>
                </div>
                <ul>
                  <li>
                    <i className="flaticon-check" />Login To You FintechDaddy Dashboard
                  </li>
                  <li>
                    <i className="flaticon-check" />Fill Your Business Detail and Personal Detail
                  </li>
                  <li>
                    <i className="flaticon-check" />Generate Your QR Code and Get Paid Instansly
                  </li>
                </ul>
                <a className="common-btn two" href="/contact-us">Learn More</a>
                <img className="graphic-shape-3 rotate" src={paymentGetwaysimageprocess1} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------- */}

      <div className="client-say-area client-say-testimonials-area pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="section-title">
            {/* <span class="subtitle">Our Testimonials</span> */}
            <h2 className="text-light">Who can use UPI QR Code?</h2>
          </div>
          <div className="row range_benefits qr">
            <div className="col-lg-4" src="">
              <div className="say-card">
                <h4 className="text-light">Merchants</h4>
                <p className="text-light">Merchants can use a QR code gateway to accept payments from customers who prefer to use their mobile devices for transactions.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="">
              <div className="say-card">
                <h4 className="text-light">Customers</h4>
                <p className="text-light">Customers who prefer to make payments using their mobile devices may find QR code gateways to be a convenient option.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="">
              <div className="say-card">
                <h4 className="text-light">Mobile App Developers</h4>
                <p className="text-light">Developers who are building mobile apps that require payment functionality may find QR code gateways to be a useful tool.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="">
                <h4 className="text-light">Payment Gateway Providers</h4>
                <p className="text-light">Payment gateway providers can offer QR code gateways as a value-added service to their customers. By providing this option.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="">
                <h4 className="text-light">Event Organizers</h4>
                <p className="text-light">QR code gateways can be particularly useful for event organizers who need to process payments quickly and efficiently. By displaying QR codes at ticket booths or other location.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="" >
                <h4 className="text-light">Freelancers</h4>
                <p className="text-light">Freelancers and service providers such as consultants, designers, and tutors can use QR code payment gateway to accept payments from clients.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="qr_benefit services-area one pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            {/* <span className="sub-title">Become a partner now</span> */}
            <h2 className="text-light">Benefit of QR Code Gateway Integration</h2>
            {/* <p>Fintech Daddy provides an advanced, flexible, full feature set along with its payment services that are known as one of the most easily integrated solutions available</p> */}
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card one">
                <i className="flaticon-software" />
                <div className="info">
                  <h4>
                    <a href="">Convenience</a>
                  </h4>

                </div>
                {/* <img className="service-shape-3 bounce" src={paymentGetwaysimagetask1} alt="image" /> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card two">
                <i className="flaticon-customer-service" />
                <div className="info">
                  <h4>
                    <a href="">Contactless Payments</a>
                  </h4>

                </div>
                {/* <img className="service-shape-2 bounce" src={paymentGetwaysimagetask2} alt="image" /> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card three">
                <i className="flaticon-layer" />
                <div className="info">
                  <h4>
                    <a href="">Easy Setup and Accessibility</a>
                  </h4>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card four">
                <i className="flaticon-united" />
                <div className="info">
                  <h4>
                    <a href="">Lower Infrastructure Costs</a>
                  </h4>

                </div>
                {/* <img className="service-shape-5 bounce" src={paymentGetwaysimagetask3} alt="image" /> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card five">
                <i className="flaticon-growth" />
                <div className="info">
                  <h4>
                    <a href="">Enhanced Security</a>
                  </h4>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card three six">
                <i className="flaticon-layer" />
                <div className="info">
                  <h4>
                    <a href="">Cost Efficiency</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* shape images */}
        {/* <img className="service-shape-1 rotate" src={paymentGetwaysimagetaskshape1} alt="image" /> */}
        <img className="service-shape-4 rotate" src={paymentGetwaysimagetaskshape2} alt="image" />
      </div>

      {/* 
      <div className="workflow-integration-area ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="task-img">
                <img className="task-img-1" src={paymentGetwaysimagetask1} alt="image" />
                <img className="task-shape-1" src={paymentGetwaysimagetaskshape1} alt="image" />
                <img className="task-img-3" src={paymentGetwaysimagetask3} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="history">
                <div className="section-heading">
                  <h2 className="text-light">Happy Customer Review</h2>
                </div>
                <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions1}>
                  {sliderItems1.map(item => (

                    <div className="history-card d-flex align-items-center position-relative bg-color-5c5c5c">
                      <img className="flex-shrink-0 me-3" src={item.image} alt="image" />
                      <div className="warp">
                        <h4 className="text-light">{item.text_data}</h4>
                        <p className="text-light">{item.description_data}</p>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
        <img className="task-shape-2" src={paymentGetwaysimagetaskshape2} alt="image" />
        <img className="team-shape" src={paymentGetwaysimageteamShape} alt="image" />
      </div> */}
      {/* End Boost Your Business */}
      <section className="bg-color-100f0f pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">Join Our Partner Program</h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>  Know More <i className="flaticon-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>


      {/* FAQ */}
      <div className="faq-area ptb-100 bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-image-">
                <img src={paymentGetwayfaq} alt="faq" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is a QR code gateway?</Accordion.Header>
                      <Accordion.Body>
                        A QR code gateway is a type of payment gateway that uses QR codes to facilitate transactions. Customers can use their mobile devices to scan the QR code and complete a transaction.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How do I use FintechDaddy QR code gateway?</Accordion.Header>
                      <Accordion.Body>
                        To use FintechDaddy QR code gateway, you need a mobile device with a QR code scanner app. Once you have the app, you simply scan the QR code provided FintechDaddy, and follow the instructions to complete the transaction.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Is using FintechDaddy QR code gateway secure?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy QR code gateways typically use encryption and other security measures to protect customer information and prevent fraud.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>What types of transactions can be processed using FintechDaddy QR code gateway?</Accordion.Header>
                      <Accordion.Body>
                        FintechDaddy QR code gateway can be used to process a variety of transactions, including retail purchases, bill payments, and donations.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>What if I have trouble scanning the QR code?</Accordion.Header>
                      <Accordion.Body>
                        If you have trouble scanning the QR code, try adjusting the lighting or angle of your device. If the problem persists, you may need to contact the FintechDaddy Support for assistance.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Qrcode;