import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import { NavLink } from "react-router-dom";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ChatUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FintechDaddy | Chat with Us - Instant Support</title>
        <meta name="description" content="Engage in real-time conversations with FintechDaddy's team to receive instant
support, solutions & personalized assistance for your needs - start chatting with us now."></meta>
      </Helmet>

      {/* Start Page Banner Area */}
      <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="page-banner-heading text-center">
                <h1 style={{ color: 'white' }}>Chat with us</h1>
                <ul>
                  <li>
                    <NavLink className="nav-link active" to="/">Home</NavLink>
                  </li>
                  <li>Chat with us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Banner Area */}
      <div className="experience-area pt-100 pb-4 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div>
                <div className="section-heading">
                  <h2 className="text-light fs-1">Real-Time Assistance at Your Fingertips</h2>
                  <p className="text-light"><b>Introduction: </b>
                    We understand that when it comes to FintechDaddy, you may have questions or need immediate assistance. That's why we offer a convenient chat feature that allows you to connect with our knowledgeable support team instantly. With our live chat support, you can get the help you need in real-time, ensuring a smooth and seamless payment experience.
                  </p>
                  <p className="text-light"><b>Section 1: Instant Answers </b>
                    Our live chat support enables you to ask questions and receive instant answers from our dedicated team of experts.
                    Whether you need clarification on FintechDaddy features, integration steps, or troubleshooting assistance, we're here to help.
                    Say goodbye to long wait times and delays—our chat support ensures quick responses to keep your business running smoothly.

                  </p>
                  <p className="text-light"><b>Section 2: Expert Guidance </b>
                    Our support team consists of experienced professionals with in-depth knowledge of our payment gateway solutions.
                    We're ready to provide expert guidance and address any concerns or challenges you may encounter.
                  </p>
                  <p className="text-light"><b>Section 3: Seamless Troubleshooting </b>
                    If you encounter any issues or need assistance with FintechDaddy, our chat support is your direct line to prompt resolution.
                    Our team will work closely with you to identify and troubleshoot the problem, ensuring minimal downtime and maximum efficiency.
                    We're committed to helping you overcome any obstacles and maintain a seamless payment processing experience.
                  </p>
                  <p className="text-light"><b>Call-to-Action: </b>
                  </p>
                  <p className="text-light"><b>Chat With Us: </b>
                    Ready to chat with our support team and get the answers you need? Click the chat icon at the bottom-right corner of your screen to initiate a conversation with us. We're available [24 Hours] to assist you
                  </p>
                  <p className="text-light"><b>Closing Statement: </b>
                    At FintechDaddy, we value your time and strive to provide exceptional customer support. Our chat feature allows us to address questions and concerns in real-time. Experience the convenience of instant assistance and chat with us today. We look forward to serving you!
                  </p>
                  <div className="button-group gap-4 text-center">
                    <Button className="banner-btn" onClick={handleShow}>Chat with us</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Modal  */}
      <Modal show={show} onHide={handleClose} className='contact'>
        <Modal.Header closeButton className='bg-color-0b0808 border'>
          <Modal.Title className='text-light ps-3'>Contact Us</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <div className="profile-authentication-area">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="register-form modal-contact bg-color-0b0808">
                <div className='form row'>
                  <div className="form-group col-md-12">
                    <label className="text-light">Name</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-light">Email</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-light">Phone</label>
                    <input type="number" className="form-control" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="text-light">Message</label>
                    <textarea type="text" className="form-control h-auto" />
                  </div>
                  <button type="submit" className="default-btn" onClick={handleClose}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal close */}

      <div className="best-service-area pb-70 bg-color-100f0f">
        <div className="container">
          <div className="">
            <h4 className="text-light mb-3">Contact Us Here:</h4>
            <p className='text-light fs-6'>FINTECHDADDY SOLUTIONS PRIVATE LIMITED</p>
            <p className='text-light fs-6'>1014/1, Shriram park, Khora Colony, Ghaziabad,
Ghaziabad, Uttar Pradesh, 201003</p>
            <p className='text-light fs-6'>Email Id:- <NavLink className='text-light' to="mailto:Gauravsingh@fintechdaddy.com">Gauravsingh@fintechdaddy.com</NavLink><br /></p>
            <p className='text-light fs-6'>Phone No. <NavLink className='text-light' to="tel:+91-8929253949">+91-8929253949</NavLink></p>
          </div>

        </div>
      </div>

    </div>
  );
}

export default ChatUs;