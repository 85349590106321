import React, { useEffect } from 'react';
import { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import bannerpayment from '../../assets/images/banner1.jpg';
import { NavLink } from "react-router-dom";
function Career() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [show, setShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    file: ''
  });

  const handleClose = () => {
    setShow(false);
    setIsSubmitted(false);
    setFormData({
      name: '',
      phone: '',
      file: ''
    });
  };

  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {
    // You can perform any validation you want before submitting the form
    if (formData.name && formData.file) {
      setIsSubmitted(true);
    }
  };

  return (
    <div>
      {/* Start Page Banner Area */}
      <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="page-banner-heading text-center">
                <h1 style={{ color: 'white' }}>Career</h1>
                <ul>
                  <li>
                    <NavLink className="nav-link active" to="/">Home</NavLink>
                  </li>
                  <li>Career</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Banner Area */}
      <div className="experience-area pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div>
                <div className="section-heading">
                  <h2 className="text-light text-center">Join the FintechDaddy Team: Empowering the Future of Payments</h2>
                  <p>Welcome to the FintechDaddy Career Page, where we invite talented individuals to be part of our passionate team, driving innovation in the world of payment gateway solutions. As a leader in the fintech industry, we believe that our success lies in the dedication and expertise of our employees.</p>
                  
                  <h2 className="text-light fs-2 mt-5">Why Choose FintechDaddy?</h2>
                  <h3 className='text-light'>Innovative Environment:</h3>
                  <p>Be part of a company at the forefront of the payments industry, where innovation and cutting-edge technology are at the core of our mission.</p>

                  <h3 className='text-light'>Impactful Work:</h3>
                  <p>Contribute to shaping the future of payments, enabling seamless transactions for businesses and individuals worldwide.</p>
                </div>

                <div className='mustknow section-heading'>
                  <h3 className='text-light'>Professional Growth:</h3>
                  <p>We are committed to the growth and development of our employees. We provide learning opportunities, skill enhancement programs, and career advancement paths.</p>
                </div>

                <div className='mustknow section-heading'>
                  <h3 className='text-light'>Collaborative Culture:</h3>
                  <p>Join a supportive and collaborative work environment, where teamwork and open communication are valued.</p>
                </div>

                <div className='mustknow section-heading'>
                  <h2 className='text-light fs-2 mt-5'>What We Look For:</h2>
                  <h3 className='text-light'>Passionate Professionals:</h3>
                  <p>We seek individuals who are passionate about the fintech industry and committed to making a difference in the world of payments.</p>
                </div>

                <div className='mustknow section-heading'>
                  <h3 className='text-light'>Innovators:</h3>
                  <p>We value creative minds that bring fresh perspectives and innovative ideas to drive our company's success.</p>
                  
                  <h3 className='text-light'>Customer-Centric: </h3>
                  <p>Our customers are at the heart of what we do. We look for individuals who are dedicated to delivering exceptional customer experiences.</p>
                </div>

                <div className='mustknow section-heading'>
                  <h3 className='text-light'>Team Players:</h3>
                  <p>Collaboration is essential to our success. We appreciate team players who work together to achieve common goals.</p>
                  
                  <h3 className='text-light'>How to Apply:</h3>
                  <p>If you are excited about being part of a dynamic team that is revolutionizing the payments landscape, we invite you to apply for one of our current openings. Please submit your updated resume and a cover letter detailing your qualifications and why you believe you'd be a great fit for FintechDaddy.</p>

                  <h3 className='text-light'>Career Development:</h3>
                  <p>At FintechDaddy, we believe in nurturing talent and providing opportunities for growth. We offer comprehensive training programs, mentorship, and career development resources to help you excel in your role and advance in your career.</p>

                  <h3 className='text-light'>Join Us in Shaping the Future of Payments:</h3>
                  <p>Are you ready to take the next step in your career and be part of a passionate team driving innovation in the fintech industry? Apply today and join us on this exciting journey towards creating a seamless and secure payment experience for businesses and consumers worldwide.</p>
                  
                  <h3 className='text-light'>Disclaimer:</h3>
                  <p>The specific job openings and opportunities may vary over time. For the latest information on current job openings and application instructions, please visit our website's Career Page or contact our HR department.
                  </p>
                </div>

                <div className="register-form modal-contact bg-color-0b0808">
                <div className='form row align-items-end'>
                  <div className="form-group col-md-3">
                    <label className="text-light">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      // value={""}
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label className="text-light">Contact No.</label>
                    <input
                      type="number"
                      className="form-control"
                      name="phone"
                      // value={""}
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label className="text-light">Upload Resume</label>
                    <input
                      type="file"
                      className="form-control p-3"
                      name='file'
                      value={formData.file}
                      onChange={handleChange}
                    />
                  </div>

                    {isSubmitted ? (
                    <div className="form-group col-md-3">
                      <p className="text-success text-center">Thank you for your message! We will get back to you soon.</p>
                    </div>
                  ) : (
                    <div className="form-group col-md-3">
                      <button type="submit" className="default-btn" onClick={handleSubmit}>Submit</button>
                    </div>
                  )}

                </div>
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Career;