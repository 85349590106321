// import React from "react";
import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import image1 from '../../assets/images/pci.png';
import image2 from '../../assets/images/iso.webp';
import image3 from '../../assets/images/shape/footer-shape.png';
import airoplan from '../../assets/images/airplane.svg';

function Footer() {
  const token = localStorage.getItem("token");
  const [bgColour, setBgColour] = useState("#fafafa")
  let mybutton;
  window.onscroll = function () {
    mybutton = document.getElementById("btn-back-to-top");
    scrollFunction(mybutton);
  };

  function scrollFunction(mybutton) {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
  function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <div>
      <div className="footer-area-front footer-area-2 pt-100 bg-color-0b0808">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-3">
              <div className="address">
                <h4>Company</h4>
                <ul>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink to="/About-Us">About Us</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Career">Career</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink to="/Privacy-Policy">Privacy Policy</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink to="/Terms-condition">Terms &amp; Condition</NavLink>
                  </li>
                </ul>
              </div>
              <div className="address mt-5">
                <h4>Become A Partner</h4>
                <ul>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Affiliate">Affiliate</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Reseller">Reseller</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/White-label">White label</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-3">
              <div className="address">
                <h4>Products</h4>
                <ul>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Payment-Gateway">Payment Gateway</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Payout">Payouts</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Payment-link">Payment Link</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Payment-Page">Payment Page</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Payment-Button">Payment Button</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Invoice">Invoice</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Pay-now">Pay Now</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-3">
              <div className="address">
                <h4>Developer</h4>
                <ul>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink to="/Integration">Docs</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink to="/Integration">Integration</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink to="/Integration">API Reference</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Documents">Documents Required</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Faqs">FAQ</NavLink>
                  </li>
                </ul>
              </div>
              <div className="address mt-5">
                <h4>Verify Identity</h4>
                <ul>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <a href="#">PAN Verification</a>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <a href="#">Aadhar Verification</a>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <a href="#">GST Verification</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-3">
              <div className="address">
                <h4>Supports</h4>
                <ul>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Contact-us">Merchant Support</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Contact-us">Customer Support</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Contact-us">Partner Support</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Talk-to-us">Talk to us</NavLink>
                  </li>
                </ul>
              </div>
              <div className="address mt-5">
                <h4>Help &amp; Support</h4>
                <ul>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Talk-to-us">Talk to us</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Chat-with-us">Chat with us</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Raise-Request">Raise a request</NavLink>
                  </li>
                  <li>
                    <i className="bx bx-right-arrow-alt" />
                    <NavLink className="nav-link" to="/Contact-us">Contact Us</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="row py-3  mt-4">
            <div className="col-lg-4 col-md-4">
              <div className="widget">
                {/* <h4 class="text-light">Find us online</h4> */}
                <ul className="social_icons">
                  <li>
                    <a className="social" href="https://www.facebook.com/" target="_blank">
                      <i className="bx bxl-facebook" />
                    </a>
                  </li>
                  <li>
                    <a className="social" href="https://www.twitter.com/" target="_blank">
                      <i className="bx bxl-twitter" />
                    </a>
                  </li>
                  <li>
                    <a className="social" href="https://www.linkedin.com/" target="_blank">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="social" href="https://www.instagram.com/" target="_blank">
                      <i className="bx bxl-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="address">
                {/* <h4>Trust Icons</h4> */}
                <div className="trust-icon">
                  <a className=" mx-2" href target="_blank">
                    <img src={image1} width="60px" />
                  </a>
                  <a className=" mx-2" href target="_blank">
                    <img src={image2} width="60px" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="address">
                {/* <h4>Subscribe Button or awards</h4> */}
                <div className="newsletter-area bg-color-0b0808">
                  <div className="container">
                    <form className="newsletter-form" noValidate="true">
                      <div className="form-group newsletter-form has-error" noValidate="true">
                        <input type="email" name="email" className="form-control" placeholder="Enter Your Email" required autoComplete="off" />
                        <button type="submit" className="newsletter-btn disabled" style={{ pointerEvents: 'all', cursor: 'pointer' }}>Subscribe</button>
                        <div id="validator-newsletter" className="form-result" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button onClick={backToTop}
          id='btn-back-to-top'
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            display: "none",
            color: "#f5f2f2",
            background: "#fd7529",
            padding: "10px",
            width: "54px",
            background: 'linear-gradient(45deg, rgb(228 52 38), rgb(243 141 42) 100%)',
            border: "hidden",
          }}

          className='btn-floating'
          color='danger'
          size='lg'><svg xmlns={airoplan} style={{ color: "white", width: "28px" }} fill="currentColor" class="bi bi-airplane" viewBox="0 0 16 16">
            <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Zm.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1c-.213 0-.458.158-.678.599Z" />
          </svg></button>

        <img className="footer-shape" src={image3} alt="image" />
      </div>

      <div className="copy-area copy-area-2 bg-color-0b0808">
        <div className="container">
          <div className="copy">
            <p>©2023 <span>FintechDaddy</span> All Rights Reserved
            </p>
          </div>
        </div>
      </div>
      <div className="go-top">
        <i className="bx bxs-chevrons-up" />
      </div>
    </div>
  );


}

export default Footer;