import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import image1 from '../../assets/images/Products/Payment Gateway/Payment Gateway top.png';
import imagelogo1 from '../../assets/images/Products/Payment Gateway/Bank Logo/axis bank.png';
import imagelogo2 from '../../assets/images/Products/Payment Gateway/Bank Logo/hdfc bank.png';
import imagelogo3 from '../../assets/images/Products/Payment Gateway/Bank Logo/icici bank.png';
import imagelogo4 from '../../assets/images/Products/Payment Gateway/Bank Logo/idbi bank.png';
import imagelogo5 from '../../assets/images/Products/Payment Gateway/Bank Logo/indus bank.png';
import imagelogo6 from '../../assets/images/Products/Payment Gateway/Bank Logo/yes bank.png';
import bestService1 from '../../assets/images/svg/awesome-1.svg';
import bestService2 from '../../assets/images/svg/awesome-2.svg';
import bestService3 from '../../assets/images/svg/awesome-3.svg';

import boostYourBussiness1 from '../../assets/images/Products/Payment Gateway/Payment Gateway down.png';
import boostYourBussiness2 from '../../assets/images/svg/business-1.svg';
import boostYourBussiness3 from '../../assets/images/svg/business-2.svg';

import paymentGetwaysimage1 from '../../assets/images/why1.png';
import paymentGetwaysimage2 from '../../assets/images/why2.png';
import paymentGetwaysimage3 from '../../assets/images/why3.png';
import paymentGetwaysimage4 from '../../assets/images/why4.png';
import paymentGetwaysimage5 from '../../assets/images/how.png';

import paymentGetwaysimageprocess11 from '../../assets/images/svg/process-1.svg';
import paymentGetwaysimageprocess1 from '../../assets/images/Products/Payment Gateway/Payments Gateway Image/Payment gateway Imge 1.png';
import paymentGetwaysimageprocess2 from '../../assets/images/Products/Payment Gateway/Payments Gateway Image/Payment gateway Imge 2.png';
import paymentGetwaysimageprocess3 from '../../assets/images/Products/Payment Gateway/Payments Gateway Image/Payment gateway Imge 3.png';
import paymentGetwaysimageprocess4 from '../../assets/images/Products/Payment Gateway/Payments Gateway Image/Payment gateway Imge 4.png';

import paymentGetwaysimageprocessbar1 from '../../assets/images/shape/process-shape-1.png';
import paymentGetwaysimageprocessbar2 from '../../assets/images/shape/process-shape-2.png';
import { NavLink } from "react-router-dom";
function PaymentGetway() {

  const sliderOptions = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 5
      }
    }
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Payment Gateway For Domestic Transaction | FintechDaddy</title>
        <meta name="description" content="experience the future of online payments with our cutting-edge payment gateway
solutions and elevate your business's online payments, boost customer trust, and
streamline checkout processes."></meta>
      </Helmet>

      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">Grow your Business with FintechDaddy</h2>
                  <p className="text-light">India's fastest Smart-Routing Payment Gateway. Which Utilizes Technology to Quickly Route Payment Transactions Between Different Payment Processors and Banks to Ensure Seamless and Reliable Payment Processing.</p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/Signup">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image">
                <img className="experience-img" src={image1} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* brands */}

      <div className="vbnmr-area pt-4 bg-color-5c5c5c">
        <div className="container">
          <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions}>
            {sliderItems.map(item => (
              <div className="sponsor-logo" key={item.id}>
                <img src={item.image} alt="image" />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>

      {/* Start Our Best Services Area */}
      <div className="best-service-area pt-100 pb-70 bg-dark pg">
        <div className="container">
          <div className="section-title">
            <h2 className="text-light"> Powerful Dashboard To Give Full Control</h2>
            <p>FintechDaddy  is a platform that allows businesses to process online payments securely and efficiently.</p>
          </div>
          <div className="row Four-sec">
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card  text-center">
                <div className="awesome-image">
                  <img src={bestService1} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Enhanced Security</a>
                </h4>
                <p className="text-light">We provide secure encryption protocols
                  and advanced security features to protect sensitive customer data during transactions.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card two text-center">
                <div className="awesome-image">
                  <img src={bestService2} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Mobile Compatibility</a>
                </h4>
                <p className="text-light">FintechDaddy can be designed to be mobile-friendly, allowing customers to make payments from their smartphones or tablets.</p>

              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <img src={bestService3} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Fraud Detection & Prevention</a>
                </h4>
                <p className="text-light">Fintech System  include advanced fraud detection and prevention features, such as real-time monitoring, risk analysis, and fraud scoring, to help prevent fraudulent transactions.</p>

              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <img src={bestService3} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Recurring Payments</a>
                </h4>
                <p className="text-light">We offer the ability to set up and manage recurring payments, allowing businesses to charge customers on a regular basis for subscription services or recurring bills.</p>

              </div>
            </div>

          </div>
        </div>
        {/* End Our Best Services Area */}
      </div>
      {/* Start Boost Your Business */}
      <div className="experience-area pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="section-heading">
              <h2 className="text-light text-center">Why Growing Businesses Trust Us?</h2>
              {/* <p className="text-light">Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain, but because occasionally circumstances occur</p> */}
            </div>
            <div className="col-lg-5" >
              <div className="experience-image">
                <img className="experience-img" src={boostYourBussiness1} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="business-experience">
                <div className="row grow">
                  <div className="col-lg-12 col-md-12 col-xl-12">
                    <div className="experience-card bg-transparent border border-1 py-2">
                      <div className="heading align-items-center mb-4">
                        {/* <div className="business-img">
                          <img src={boostYourBussiness2} alt="image" />
                        </div> */}
                        <h4 className="ps-0 text-light fs-4">Built For Very High Scale</h4>
                      </div>
                      <p>FintechDaddy Payment Gateway offers reliability and scales with you. Process 4000 transactions per second smoothly without any downtimes</p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-xl-12">
                    <div className="experience-card bg-transparent border border-1 py-2">
                      <div className="heading align-items-center mb-4">
                        {/* <div className="business-img">
                          <img src={boostYourBussiness3} alt="image" />
                        </div> */}
                        <h4 className="ps-0 text-light fs-4">Security</h4>
                      </div>
                      <p>Our payment gateway should provide robust security measures, such as encryption, tokenization, and secure SSL connections.</p>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-xl-12">
                    <div className="experience-card bg-transparent border border-1 py-2">
                      <div className="heading align-items-center mb-4">
                        {/* <div className="business-img">
                          <img src={boostYourBussiness3} alt="image" />
                        </div> */}
                        <h4 className="ps-0 text-light fs-4">Instant Settlement</h4>
                      </div>
                      <p>FintechDaddy Provide Instant Settlement Direct To Your Bank. Clients Has Payout Day By Day Settlement which Help People To Run Their Businesses Smoothly</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Boost Your Business */}
      <section className="bg-dark pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">Join Our Partner Program</h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>Know More <i className="flaticon-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>
      {/* Start Work Process Area */}
      <div className="process-area pg pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="section-title">
            <h2 className="text-light">Do Your Business The Easy Way</h2>
          </div>
          <div className="row justify-content-center mt-md-5 easy_way">
            <div className="col-lg-3 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img className="process-1" src={paymentGetwaysimageprocess1} alt="image" />
                </div>
                <h4 className="text-light">Integrate With a Few Simple Steps</h4>
                <p>Integrate Payment Gateway in Most Popular E-commerce platforms such as Shopify, WooCommerce, Magento, and BigCommerce And Many More.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img className="process-1" src={paymentGetwaysimageprocess2} alt="image" />
                </div>
                <h4 className="text-light">Instant Beneficiary Additions </h4>
                <p>No cool-off period between adding beneficiary and making payments</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img className="process-1" src={paymentGetwaysimageprocess3} alt="image" />
                </div>
                <h4 className="text-light">Offer Multiple Payment Method </h4>
                <p>FintechDaddy Support Various Payment Methods Such as Credit Cards, Debit Cards, E-wallets, and Bank transfers.</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img className="process-1" src={paymentGetwaysimageprocess4} alt="image" />
                </div>
                <h4 className="text-light">Faster Processing </h4>
                <p>Transactions Process in Real-Time, Which Means That Businesses Can Receive Payment Quickly & Efficiently.</p>
              </div>
            </div>

          </div>
        </div>
        {/* shape-images */}
        <div>
          {/* <img className="process-shape-1" src={paymentGetwaysimageprocessbar1} alt="process-shape" /> */}
        </div>
        <div>
          {/* <img className="process-shape-2" src={paymentGetwaysimageprocessbar2} alt="process-shape" /> */}
        </div>
      </div>
      {/* End Work Process Area */}
    </div>
  );
}

export default PaymentGetway;