import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import image1 from '../../assets/images/Products/pay Now/pay now.png';
import imagelogo1 from '../../assets/images/payment/bhim.png';
import imagelogo2 from '../../assets/images/payment/upi.png';
import imagelogo3 from '../../assets/images/payment/gpay.png';
import imagelogo4 from '../../assets/images/payment/phonepe.png';
import imagelogo5 from '../../assets/images/payment/paytm.png';
import imagelogo6 from '../../assets/images/payment/rupay.png';
import imagelogo7 from '../../assets/images/payment/visa.png';
import imagelogo8 from '../../assets/images/payment/mastercard.png';
import paymentGetwayfaq from '../../assets/images/faq-img.png';

import paymentGetwaysimageUser2 from '../../assets/images/user/user-1.png';
import paymentGetwaysimage1 from '../../assets/images/why1.png';
import paymentGetwaysimage2 from '../../assets/images/why2.png';
import paymentGetwaysimage3 from '../../assets/images/why3.png';
import paymentGetwaysimage4 from '../../assets/images/why4.png';
import paymentGetwaysimage5 from '../../assets/images/how.png';

import paymentGetwaysimageprocess1 from '../../assets/images/shape/graphic-shape-3.png';
import paymentGetwaysimageprocess2 from '../../assets/images/paying-img.png';
import paymentGetwaysimageprocess3 from '../../assets/images/Products/pay Now/How Pay Now Help.png';


import paymentGetwaysimagetask1 from '../../assets/images/shape/service-shape-3.png';
import paymentGetwaysimagetask2 from '../../assets/images/shape/service-shape-2.png';
import paymentGetwaysimagetask3 from '../../assets/images/shape/service-shape-5.png';
import paymentGetwaysimagetaskshape1 from '../../assets/images/shape/service-shape-1.png';
import paymentGetwaysimagetaskshape2 from '../../assets/images/shape/task-shape-2.png';
import { NavLink } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
function Paynow() {

  const sliderOptions = {
    loop: true,
    margin: 40,
    autoplay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 3
      },
      600: {
        items: 2
      },
      1000: {
        items: 8
      }
    }
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 },
    { id: 7, image: imagelogo7 },
    { id: 8, image: imagelogo8 }
  ];

  const sliderOptions1 = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  const sliderItems1 = [
    { id: 1, image: paymentGetwaysimageUser2, text_data: 'Rakesh', description_data: "I recently used the Pay Now option when purchasing concert tickets online and was impressed by how easy and convenient the process was. I didn't have to create an account or log in, and the payment was processed securely and quickly. The 'Pay Now' option saved me time and made the entire transaction seamless. I will definitely be using this feature for future purchases and would highly recommend it to others! Thanks Fintech Daddy." },
    { id: 2, image: paymentGetwaysimageUser2, text_data: 'Anuj Srivastav', description_data: "I used the 'Pay Now' option to pay for an online purchase and was amazed at how fast and secure the process was. I didn't have to wait for the payment to clear or worry about my personal information being compromised. The 'Pay Now' feature made the transaction much simpler and I appreciated not having to go through extra steps to complete my purchase. I highly recommend using this feature for anyone looking for a quick and hassle-free payment experience." },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Introducing FintechDaddy Pay Now | Quick & Easy Payments</title>
        <meta name="description" content="Instantly complete transactions with FintechDaddy, Seamlessly process payments,
ensure order fulfillment and provide a frictionless checkout experience."></meta>
      </Helmet>
      
      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="">
                <div className="section-heading">
                  <h2 className="text-light">Introducing FintechDaddy Pay Now </h2>
                  <p className="text-light">The "Pay Now" option in FintechDaddy allows customers to make a payment immediately, without needing to create an account or log in.</p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/contact-us">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image d-flex justify-content-center">
                <img className="experience-img" src={image1} alt="image" />
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <div className="container">
                <div className="partners mt-5">
                  <div className="header text-light">Supports all UPI Apps</div>
                  <div className="partner-img">
                    {/* <img className="lazy loaded" alt="bhim" src={imagelogo1} />
                    <img className="lazy loaded" alt="upi" src={imagelogo2} />
                    <img className="lazy loaded" alt="gpay" src={imagelogo3} />
                    <img className="lazy loaded" alt="phonepe" src={imagelogo4} data-was-processed="true" />
                    <img className="lazy loaded" alt="paytm" src={imagelogo5} data-was-processed="true" />
                    <img className="lazy loaded" alt="rupay" src={imagelogo6} data-was-processed="true" />
                    <img className="lazy loaded" alt="visa" src={imagelogo7} />
                    <img className="lazy loaded" alt="mastercard" src={imagelogo8} data-was-processed="true" /> */}
                    <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions}>
                      {sliderItems.map(item => (
                        <div className="partner-img" key={item.id}>
                          <img src={item.image} alt="image" className='lazy loaded' />
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services-area one pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">On-Demand Settlements</span>
            <h2 className="text-light">What Is The Uses of Pay Now Option</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card shadow1 one">
                <i className="flaticon-software" />
                <div className="info">
                  <h4>
                    <a>Online Purchases</a>
                  </h4>
                  <p>Customers can use the "Pay Now" option to quickly and easily pay for goods or services they have purchased online, without needing to create an account or log in.</p>
                </div>
                <img className="service-shape-3 bounce" src={paymentGetwaysimagetask1} alt="image" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card shadow1 two">
                <i className="flaticon-customer-service" />
                <div className="info">
                  <h4>
                    <a>Donations</a>
                  </h4>
                  <p>Non-profit organizations and charities can use the "Pay Now" option to allow supporters to make a quick and secure donation without needing to go through a lengthy registration process.</p>
                </div>
                <img className="service-shape-2 bounce" src={paymentGetwaysimagetask2} alt="image" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card shadow1 three">
                <i className="flaticon-layer" />
                <div className="info">
                  <h4>
                    <a>Invoicing</a>
                  </h4>
                  <p>Businesses can include a "Pay Now" button on their invoices, allowing customers to make a payment immediately and securely without needing to mail a check or log into a payment portal.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card shadow1 four">
                <i className="flaticon-united" />
                <div className="info">
                  <h4>
                    <a>Event Registration</a>
                  </h4>
                  <p>Organizations that host events such as conferences or workshops can use the "Pay Now" option to allow attendees to pay for registration fees quickly and securely.</p>
                </div>
                <img className="service-shape-5 bounce" src={paymentGetwaysimagetask3} alt="image" />
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card five">
                <i className="flaticon-growth" />
                <div className="info">
                  <h4>
                    <a>Project Analysis</a>
                  </h4>
                  <p>Nusid quod maxie placeat fcere possimus, nis assumnda est, omnis dolor</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* shape images */}
        <img className="service-shape-1 rotate" src={paymentGetwaysimagetaskshape1} alt="image" />
        <img className="service-shape-4 rotate" src={paymentGetwaysimagetaskshape2} alt="image" />
      </div>

      <div className="modern-graphic-area-one ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="modern-info">
                <div className="section-heading">
                  {/* <span className="sub-title">Subscriptions On Payment Button</span> */}
                  <h2 className="text-light">How Pay Now Help Business To Grow?</h2>
                  {/* <p className="text-light">Indignation and dislike men who are so beguiled andy the charms of pleasure the moment, so blinded by desir that they cannot foresee the pain and trouble</p> */}
                </div>
                <ul>
                  <li>
                    <i className="flaticon-check" />Simple & Fastest Payment Solution
                  </li>
                  <li>
                    <i className="flaticon-check" />Easily Accept Paymet Without Coding
                  </li>
                  <li>
                    <i className="flaticon-check" />Increased Sales
                  </li>
                </ul>
                <a className="common-btn two" href="/contact-us">Learn More</a>
                <img className="graphic-shape-3 rotate" src={paymentGetwaysimageprocess1} alt="image" />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="graphics-img">
                <img className="paying-img" src={paymentGetwaysimageprocess2} alt="paying-img" data-cues="zoomIn" data-disabled="true" />
                <img className="graphics-img-1" src={paymentGetwaysimageprocess3} alt="image" />
                {/* shape images */}
                {/* <img className="graphics-img-2 moveVertical" src={paymentGetwaysimageprocess4} alt="image" /> */}
                {/* <img className="graphics-img-3 moveHorizontal" src={paymentGetwaysimageprocess5} alt="image" /> */}
                {/* <img className="graphic-shape-1" src={paymentGetwaysimageprocess6} alt="image" /> */}
                {/* <img className="graphic-shape-2 moveVertical" src={paymentGetwaysimageprocess7} alt="image" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------- */}

      <div className="client-say-area client-say-testimonials-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            {/* <span class="subtitle">Our Testimonials</span> */}
            <h2 className="text-light">Benefit of Pay Now</h2>
          </div>
          <div className="row range_benefits justify-content-center">
            <div className="col-lg-4" src="">
              <div className="say-card">
                <h4 className="text-light">Convenience</h4>
                <p className="text-light">The "Pay Now" option allows customers to make a payment quickly and easily, without needing to create an account or log in</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="">
              <div className="say-card">
                <h4 className="text-light">Security</h4>
                <p className="text-light">Payment gateways typically offer secure payment processing, using encryption and other measures to protect sensitive payment information.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="">
              <div className="say-card">
                <h4 className="text-light">Faster Payment Processing</h4>
                <p className="text-light">With the "Pay Now" option, businesses can receive payment immediately, without needing to wait for checks to clear or for customers to log into a payment portal.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="">
                <h4 className="text-light">Increased Sales</h4>
                <p className="text-light">The convenience and ease of use of the "Pay Now" option can encourage customers to complete their purchase, leading to increased sales for businesses.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="">
                <h4 className="text-light">Reduced Administrative Burden</h4>
                <p className="text-light">The "Pay Now" option can reduce the administrative burden on businesses, as there is no need to manually process payments or reconcile accounts.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Testmonials area */}
      {/* <div className="workflow-integration-area ptb-100 bg-color-100f0f ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="task-img">
                <img className="task-img-1" src={paymentGetwaysimagetask11} alt="image" />
                <img className="task-shape-1" src={paymentGetwaysimagetaskshape11} alt="image" />
                <img className="task-img-2" src={paymentGetwaysimagetask2} alt="image" />
                <img className="task-img-3" src={paymentGetwaysimagetask3} alt="image" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="history">
                <div className="section-heading">
                  <h2 className="text-light">Happy Customer Review</h2>
                </div>
                <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions1}>
                  {sliderItems1.map(item => (

                    <div className="history-card d-flex align-items-center position-relative bg-color-5c5c5c">
                      <img className="flex-shrink-0 me-3" src={paymentGetwaysimageUser2} alt="image" />
                      <div className="warp px-3">
                        <h4 className="text-light">{item.text_data}</h4>
                        <p className="text-light">{item.description_data}</p>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
  
        <img className="task-shape-2" src={paymentGetwaysimagetaskshape2} alt="image" />
        <img className="team-shape" src={paymentGetwaysimageteamShape} alt="image" />
      </div> */}


      <section className="bg-color-100f0f pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">Join Our Partner Program</h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>  Know More <i className="flaticon-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>

      {/* FAQ */}
      <div className="faq-area ptb-100 bg-dark ">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-image-">
                <img src={paymentGetwayfaq} alt="faq" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is the Pay Now option?</Accordion.Header>
                      <Accordion.Body>
                        The "Pay Now" option is a feature of FintechDaddy that allows customers to make secure online payments quickly and easily without the need for additional steps such as creating an account or logging in.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Is the Pay Now option secure?</Accordion.Header>
                      <Accordion.Body>
                        Yes, the Pay Now option is a secure way to make online payments. FintechDaddy use advanced security measures such as encryption and tokenization to protect customers' payment information.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Do I need to create an account to use the Pay Now option?</Accordion.Header>
                      <Accordion.Body>
                        No, you do not need to create an account to use the Pay Now option. Simply enter your payment information and complete the transaction.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Can I use the Pay Now option for all types of purchases?</Accordion.Header>
                      <Accordion.Body>
                        It depends on what type of business you run. The "Pay Now" option may not be available for all types of purchases or on all websites.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>How fast is the Pay Now option?</Accordion.Header>
                      <Accordion.Body>
                        The Pay Now option is a fast way to make online payments. Transactions are usually processed within seconds or minutes.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paynow;