import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import { NavLink } from "react-router-dom";
function Aboutus() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FintechDaddy - Trusted Payment Gateway Partner in India</title>
        <meta name="description" content="Let Explore how we're reshaping the payments landscape and partnering with
businesses to achieve financial success."></meta>
      </Helmet>

      {/* Start Page Banner Area */}
      <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="page-banner-heading text-center">
                <h1 style={{ color: 'white' }}>About Us</h1>
                <ul>
                  <li>
                    <NavLink className="nav-link active" to="/">Home</NavLink>
                  </li>
                  <li>About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Banner Area */}
      <div className="experience-area pt-100 pb-70 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">FintechDaddy - Your Trusted Payment Gateway Partner</h2>
                  <p className="text-light">At FintechDaddy, we are dedicated to empowering businesses with secure and seamless payment solutions. As a leading payment gateway provider, we understand the evolving needs of modern businesses and strive to deliver innovative solutions that drive growth and success.</p>
                  <p className="text-light">Our mission is to simplify the payment process for businesses of all sizes, enabling them to accept payments conveniently and securely. With FintechDaddy, you can unlock the full potential of your business and provide a seamless payment experience to your customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Start Our Best Services Area */}
      <div className="best-service-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="">
            <h2 className="text-light mb-3">Why Choose FintechDaddy?</h2>
            <p className='text-light fs-6'><b>Secure and Reliable:</b> We prioritize the security of your transactions and customer data. Our robust encryption protocols and advanced fraud prevention measures ensure that your payments are processed securely and your sensitive information is protected.
            </p>
            <p className='text-light fs-6'><b>Seamless Integration:</b>Our payment gateway seamlessly integrates with various platforms, including e-commerce websites, mobile applications, and point-of-sale systems. With easy-to-use APIs and comprehensive documentation, integrating our payment gateway into your existing systems is a breeze.
            </p>
            <p className='text-light fs-6'><b>Multiple Payment Options:</b>We support a wide range of payment methods, including credit cards, debit cards, digital wallets, and alternative payment options. This gives your customers the flexibility to choose their preferred payment method, leading to higher conversion rates and customer satisfaction.
            </p>
            <p className='text-light fs-6'><b>Advanced Reporting and Analytics:</b>Our intuitive dashboard provides you with comprehensive reporting and analytics, giving you valuable insights into your transaction data. Understand customer behavior, track sales performance, and make data-driven decisions to optimize your payment processes and drive growth.
            </p>
            <p className='text-light fs-6'><b>Dedicated Support:</b>We believe in providing exceptional customer support. Our experienced support team is available to assist you every step of the way, offering prompt and personalized assistance to address any questions or concerns you may have.

            </p>

            <p>At FintechDaddy, we are committed to being your trusted payment gateway partner. We are driven by innovation, reliability, and the success of our clients. Join us on this payment journey and experience the difference FintechDaddy can make for your business.
            </p>
          </div>
        </div>
      </div>

      <div className="best-service-area pb-70 bg-dark">
        <div className="container">
          <div className="">
            <h4 className="text-light mb-3">Contact Us Here:</h4>
            <p className='text-light fs-6'>FINTECHDADDY SOLUTIONS PRIVATE LIMITED</p>
            <p className='text-light fs-6'>1014/1, Shriram park, Khora Colony, Ghaziabad,
Ghaziabad, Uttar Pradesh, 201003</p>
            <p className='text-light fs-6'>Email Id:- <NavLink className='text-light' to="mailto:Gauravsingh@fintechdaddy.com">Gauravsingh@fintechdaddy.com</NavLink><br /></p>
            <p className='text-light fs-6'>Phone No. <NavLink className='text-light' to="tel:+91-8929253949">+91-8929253949</NavLink></p>


          </div>

        </div>
      </div>

    </div>
  );
}

export default Aboutus;