import React, { useEffect } from 'react';
import bannerpayment from '../../assets/images/banner1.jpg';
import blogimage1 from '../../assets/images/blog/blog-9.jpg';
import blogimage2 from '../../assets/images/blog/blog-3.jpg';
import blogimage3 from '../../assets/images/svg/caleander.svg';
import blogimage4 from '../../assets/images/blog/blog-5.jpg';
import blogimage5 from '../../assets/images/user/user-6.png';
import blogimage6 from '../../assets/images/blog/latest-3.jpg';
import blogimage7 from '../../assets/images/blog/latest-1.jpg';
import blogimage8 from '../../assets/images/blog/blog-9.jpg';


import { NavLink } from "react-router-dom";
function Blogpage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Start Page Banner Area */}
      <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="page-banner-heading text-center">
                <h1 style={{ color: 'white' }}>Blog Details</h1>
                <ul>
                  <li>
                    <NavLink className="nav-link active" to="/">Home</NavLink>
                  </li>
                  <li>Blog Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100 bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <form className="search-form">
                <div className="form-group">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <button type="submit" className="search-btn">
                    <i className="flaticon-search" />
                  </button>
                </div>
              </form>
              <div className="categories-box">
                <h4 className="text-light">Categories</h4>
                <ul>
                  <li>
                    <i className="bx bxs-chat" />
                    <a href="/Blog-page">Apps Development</a>
                    <span>03</span>
                  </li>
                  <li>
                    <i className="bx bxs-chat" />
                    <a href="/Blog-page">Corporate Business</a>
                    <span>02</span>
                  </li>
                  <li>
                    <i className="bx bxs-chat" />
                    <a href="/Blog-page">Digital Marketing</a>
                    <span>08</span>
                  </li>
                  <li>
                    <i className="bx bxs-chat" />
                    <a href="/Blog-page">Graphics Design</a>
                    <span>06</span>
                  </li>
                  <li>
                    <i className="bx bxs-chat" />
                    <a href="/Blog-page">CEO Optimization</a>
                    <span>04</span>
                  </li>
                  <li>
                    <i className="bx bxs-chat" />
                    <a href="/Blog-page">Web Development</a>
                    <span>03</span>
                  </li>
                </ul>
              </div>
              <div className="tags">
                <h4 className="text-light">Tags</h4>
                <ul>
                  <li>
                    <a href="/Blog-page">App</a>
                  </li>
                  <li>
                    <a href="/Blog-page">Branding</a>
                  </li>
                  <li>
                    <a href="/Blog-page">Digital</a>
                  </li>
                  <li>
                    <a href="/Blog-page">Graphics</a>
                  </li>
                  <li>
                    <a href="/Blog-page">Saas</a>
                  </li>
                  <li>
                    <a href="/Blog-page">Startup</a>
                  </li>
                </ul>
              </div>
              <div className="recent-post">
                <h4 className="text-light">Recent Posts</h4>
                <ul>
                  <li>
                    <a href="/Blog-page">
                      <img className="calender-icon" src={blogimage2} alt="image" />
                    </a>
                    <div className="info">
                      <span>
                        <img src={blogimage3} alt="image" className='me-2' />
                         06 Jun 2022
                      </span>
                      <h5>
                        This Long-Awaited Technology May Finally Change The World
                      </h5>
                    </div>
                  </li>
                  <li>
                    <a href="/Blog-page">
                      <img className="calender-icon" src={blogimage4} alt="image" />
                    </a>
                    <div className="info">
                      <span>
                        <img src={blogimage3} alt="image" className='me-2' />
                        07 Jun 2022
                      </span>
                      <h5>
                        Design Thinking: Building a Design System for an Existing
                        Product
                      </h5>
                    </div>
                  </li>
                  <li>
                    <a href="/Blog-page">
                    <img className="calender-icon" src={blogimage5} alt="image" />
                    </a>
                    <div className="info">
                      <span>
                      <img src={blogimage3} alt="image" className='me-2' />
                        09 Jun 2022
                      </span>
                      <h5>
                        8 Life Changing Lessons That I Wish I’d Known at 20-Something
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-content">
                <div className="blog-details">
                  <div className="details-image">
                    <img src={blogimage1} alt="image" />
                    <div className="time">
                      <p>26 July 2022</p>
                    </div>
                  </div>
                  <h4 className="text-light">
                    How To Build And Launch Powerful Responsive
                  </h4>
                  <p>
                    Rerum facilis est et expedita distinctio. Nam libero tempore, cum
                    soluta nobis est eligendi optio nihil impedit quo minus id quod
                    maxime placeat facere possimus, omnis voluptas assumenda est,
                    omnis dolor repellendus. Temporibus autem quibusdam et aut
                    officiis debitis aut rerum
                  </p>
                  <p>
                    necessitatibus saepe eveniet ut et voluptates repudiandae sint et
                    molestiae non recusandae. Itaque earum rerum hic tenetur a
                    sapiente delectus
                  </p>
                </div>
                <div className="rerum">
                  <p>
                    “Rerum facilis est et expedita distinctio. Nam libero tempore, cum
                    soluta nobis est eligendi optio cumque nihil impedit quo minus id
                    quod maxime placeat facere”
                  </p>
                  <i className="flaticon-straight-quotes" />
                </div>
                <div className="editor">
                  <h5 className="text-light">
                    Think Like an Editor Content Strategy And Web Development
                  </h5>
                  <p>
                    Cannot foresee the pain and trouble that are bound to ensue; and
                    equal blame belongs to those who fail their duty through weakness
                    of will, which is the same as saying through shrinking from toil
                    and pain
                  </p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-6">
                      <div className="editop-img">
                      <img src={blogimage6} alt="image" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <div className="editop-img">
                      <img src={blogimage7} alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shoulnow">
                  <h5 className="text-light">What You Should Do Now</h5>
                  <p>
                    Facilis est et expedita distinctio. Nam libero tempore, cum soluta
                    nobis est eligendi optio cumque nihiimpedit quo minus id quod
                    maxime placeat facere possimus, omnis voluptas assumenda mnis
                    dolor
                  </p>
                  <ul>
                    <li>
                      <i className="bx bx-check" />
                      You’ll use these concepts in whichever programming language you
                      learn in the future.
                    </li>
                    <li>
                      <i className="bx bx-check" />
                      That way you have an overview before you start mastering a
                      dedicated language..
                    </li>
                    <li>
                      <i className="bx bx-check" />
                      Amazing Timing and Experience
                    </li>
                  </ul>
                </div>
                <div className="share-social">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <ul className="share-social-icon">
                        <li>
                          <p>Share:</p>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/" target="_blank">
                            <i className="bx bxl-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.twitter.com/" target="_blank">
                            <i className="bx bxl-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/" target="_blank">
                            <i className="bx bxl-linkedin" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.google.com/" target="_blank">
                            <i className="bx bxl-google" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <ul className="social-tags">
                        <li>
                          <p>Tags:</p>
                        </li>
                        <li>
                          <a href="/Blog-page">Saas</a>
                        </li>
                        <li>
                          <a href="/Blog-page">Digital</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <form className="reply-form">
                  <h5 className="text-light">Comments Reply</h5>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <input
                        type="name"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        className="form-control textarea"
                        placeholder="Comment..."
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="flexCheckDefault"
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault">
                        Save my info for the next comment
                      </label>
                    </div>
                  </div>
                  <button type="submit" className="reply-btn">
                    Post Comment
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default Blogpage;