import React, { useState } from 'react';
import logo from '../assets/images/001.png';
import { NavLink } from 'react-bootstrap';
function IntegrationSidebar() {

    const [activeMenu, setActiveMenu] = useState(null);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [activeSubSubMenu, setActiveSubSubMenu] = useState(null);
    const handleMenuClick = (menuIndex) => {
        setActiveMenu(activeMenu === menuIndex ? null : menuIndex);
        setActiveSubMenu(null);
        setActiveSubSubMenu(null);
    };

    const handleSubMenuClick = (subMenuIndex) => {
        setActiveSubMenu(activeSubMenu === subMenuIndex ? null : subMenuIndex);
        setActiveSubSubMenu(null);
    };

    const handleSubSubMenuClick = (subSubMenuIndex) => {

        console.log(`SubSubMenu clicked: ${subSubMenuIndex}`);


    };

    return (
        <>



            <div className="doc-sidebar col-md-3 col-12 order-0 d-md-flex bg-dark">
                <div id="doc-nav sidebar" className="doc-nav">
                    <NavLink to="/">
                        <img src={logo} alt="logo" className="img-fluid m-5 logo" />
                    </NavLink>
                    <div className="burger px-3 text-light fs-2" id="toggle2">&#9776;</div>
                    <nav id="doc-menu" className="nav doc-menu flex-column sticky">
                        <li className="nav-item " onClick={() => handleMenuClick(0)}>
                            <a className="nav-link scrollto active" href="#download-section">
                                Introduction
                            </a>
                            {activeMenu === 0 && (
                                <nav className="nav doc-sub-menu nav flex-column">
                                    <li className="nav-item">
                                     <a className="nav-link scrollto" href="#web-checkout" onClick={(e) => e.stopPropagation()}><span style={{color:'white'}}>&#8594;</span> Web Checkout</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#e-commerce-platforms" onClick={(e) => e.stopPropagation()}><span style={{color:'white'}}>&#8594;</span> E-commerce Platforms</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#server-kit-integration" onClick={(e) => e.stopPropagation()}><span style={{color:'white'}}>&#8594;</span> Server Kit Integration</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#mobile-apps" onClick={(e) => e.stopPropagation()}><span style={{color:'white'}}>&#8594;</span> Mobile Apps</a>
                                    </li>

                                </nav>
                            )}
                        </li>
                        <li className="nav-item" onClick={() => handleMenuClick(1)}>
                            <a className="nav-link scrollto" href="#duringIntigration">Getting Started</a>
                            {activeMenu === 1 && (
                                <nav className="nav doc-sub-menu nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#duringIntigration" onClick={(e) => e.stopPropagation()}>
                                        <span style={{color:'white'}}>&#8594;</span> Testing Your integration
                                        </a>
                                        <nav className="nav doc-sub-menu child-menu nav flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link scrollto" href="#marchentTestKey" onClick={(e) => e.stopPropagation()}>
                                                <span style={{color:'white'}}>&bull;</span> Marchant Testing Key
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link scrollto" href="#secrateKey" onClick={(e) => e.stopPropagation()}>
                                                <span style={{color:'white'}}>&bull;</span> Secrate Testing Key
                                                </a>
                                            </li>
                                        </nav>
                                    </li>
                                </nav>
                            )}
                        </li>
                        <li className="nav-item" onClick={() => handleMenuClick(2)}>
                            <a className="nav-link scrollto" href="#webIntegrationAPI">
                                Web Integration
                            </a>
                            {activeMenu === 2 && (
                                <nav className="nav doc-sub-menu nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#createToken" onClick={(e) => e.stopPropagation()}>
                                        <span style={{color:'white'}}>&#8594;</span> Create Token
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#createOrder" onClick={(e) => e.stopPropagation()}>
                                        <span style={{color:'white'}}>&#8594;</span> Create Order
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#webhookAPi" onClick={(e) => e.stopPropagation()}>
                                        <span style={{color:'white'}}>&#8594;</span> WebHook
                                        </a>
                                    </li>
                                </nav>
                            )}
                        </li>
                        <li className="nav-item" onClick={() => handleMenuClick(3)}>
                            <a className="nav-link scrollto" href="#callouts-section">
                                 Ecommerce Platforms
                            </a>
                            {activeMenu === 3 && (
                                <nav className="nav doc-sub-menu nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#wordpress" onClick={(e) => e.stopPropagation()}>
                                           <span style={{color:'white'}}>&#8594;</span> Wordpress
                                        </a>
                                        <nav className="nav doc-sub-menu child-menu nav flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link scrollto" href="#wpIntegrationSteps" onClick={(e) => e.stopPropagation()}>
                                                - Integration Steps
                                                </a>
                                                <nav className="nav doc-sub-menu child-menu nav flex-column">
                                                    <li className="nav-item">
                                                        <a className="nav-link scrollto" href="#wpInstall" onClick={(e) => e.stopPropagation()}>
                                                        <span style={{color:'white'}}>&bull;</span> Install Plugin
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link scrollto" href="#css" onClick={(e) => e.stopPropagation()}>
                                                        <span style={{color:'white'}}>&bull;</span> Configure Your Store
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link scrollto" href="#sass" onClick={(e) => e.stopPropagation()}>
                                                        <span style={{color:'white'}}>&bull;</span> Set Up webhooks
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link scrollto" href="#less" onClick={(e) => e.stopPropagation()}>
                                                        <span style={{color:'white'}}>&bull;</span> Test Your Store
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link scrollto" href="#less">
                                                        <span style={{color:'white'}}>&bull;</span> Go Live
                                                        </a>
                                                    </li>
                                                </nav>
                                            </li>
                                        </nav>
                                    </li>
                                </nav>
                            )}
                        </li>
                        <li className="nav-item" onClick={() => handleMenuClick(4)}>
                            <a className="nav-link scrollto" href="#serverKitintegration">
                                Server Kit Integration
                            </a>
                            {activeMenu === 4 && (
                                <nav className="nav doc-sub-menu nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#PHP" onClick={(e) => e.stopPropagation()}>
                                        <span style={{color:'white'}}>&#8594;</span> PHP
                                        </a>

                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#PHP" onClick={(e) => e.stopPropagation()}>
                                        <span style={{color:'white'}}>&#8594;</span> Java
                                        </a>

                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#PHP" onClick={(e) => e.stopPropagation()}>
                                        <span style={{color:'white'}}>&#8594;</span> Python 
                                        </a>

                                    </li>
                                </nav>
                            )}
                        </li>


                    </nav>


                </div>
            </div>

        </>
    );
}

export default IntegrationSidebar;