import React from "react";
import $ from 'jquery';
import ReactDOM from "react-dom";



// import "./assets/js/jquery-1.7.1.min";

// // import "./assets/js/bootstrap.bundle.min.js";
//// import "./assets/js/owl.carousel.min.js";
// import "./assets/js/jquery.magnific-popup.min.js";
// import "./assets/js/scrollcue.js";
// // import "./assets/js/appear.min.js";
// import "./assets/js/odometer.min.js";
// // import "./assets/js/form-validator.min.js";
// // import "./assets/js/contact-form-script.js";
// // import "./assets/js/jquery.ajaxchimp.min.js";
// // import "./assets/js/meanmenu.min.js";
// // import "./assets/js/main.js";


// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Home,
  Navigation,
  Footer,
  PaymentGetway,
  Payout,
  SignIn,
  SignUP,
  Paylink,
  Paymentpage,
  Paymentbutton,
  Qrcode,
  Invoice,
  Paynow,
  Affiliate,
  Reseller,
  Whitelabel,
  Integration,
  PaymentsLinks,
  Documents,
  Contact,
  Blogpage,
  Aboutus,
  PrivacyPolicy,
  Termscondition,
  Raise,
  Chat,
  Talk,
  Career,
  Faqs,
} from "./components";
const segment = window.location.pathname.split("/");
console.log(segment);
// console.warn(segment[1] + 'ppppppppp');
 if (segment[1] == 'Integration') {
  // alert('pppp22');
  ReactDOM.render(
    <Router>
      <Routes>
        <Route path="/Integration" element={<Integration />} />
      </Routes>
    </Router>,

    document.getElementById("root")
  );
} 

else if (segment[1] == 'pagelinks') {
  const slug = segment[2];
  console.log(slug);
  ReactDOM.render(
    <Router>
      <Routes>
      <Route path='/pagelinks/:slug' element={<PaymentsLinks slug={slug} />} />
      </Routes>
    </Router>,
    document.getElementById("root")
  );
}

else {
  ReactDOM.render(

    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Payment-Gateway" element={<PaymentGetway />} />
        <Route path="/Payout" element={<Payout />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/Signup" element={<SignUP />} />
        <Route path="/Payment-link" element={<Paylink />} />
        <Route path="/Payment-Page" element={<Paymentpage />} />
        <Route path="/Payment-Button" element={<Paymentbutton />} />
        <Route path="/Qr-code" element={<Qrcode />} />
        <Route path="/Invoice" element={<Invoice />} />
        <Route path="/Pay-now" element={<Paynow />} />
        <Route path="/Affiliate" element={<Affiliate />} />
        <Route path="/Reseller" element={<Reseller />} />
        <Route path="/White-label" element={<Whitelabel />} />
        <Route path="/Documents" element={<Documents />} />
        <Route path="/Blog-page" element={<Blogpage />} />
        <Route path="/About-us" element={<Aboutus />} />
        <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route path="/Terms-condition" element={<Termscondition />} />
        <Route path="/Raise-Request" element={<Raise />} />
        <Route path="/Chat-with-us" element={<Chat />} />
        <Route path="/Talk-to-us" element={<Talk />} />
        <Route path="/Contact-us" element={<Contact />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/Faqs" element={<Faqs />} />
      </Routes>
      <Footer />


    </Router>,


    document.getElementById("root")
  );
}

// serviceWorker.unregister();