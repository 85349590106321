import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import image1 from '../../assets/images/Products/Invoive/invoice.png';

import bestService1 from '../../assets/images/svg/awesome-1.svg';
import bestService2 from '../../assets/images/svg/awesome-2.svg';
import bestService3 from '../../assets/images/svg/awesome-3.svg';

import paymentGetwaysimage1 from '../../assets/images/why1.png';
import paymentGetwaysimage2 from '../../assets/images/why2.png';
import paymentGetwaysimage3 from '../../assets/images/why3.png';
import paymentGetwaysimage4 from '../../assets/images/why4.png';
import paymentGetwaysimage5 from '../../assets/images/how.png';

import paymentGetwaysimageprocess1 from '../../assets/images/shape/graphic-shape-3.png';
import paymentGetwaysimageprocess2 from '../../assets/images/paying-img.png';
import paymentGetwaysimageprocess3 from '../../assets/images/Products/Invoive/print & share invoice section.png';

import paymentGetwaysimageprocess4 from '../../assets/images/graphics-img-2.png';
import paymentGetwaysimageprocess5 from '../../assets/images/graphics-img-3.png';
import paymentGetwaysimageprocess6 from '../../assets/images/shape/graphic-shape-1.png';
import paymentGetwaysimageprocess7 from '../../assets/images/shape/graphic-shape-2.png';

import paymentGetwaysimagetask1 from '../../assets/images/Products/Invoive/Invoice 2 .png';
import paymentGetwaysimagetask2 from '../../assets/images/task-img-2.png';
import paymentGetwaysimagetask3 from '../../assets/images/task-img-3.png';
import paymentGetwaysimagetaskshape1 from '../../assets/images/shape/task-shape-1.png';
import paymentGetwaysimagetaskshape2 from '../../assets/images/shape/task-shape-2.png';
import paymentGetwaysimageUser2 from '../../assets/images/user/user-1.png';
import paymentGetwaysimageteamShape from '../../assets/images/shape/team-shape.png';
import paymentGetwayfaq from '../../assets/images/faq-img.png';
import { NavLink } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
function Invoice() {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faster Online Invoice Payments Option | FintechDaddy</title>
        <meta name="description" content="Use FintechDaddy invoice for hassle-free invoice payments & take control of your
transaction with our innovative option.
"></meta>
      </Helmet>

      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">Difficulty in Managing invoices? Are You Facing Problems in Accepting Digital Payments?</h2>
                  <p className="text-light">With FintechDaddy, Specially Designed for Invoices, Manage Your Payments Easily.</p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/contact-us">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image d-flex justify-content-center">
                <img className="experience-img" src={image1} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="client-say-area client-say-testimonials-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            {/* <span class="subtitle">Our Testimonials</span> */}
            <h2 className="text-light">How Invoice Payment Work</h2>
          </div>
          <div className="row range_benefits">
            <div className="col-lg-4" src="">
              <div className="say-card">
                {/* <h4 className="text-light">Notify your customers</h4> */}
                <p className="text-light">FintechDaddy Invoice  payment is a process in which a customer pays for goods or services that they have received from a business based on an invoice that the business has issued.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="">
              <div className="say-card">
                {/* <h4 className="text-light">Wide range of payment</h4> */}
                <p className="text-light">The payment process for an invoice typically involves the customer reviewing the invoice, verifying the accuracy of the information, and making a payment to the business.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="">
              <div className="say-card">
                {/* <h4 className="text-light">Reminders on auto-pilot</h4> */}
                <p className="text-light">After the payment has been made, the business will typically update the invoice to reflect the payment and provide a receipt or confirmation of the payment to the customer.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="workflow-integration-area ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="task-img">
                <img className="task-img-1" src={paymentGetwaysimagetask1} alt="image" />
                <img className="task-shape-1" src={paymentGetwaysimagetaskshape1} alt="image" />
                {/* <img className="task-img-2" src={paymentGetwaysimagetask2} alt="image" /> */}
                {/* <img className="task-img-3" src={paymentGetwaysimagetask3} alt="image" /> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="history">
                <div className="section-heading">
                  <h2 className="text-light">Faster Online Invoice Payments</h2>
                  <p className='fs-6 py-3 px-2 border rounded' style={{ lineHeight: "1.5" }}>Set up your preferred payment gateway, and start accepting card payments to instantly increase your cash flow.
                  </p>
                </div>
                {/* <div className="history-card d-flex align-items-center position-relative bg-color-5c5c5c">
                  <img className="flex-shrink-0 me-3" src={paymentGetwaysimageUser2} alt="image" />
                  <div className="warp">
                    <h4 className="text-light">Paige Turner</h4>
                    <p className="text-light">On the other hand, we denounce with righus indignan and dislike men who are so beguiled and delized</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* shape images */}
        <img className="task-shape-2" src={paymentGetwaysimagetaskshape2} alt="image" />
        <img className="team-shape" src={paymentGetwaysimageteamShape} alt="image" />
      </div>

      {/* brands */}
      {/* <div className="vbnmr-area pt-100 pb-70 bg-color-5c5c5c">  </div> */}
      {/* brands */}
      <div className="modern-graphic-area-one ptb-100 bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="modern-info">
                <div className="section-heading">
                  {/* <span className="sub-title">Print and share invoices with ease</span> */}
                  <h2 className="text-light">Print & Share Invoices With Ease</h2>
                  <p className="text-light">Automate payment reminders and get paid on time. You can also send personalized thank you notes when you receive payments.
                  </p>
                </div>
                {/* <ul>
                    <li>
                      <i className="flaticon-check" />Distinctively brand adaptive innovation
                    </li>
                    <li>
                      <i className="flaticon-check" />You need to be sure there isn't anything
                    </li>
                  </ul> */}
                <a className="common-btn two" href="/contact-us">Learn More</a>
                <img className="graphic-shape-3 rotate" src={paymentGetwaysimageprocess1} alt="image" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="graphics-img">
                <img className="paying-img" src={paymentGetwaysimageprocess2} alt="paying-img" data-cues="zoomIn" data-disabled="true" />
                <img className="graphics-img-1" src={paymentGetwaysimageprocess3} alt="image" />
                {/* shape images */}
                {/* <img className="graphics-img-2 moveVertical" src={paymentGetwaysimageprocess4} alt="image" /> */}
                {/* <img className="graphics-img-3 moveHorizontal" src={paymentGetwaysimageprocess5} alt="image" /> */}
                {/* <img className="graphic-shape-1" src={paymentGetwaysimageprocess6} alt="image" /> */}
                <img className="graphic-shape-2 moveVertical" src={paymentGetwaysimageprocess7} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------- */}



      {/* Start Our Best Services Area */}
      <div className="best-service-area pt-100 pb-70 bg-color-100f0f benefits ">
        <div className="container">
          <div className="section-title">
            <h2 className="text-light">What Is The Benefit of Invoice Payment </h2>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
          </div>
          <div className="row invoice">
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card  text-center">
                <div className="awesome-image">
                  <img src={bestService1} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Faster Payment Processing</a>
                </h4>
                <p className="text-light">Invoice payment allows for faster processing of payments, as payments can be made online, eliminating the need for paper checks and mail.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card two text-center">
                <div className="awesome-image">
                  <img src={bestService2} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Increased Accuracy</a>
                </h4>
                <p className="text-light">Invoices can be easily tracked and monitored, which can help ensure that payments are accurate and timely.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <img src={bestService3} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Improved Cash Flow Management</a>
                </h4>
                <p className="text-light">Invoice payment can help businesses manage their cash flow more effectively, as payments can be received faster and tracked more easily.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card  text-center">
                <div className="awesome-image">
                  <img src={bestService1} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Greater Convenience</a>
                </h4>
                <p className="text-light">Invoice payment provides greater convenience for both the business and its customers, as payments can be made online from anywhere with an internet connection.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card two text-center">
                <div className="awesome-image">
                  <img src={bestService2} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Reduced Administrative Burden</a>
                </h4>
                <p className="text-light">Invoice payment can help reduce the administrative burden associated with traditional payment methods, as payments can be processed automatically.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <img src={bestService3} alt="image" />
                </div>
                <h4>
                  <a href="#" className="text-light">Enhanced Security</a>
                </h4>
                <p className="text-light">Invoice payment systems use encryption and other security measures to protect sensitive information, which can help reduce the risk of fraud or other security breaches.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Our Best Services Area */}

      <section className=" bg-dark pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">Join Our Partner Program</h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>  Know More <i className="flaticon-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>
      {/* FAQ */}
      <div className="faq-area ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-image-">
                <img src={paymentGetwayfaq} alt="faq" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is invoice payment?</Accordion.Header>
                      <Accordion.Body>
                        Invoice payment is a process by which customers pay for goods or services that have been provided by a business, based on an invoice that has been issued.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How does FintechDaddy invoice payment work?</Accordion.Header>
                      <Accordion.Body>
                        After a business provides goods or services to a customer, an invoice is issued detailing the amount owed and the payment terms. The customer then makes a payment to the business based on the invoice
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What are the benefits of FintechDaddy invoice payment?</Accordion.Header>
                      <Accordion.Body>
                        Benefits of FintechDaddy invoice payment include faster payment processing, increased accuracy, improved cash flow management, greater convenience, reduced administrative burden, and enhanced security.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>What payment methods are typically accepted for FintechDaddy invoice payments?</Accordion.Header>
                      <Accordion.Body>
                        Payment methods for invoice payments can vary, but typically include credit or debit cards, bank transfers, and online payment platforms.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>What happens if a customer does not pay an invoice on time?</Accordion.Header>
                      <Accordion.Body>
                        If a customer does not pay an invoice on time, the business may need to follow up with the customer to request payment. This may involve sending reminders, issuing late payment fees, or taking legal action if necessary.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;