import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import { NavLink } from "react-router-dom";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function TalkUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FintechDaddy | Talk to Us - Let's Connect</title>
        <meta name="description" content="Get in touch with FintechDaddy & initiate a conversation. We're here to address your
queries, collaborate on solutions & assist you in achieving your financial goals."></meta>
      </Helmet>

      {/* Start Page Banner Area */}
      <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="page-banner-heading text-center">
                <h1 style={{ color: 'white' }}>Talk to us</h1>
                <ul>
                  <li>
                    <NavLink className="nav-link active" to="/">Home</NavLink>
                  </li>
                  <li>Talk to us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Banner Area */}
      <div className="experience-area pt-100 pb-4 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div>
                <div className="section-heading">
                  <h2 className="text-light fs-1">We're Here to Help You Find the Perfect Payment Gateway Solution</h2>
                  <p className="text-light"><b>Introduction: </b>
                    At FintechDaddy we understand that choosing the right payment gateway for your business is crucial. That's why we're committed to providing personalized assistance and guiding you towards the best payment gateway solution that meets your specific needs. We invite you to reach out to our team of experts and start a conversation today.
                  </p>
                  <p className="text-light"><b>Section 1: Tailored Solutions </b>
                    Our knowledgeable team will work closely with you to understand your unique business requirements.
                    We'll analyze your industry, transaction volume, security needs, and other relevant factors.
                    Based on our analysis, we'll recommend a customized payment gateway solution that aligns perfectly with your business goals.
                  </p>
                  <p className="text-light"><b>Section 2: Expert Advice </b>
                    Our experienced specialists are ready to address your questions and concerns.
                    We'll provide comprehensive information about our gateway features, security measures, integration options, and pricing structure.
                    We're here to offer guidance and help you make informed decisions that support your business growth and success.
                  </p>
                  <p className="text-light"><b>Section 3: Seamless Integration </b>
                    Our team will assist you in seamlessly integrating FintechDaddy into your existing website or e-commerce platform.
                    We'll provide step-by-step instructions, documentation, and technical support throughout the integration process.
                    With our expertise, you can enjoy a smooth and hassle-free implementation that ensures uninterrupted payment processing.
                  </p>
                  <p className="text-light"><b>Section 4: Ongoing Support </b>
                    Our commitment to your success doesn't end with integration. We offer ongoing support to address any issues or inquiries you may have.
                    Our dedicated support team is available via phone, email, or live chat to provide prompt assistance whenever you need it.
                  </p>
                  <p className="text-light"><b>Closing Statement: </b>
                    At FintechDaddy, we believe in building strong relationships with our clients. We're passionate about helping businesses like yours thrive with the right payment gateway. Let's discuss your needs and take your payment processing to new heights. Talk to us today!
                  </p>
                  <div className="button-group gap-4 text-center">
                    <Button className="banner-btn" onClick={handleShow}>Talk to us</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal  */}
      <Modal show={show} onHide={handleClose} className='contact'>
        <Modal.Header closeButton className='bg-color-0b0808 border'>
          <Modal.Title className='text-light ps-3'>Contact Us</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <div className="profile-authentication-area">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="register-form modal-contact bg-color-0b0808">
                <div className='form row'>
                  <div className="form-group col-md-12">
                    <label className="text-light">Name</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-light">Email</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-light">Phone</label>
                    <input type="number" className="form-control" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="text-light">Message</label>
                    <textarea type="text" className="form-control h-auto" />
                  </div>
                  <button type="submit" className="default-btn" onClick={handleClose}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal close */}


      <div className="best-service-area pb-70 bg-color-100f0f">
        <div className="container">
          <div className="">
            <h4 className="text-light mb-3">Contact Us Here:</h4>
            <p className='text-light fs-6'>FINTECHDADDY SOLUTIONS PRIVATE LIMITED</p>
            <p className='text-light fs-6'>1014/1, Shriram park, Khora Colony, Ghaziabad,
Ghaziabad, Uttar Pradesh, 201003</p>
            <p className='text-light fs-6'>Email Id:- <NavLink className='text-light' to="mailto:Gauravsingh@fintechdaddy.com">Gauravsingh@fintechdaddy.com</NavLink><br /></p>
            <p className='text-light fs-6'>Phone No. <NavLink className='text-light' to="tel:+91-8929253949">+91-8929253949</NavLink></p>


          </div>

        </div>
      </div>

    </div>
  );
}

export default TalkUs;