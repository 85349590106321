import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { Helmet } from "react-helmet";
import bannerpayment from "../../assets/images/banner1.jpg";
import image1 from "../../assets/images/Pages/Affilaite Top.png";
import imagelogo1 from "../../assets/images/payment/bhim.png";
import imagelogo2 from "../../assets/images/payment/upi.png";
import imagelogo3 from "../../assets/images/payment/gpay.png";
import imagelogo4 from "../../assets/images/payment/phonepe.png";
import imagelogo5 from "../../assets/images/payment/paytm.png";
import imagelogo6 from "../../assets/images/payment/rupay.png";
import imagelogo7 from "../../assets/images/payment/visa.png";
import imagelogo8 from "../../assets/images/payment/mastercard.png";
import boostYourBussiness1 from "../../assets/images/experience-img.png";
import boostYourBussiness2 from "../../assets/images/svg/business-1.svg";
import boostYourBussiness3 from "../../assets/images/svg/business-2.svg";
import userBussiness3 from "../../assets/images/svg/user.svg";
import paymentGetwaysimage1 from "../../assets/images/why1.png";
import paymentGetwaysimage2 from "../../assets/images/why2.png";
import paymentGetwaysimage3 from "../../assets/images/why3.png";
import paymentGetwaysimage4 from "../../assets/images/why4.png";
import paymentGetwaysimage5 from "../../assets/images/how.png";
import paymentGetwayfaq from "../../assets/images/faq-img.png";

import paymentGetwaysimageprocess1 from "../../assets/images/shape/graphic-shape-3.png";
import paymentGetwaysimageprocess2 from "../../assets/images/paying-img.png";
import paymentGetwaysimageprocess3 from "../../assets/images/Pages/Affilaite 2nd Benefits Of Being.png";

import paymentGetwaysimageprocess4 from "../../assets/images/graphics-img-2.png";
import paymentGetwaysimageprocess5 from "../../assets/images/graphics-img-3.png";
import paymentGetwaysimageprocess6 from "../../assets/images/shape/graphic-shape-1.png";
import paymentGetwaysimageprocess7 from "../../assets/images/shape/graphic-shape-2.png";

import paymentGetwaysimagetask1 from "../../assets/images/shape/service-shape-3.png";
import paymentGetwaysimagetask2 from "../../assets/images/shape/service-shape-2.png";
import paymentGetwaysimagetask3 from "../../assets/images/shape/service-shape-5.png";
import paymentGetwaysimagetaskshape1 from "../../assets/images/shape/service-shape-1.png";
import paymentGetwaysimagetaskshape2 from "../../assets/images/shape/task-shape-2.png";
import Affiliateimage from "../../assets/images/shape/icon01.png";
import { NavLink } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
function Affiliate() {
  const sliderOptions = {
    loop: true,
    margin: 40,
    autoplay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 8,
      },
    },
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 },
    { id: 7, image: imagelogo7 },
    { id: 8, image: imagelogo8 },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FintechDaddy Affiliate Program | Share & Earn</title>
        <meta
          name="description"
          content="Join Our affiliate program to unlock commissions or rewards & enjoy earning
through the benefits of promoting our reliable financial solutions."
        ></meta>
      </Helmet>

      <div className="experience-area pb-70 bg-color-100f0f mt-5 pt-5">
        <div className="container">
          <div className="row align-items-center mt-3">
            <div className="col-lg-7">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">
                    Join Our Partner Program And Earn Commision
                  </h2>
                  <p className="text-light">
                    affiliates sign up and receive a unique link or code to
                    promote the company's products or services. When a user
                    clicks on the affiliate link and makes a purchase, the
                    affiliates earns a commission or reward.
                  </p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/contact-us">
                    Know More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="experience-image d-flex justify-content-center">
                <img className="experience-img" src={image1} alt="image" />
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <div className="container">
                <div className="partners mt-5">
                  <div className="header text-light">Supports all UPI Apps</div>
                  <div className="partner-img">
                    <OwlCarousel
                      className="vbnmr-slider owl-carousel owl-theme"
                      {...sliderOptions}
                    >
                      {sliderItems.map((item) => (
                        <div className="partner-img" key={item.id}>
                          <img
                            src={item.image}
                            alt="image"
                            className="lazy loaded"
                          />
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="call-overlay py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 col-xl-6">
              <div className="call-item text-center text-sm-left d-flex">
                <div className="call-icon">
                  <img src={Affiliateimage} alt="call" />
                </div>
                <div className="call-content">
                  <h5 className="title text-light">Ready To Start Your Earnings Through Our Referal Program</h5>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-6 text-center text-sm-left text-md-right">
              <div className="button-group">
                <a className="banner-btn" href="#">learn More <i className="flaticon-right" /></a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className="services-area one pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            {/* <span className="sub-title">On-Demand Settlements</span> */}
            <h2 className="text-light">
              Benefits for Affiliates Promoting the Business's Products or
              Services
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card one">
                <i className="flaticon-software" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Passive income</a>
                  </h4>
                  <p>
                    Affiliates can earn passive income by promoting products or
                    services that they believe in, without having to create or
                    maintain their own products or services.
                  </p>
                </div>
                <img
                  className="service-shape-3 bounce"
                  src={paymentGetwaysimagetask1}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card two">
                <i className="flaticon-customer-service" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Low start-up costs</a>
                  </h4>
                  <p>
                    Affiliate marketing typically requires low start-up costs,
                    making it an accessible option for anyone interested in
                    earning money through online marketing.
                  </p>
                </div>
                <img
                  className="service-shape-2 bounce"
                  src={paymentGetwaysimagetask2}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card three">
                <i className="flaticon-layer" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Flexibility</a>
                  </h4>
                  <p>
                    Affiliates can promote products or services in their own way
                    and on their own schedule, making affiliate marketing a
                    flexible option way for earning.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card four">
                <i className="flaticon-united" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Learning opportunities</a>
                  </h4>
                  <p>
                    Affiliate marketing can provide learning opportunities for
                    affiliates to improve their marketing skills and gain
                    insights into the industry.
                  </p>
                </div>
                <img
                  className="service-shape-5 bounce"
                  src={paymentGetwaysimagetask3}
                  alt="image"
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card four">
                <i className="flaticon-united" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">
                      Variety of Products/Services
                    </a>
                  </h4>
                  <p>
                    Affiliates can choose from a wide range of products or
                    services to promote. This variety allows them to select
                    offers that align with their niche.
                  </p>
                </div>
                <img
                  className="service-shape-5 bounce"
                  src={paymentGetwaysimagetask3}
                  alt="image"
                />
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services-card four">
                <i className="flaticon-united" />
                <div className="info">
                  <h4>
                    <a href="service-details.html">Diverse Product Range</a>
                  </h4>
                  <p>Affiliate marketing allows affiliates to promote a wide range of products or services across various industries and niches.</p>
                </div>
                <img className="service-shape-5 bounce" src={paymentGetwaysimagetask3} alt="image" />
              </div>
            </div> */}
          </div>
        </div>
        {/* shape images */}
        <img
          className="service-shape-1 rotate"
          src={paymentGetwaysimagetaskshape1}
          alt="image"
        />
        <img
          className="service-shape-4 rotate"
          src={paymentGetwaysimagetaskshape2}
          alt="image"
        />
      </div>

      <div className="modern-graphic-area-one ptb-100 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="modern-info">
                <div className="section-heading">
                  {/* <span className="sub-title">Subscriptions On Payment Button</span> */}
                  <h2 className="text-light">
                    Benefits Of Being Fintech Daddy Partner
                  </h2>
                  {/* <p className="text-light">Indignation and dislike men who are so beguiled andy the charms of pleasure the moment, so blinded by desir that they cannot foresee the pain and trouble</p> */}
                </div>
                <ul>
                  <li>
                    <i className="flaticon-check" />
                    Get a fixed commission on transactions made by your
                    affiliates
                  </li>
                  <li>
                    <i className="flaticon-check" />
                    Earn 0.2 % of each successful payment received by your
                    referral
                  </li>
                  <li>
                    <i className="flaticon-check" />
                    Receive automated commissions in your bank account every day
                  </li>
                </ul>
                <a className="common-btn two" href="/contact-us">
                  Learn More
                </a>
                <img
                  className="graphic-shape-3 rotate"
                  src={paymentGetwaysimageprocess1}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="graphics-img">
                <img
                  className="paying-img"
                  src={paymentGetwaysimageprocess2}
                  alt="paying-img"
                  data-cues="zoomIn"
                  data-disabled="true"
                />
                <img
                  className="graphics-img-1"
                  src={paymentGetwaysimageprocess3}
                  alt="image"
                />
                {/* shape images */}
                {/* <img className="graphics-img-2 moveVertical" src={paymentGetwaysimageprocess4} alt="image" /> */}
                {/* <img className="graphics-img-3 moveHorizontal" src={paymentGetwaysimageprocess5} alt="image" /> */}
                {/* <img className="graphic-shape-1" src={paymentGetwaysimageprocess6} alt="image" /> */}
                {/* <img className="graphic-shape-2 moveVertical" src={paymentGetwaysimageprocess7} alt="image" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------- */}

      <div className="client-say-area client-say-testimonials-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            {/* <span class="subtitle">Our Testimonials</span> */}
            <h2 className="text-light">
              If You Join Our Affiliate Program What’s Your Customer Gets
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Fast & Seamless Onboarding</h4>
                <p className="text-light">
                  Send payment links to your customers over SMS, email,
                  WhatsApp, or any other channel of their preference and get
                  paid sooner.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Ready To Go Integration</h4>
                <p className="text-light">
                  FintechDaddy offers more than 120 payment modes such as
                  credit/debit cards, UPI, Google Pay, Amazon pay, Netbanking,
                  wallets and other payment options.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Early Access To New Features</h4>
                <p className="text-light">
                  When generating Payment Links, set multiple automated
                  reminders to get paid on time and boost your revenue.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">Zero Setup Fee</h4>
                <p className="text-light">
                  Let customers initiate partial payments in case of service
                  fulfillment and get the remaining payment on completion of the
                  service.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">No Annual Maintenance Fee</h4>
                <p className="text-light">
                  Generate payment links in bulk by uploading a simple .csv or
                  .xlsx file. Save time and reduce errors by creating payment
                  links in bulk.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card">
                <h4 className="text-light">24*7 support</h4>
                <p className="text-light">
                  Get real-time reports on the transactions processed through
                  payment links and help your finance team manage the books
                  better.
                </p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Boost Your Business */}
      <div className="experience-area pt-100 pb-70  bg-color-100f0f passive_income">
        <div className="container">
          <div className="row align-items-center">
            {/* <div className="col-lg-5" >
              <div className="experience-image">
                <img className="experience-img" src={boostYourBussiness1} alt="image" />
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="business-experience">
                <div className="section-heading">
                  <h2 className="text-light">
                    Anyone, Anywhere Can Earn a Passive Income
                  </h2>
                  {/* <p className="text-light">Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain, but because occasionally circumstances occur</p> */}
                </div>
                <div className="row justify-content-center">
                  <div className="graphics-img">
                    <img
                      className="graphic-shape-2 moveVertical"
                      src={paymentGetwaysimageprocess7}
                      alt="image"
                    />
                    <img
                      className="graphic-shape-3 rotate"
                      src={paymentGetwaysimageprocess1}
                      alt="image"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-xl-4">
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img src={boostYourBussiness3} alt="image" />
                        </div>
                        <h4 className="text-light">Freelancers</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-xl-4">
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img src={userBussiness3} alt="image" />
                        </div>
                        <h4 className="text-light">Social Media Influencers</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-xl-4">
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img src={boostYourBussiness3} alt="image" />
                        </div>
                        <h4 className="text-light">Bloggers</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-xl-4">
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img src={boostYourBussiness2} alt="image" />
                        </div>
                        <h4 className="text-light">New Startup</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-xl-4">
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img src={userBussiness3} alt="image" />
                        </div>
                        <h4 className="text-light">Students</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Boost Your Business */}

      {/* Start Our Best Services Area */}
      <div className="best-service-area pt-100 pb-70 bg-dark">
        <div className="container">
          <div className="section-title">
            <h2 className="text-light">Getting Onboarded</h2>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
          </div>
          <div className="row Four-sec onboard">
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card  text-center">
                <div className="awesome-image">
                  <span className="fs-4 text-light">1</span>
                </div>
                <h4>
                  <a className="text-light">Signup With Us</a>
                </h4>

                {/* <a className="text-success">Join Now</a> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card two text-center">
                <div className="awesome-image">
                  <span className="fs-4 text-light">2</span>
                </div>
                <h4>
                  <a className="text-light">Refer Merchant</a>
                </h4>

                {/* <a className="text-success">Join Now</a> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <span className="fs-4 text-light">3</span>
                </div>
                <h4>
                  <a className="text-light">Onboard Merchant</a>
                </h4>

                {/* <a className="text-success">Join Now</a> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="awesome-card three text-center">
                <div className="awesome-image">
                  <span className="fs-4 text-light last">4</span>
                </div>
                <h4>
                  <a className="text-light">Start Earning</a>
                </h4>

                {/* <a className="text-success">Join Now</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Our Best Services Area */}
      <section className="bg-color-100f0f pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">
                    Join Our Partner Program
                  </h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>
                    {" "}
                    Know More <i className="flaticon-right" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>
      {/* FAQ */}
      <div className="faq-area ptb-100 bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-image-">
                <img src={paymentGetwayfaq} alt="faq" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        1 Why should you choose to be a part of the FintechDaddy
                        Partner program?
                      </Accordion.Header>
                      <Accordion.Body>
                        The Fintech Daddy Partner Program is the most rewarding
                        B2B affiliate program in India. As a Fintech Daddy
                        partner, you not only get to earn unbeatable commissions
                        but also offer a delightful and convenient digital
                        payments experience for your customers.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        2 How much can I earn with the FintechDaddy Partner
                        Program?
                      </Accordion.Header>
                      <Accordion.Body>
                        With the FintechDaddy Partner Program, you get paid on a
                        recurring basis, depending on how many transactions your
                        affiliates complete with Fintech Daddy.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        3 How much time does the partner onboarding take?
                      </Accordion.Header>
                      <Accordion.Body>
                        Once you submit the contact form, you will get a call
                        from our team.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        4 I am a FintechDaddy merchant, can I also register as
                        Partner?
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes, an existing FintechDaddy merchant can register as a
                        Partner as well. In such a scenario you will be managing
                        your merchant account and also get access to a sub
                        dashboard where you can add new customer accounts.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Affiliate;
