import React from "react";
import { NavLink } from "react-router-dom";
import logo from '../../assets/images/001.png';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/owl.carousel.min.css";
import "../../assets/css/owl.theme.default.min.css";
import "../../assets/css/magnific-popup.css";
import "../../assets/css/scrollcue.css";
import "../../assets/css/odometer.min.css";
import "../../assets/css/boxicons.min.css";
import "../../assets/css/flaticon.css";
import "../../assets/css/meanmenu.min.css";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";


function NavbarPage() {
  const token = localStorage.getItem("token");
  // alert(token);
  let url = "Integration";
  return (


    <div class="navbar-area fixed-top">
      <div class="mobile-responsive-nav">
        <div class="container">
          <div class="mobile-responsive-menu mean-container">
            <div className="mean-bar">
              <a href="#nav" className="meanmenu-reveal" id="toggle2" style={{ textAlign: "center", textIndent: 0, fontSize: 18 }}>
                <span>
                  <span>
                    <span />
                  </span>
                </span>
              </a>
            </div>

            <div class="logo">
              <NavLink className="navbar-brand" to="/">
                <img src={logo} class="main-logo" alt="logo" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div class="desktop-nav">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-md navbar-light">
            <NavLink className="navbar-brand" to="/">
              <img src={logo} class="main-logo" alt="logo"/>
            </NavLink>

            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto me-auto">
                <li class="nav-item">
                  <NavLink className="nav-link" to="/">
                    Home
                  </NavLink>
                </li>

                <li class="nav-item">
                  <a href="#" class="nav-link dropdown-toggle">
                    Products
                  </a>
                  <ul class="dropdown-menu two">
                    <div class="row">
                      <div class="col-lg-6">
                        <li class="nav-item">
                          <NavLink className="nav-link" to="/Payment-Gateway">Payment Gateway</NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink className="nav-link" to="/Payout">Payouts</NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink className="nav-link" to="/Payment-link">Payment Link</NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink className="nav-link" to="/Payment-Page">Payment Page</NavLink>
                        </li>
                      </div>
                      <div class="col-lg-6">

                        <li class="nav-item">
                          <NavLink className="nav-link" to="/Payment-Button">Payment Button</NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink className="nav-link" to="/Qr-code">QR Codes</NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink className="nav-link" to="/Invoice">Invoice</NavLink>
                        </li>
                        <li class="nav-item">
                          <NavLink className="nav-link" to="/Pay-now">Pay Now</NavLink>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>

                <li class="nav-item">
                  <a href="#" class="nav-link dropdown-toggle">
                    Partners
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <NavLink className="nav-link" to="/Affiliate">Affiliate</NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink className="nav-link" to="/Reseller">Reseller</NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink className="nav-link" to="/White-label">White label</NavLink>
                    </li>
                  </ul>
                </li>

                <li class="nav-item">
                  <a href={url} class="nav-link">
                    Developer
                  </a>
                </li>
              </ul>

              <div class="others-options d-flex align-items-center justify-content-around">
                <div class="others-options d-flex align-items-center">
                  <NavLink className="common-btn in me-3" to="https://overview.fintechdaddy.in/">Partner Overview</NavLink>
                  <NavLink className="common-btn in me-3" to="/SignIn">Sign In</NavLink>
                  <NavLink className="common-btn" to="/Signup">Sign Up </NavLink>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">Navbar scroll</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link href="#action1">Home</Nav.Link>
              <Nav.Link href="#action2">Link</Nav.Link>
              <NavDropdown title="Link" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#" disabled>
                Link
              </Nav.Link>
            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar> */}

      <div class="others-option-for-responsive">
        <div class="container">
          {/* <div class="dot-menu">
            <div class="inner">
              <div class="circle circle-one"></div>
              <div class="circle circle-two"></div>
              <div class="circle circle-three"></div>
            </div>
          </div> */}

          <div class="container">
            <div class="option-inner">
              <div class="others-options">
                <a href="https://overview.fintechdaddy.in/" class="common-btn me-3">Partner Overview</a>
                <a href="/SignIn" class="common-btn me-3">Sign In</a>
                <a href="/Signup" class="common-btn">Sign Up</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default NavbarPage;