import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import OwlCarousel from 'react-owl-carousel';
import paymentbuttonimg from '../../assets/images/Products/Payment Buttom/payment Button Image front.png';
import paymentbutton2 from '../../assets/images/Products/Payment Buttom/payment button 2.png';
import imagelogo1 from '../../assets/images/Products/Payment Gateway/Bank Logo/axis bank.png';
import imagelogo2 from '../../assets/images/Products/Payment Gateway/Bank Logo/hdfc bank.png';
import imagelogo3 from '../../assets/images/Products/Payment Gateway/Bank Logo/icici bank.png';
import imagelogo4 from '../../assets/images/Products/Payment Gateway/Bank Logo/idbi bank.png';
import imagelogo5 from '../../assets/images/Products/Payment Gateway/Bank Logo/indus bank.png';
import imagelogo6 from '../../assets/images/Products/Payment Gateway/Bank Logo/yes bank.png';
import paymentGetwayfaq from '../../assets/images/faq-img.png';

import paymentGetwaysimage1 from '../../assets/images/why1.png';
import paymentGetwaysimage2 from '../../assets/images/why2.png';
import paymentGetwaysimage3 from '../../assets/images/why3.png';
import paymentGetwaysimage4 from '../../assets/images/why4.png';
import paymentGetwaysimage5 from '../../assets/images/how.png';

import paymentGetwaysimageprocess1 from '../../assets/images/shape/graphic-shape-3.png';
import paymentGetwaysimageprocess2 from '../../assets/images/paying-img.png';
import paymentGetwaysimageprocess7 from '../../assets/images/shape/graphic-shape-2.png';
import paymentGetwaysimageUser2 from '../../assets/images/user/user-1.png';
import Accordion from 'react-bootstrap/Accordion';
import { NavLink } from "react-router-dom";
function Paymentbutton() {

  const sliderOptions = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      }
    }
  };

  const sliderItems = [
    { id: 1, image: imagelogo1 },
    { id: 2, image: imagelogo2 },
    { id: 3, image: imagelogo3 },
    { id: 4, image: imagelogo4 },
    { id: 5, image: imagelogo5 },
    { id: 6, image: imagelogo6 }
  ];



  const sliderOptions1 = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  const sliderItems1 = [
    { id: 1, image: paymentGetwaysimageUser2, text_data: 'Rahul', description_data: '"I love how easy it is to make a payment with the Fintech Daddy payment button on this website! I dont have to navigate to a separate payment page or enter a lot of information, which makes the checkout process a breeze."' },
    { id: 2, image: paymentGetwaysimageUser2, text_data: 'Sumitra', description_data: '"The customization options for the payment button on this website are great. I was able to easily match the color and design of the payment button to my brand, which helps to provide a more professional look and feel"'},
    { id: 3, image: paymentGetwaysimageUser2, text_data: 'Amit Sachdeva', description_data: '"I appreciate the security measures that are in place when using the Fintech Daddy payment button on this website. I feel confident that my payment information is being protected, which is important to me as a customer."' },
  ];

  console.warn(sliderItems1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

<Helmet>
    <meta charSet="utf-8" />
     <title>Accept Payment With FintechDaddy One-Click Secure Payment Button</title>
     <meta name="description" content="Integrate FintechDaddy payment button seamlessly into your website. Empower your
customers to make secure transactions with just a click."></meta>
</Helmet>

      <div className="experience-area pt-100 pb-70 bg-color-100f0f mt-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div>
                <div className="section-heading">
                  <h2 className="text-light">Accept Payment With FintechDaddy Payment Button</h2>
                  <p className="text-light">Payment Button is a Clickable Button on a Website or Payment Page That Enables Customers to Initiate a Payment Transaction.</p>
                </div>
                <div className="button-group gap-4">
                  <a className="banner-btn" href="/Signup">Know More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5" >
              <div className="experience-image" >
                <img className="experience-img" src={paymentbuttonimg} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* brands */}
      <div className="vbnmr-area pt-4 bg-color-5c5c5c">
        <div className="container">
          <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions}>
            {sliderItems.map(item => (
              <div className="sponsor-logo" key={item.id}>
                <img src={item.image} alt="image" />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>


      {/* <div className="vbnmr-area pt-100 pb-70 bg-color-5c5c5c">
      </div> */}
      {/* brands */}
      <div className="modern-graphic-area-one ptb-100 bg-dark">
        <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-6">
              <div className="graphics-img">
                <img className="paying-img" src={paymentGetwaysimageprocess2} alt="paying-img" data-cues="zoomIn" data-disabled="true" />
                <img className="graphics-img-1" src={paymentbutton2} alt="image" />
                {/* shape images */}
                {/* <img className="graphics-img-2 moveVertical" src={paymentGetwaysimageprocess4} alt="image" /> */}
                {/* <img className="graphics-img-3 moveHorizontal" src={paymentGetwaysimageprocess5} alt="image" /> */}
                {/* <img className="graphic-shape-1" src={paymentGetwaysimageprocess6} alt="image" /> */}
                <img className="graphic-shape-2 moveVertical" src={paymentGetwaysimageprocess7} alt="image" />
              </div>
            </div>

            <div className="col-lg-6 ps-md-5">
              <div className="modern-info">
                <div className="section-heading">
                  {/* <span className="sub-title">Subscriptions On Payment Button</span> */}
                  <h2 className="text-light">Say Hello To The Powerful Payment Button</h2>
                  <p className="text-light">Payment buttons can support multiple payment options such as credit cards, debit cards, e-wallets, and bank transfers, giving customers the flexibility to choose the payment method that works best for them.
                  </p>
                </div>
                {/* <ul>
                    <li>
                      <i className="flaticon-check" />Distinctively brand adaptive innovation
                    </li>
                    <li>
                      <i className="flaticon-check" />You need to be sure there isn't anything
                    </li>
                  </ul> */}
                <a className="common-btn two" href="/contact-us">Learn More</a>
                <img className="graphic-shape-3 rotate" src={paymentGetwaysimageprocess1} alt="image" />
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* ------------------------- */}

      <div className="client-say-area client-say-testimonials-area pt-100 pb-70  bg-color-100f0f">
        <div className="container">
          <div className="section-title">
            {/* <span class="subtitle">Our Testimonials</span> */}
            <h2 className="text-light">Payment Buttons Provide a Range of Benefits . Here are some key Benefits of Payment buttons</h2>
          </div>
          <div className="row range_benefits justify-content-center">
            <div className="col-lg-4" src="001.html">
              <div className="say-card">
                <h4 className="text-light">Convenience</h4>
                <p className="text-light">Payment buttons make it easy for customers to initiate a payment transaction quickly and conveniently. Customers can make a payment with just a few clicks.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="001.html">
              <div className="say-card">
                <h4 className="text-light">Improved Cash Flow</h4>
                <p className="text-light">Payment buttons enable businesses to collect payments more efficiently, which can improve cash flow and reduce payment processing time.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4" src="001.html">
              <div className="say-card">
                <h4 className="text-light">Increased Conversion Rates</h4>
                <p className="text-light">FintechDaddy can help to increase conversion rates by reducing friction in the payment process. By making it easy for customers to make a payment.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="001.html">
                <h4 className="text-light">Customization</h4>
                <p className="text-light">FintechDaddy Payment buttons can be customized to match a business's branding and website design. This can help to improve the user experience & increase brand recognition.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="say-card" src="001.html">
                <h4 className="text-light">Security</h4>
                <p className="text-light">FintechDaddy are typically integrated with payment processing software or payment gateways, which provide a high level of security for payment transactions.</p>
                <i className="flaticon-straight-quotes" />
              </div>
            </div>

          </div>
        </div>
      </div>



      {/* <div className="workflow-integration-area ptb-100 bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="task-img">
                <img className="task-img-1" src={paymentGetwaysimagetask1} alt="image" />
                <img className="task-shape-1" src={paymentGetwaysimagetaskshape1} alt="image" />
                <img className="task-img-2" src={paymentGetwaysimagetask2} alt="image" />
                <img className="task-img-3" src={paymentGetwaysimagetask3} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="history">
                <div className="section-heading">
                  <h2 className="text-light">Happy Customers </h2>
                </div>

                <OwlCarousel className="vbnmr-slider owl-carousel owl-theme" {...sliderOptions1}>
                  {sliderItems1.map(item => (

                    <div className="history-card d-flex align-items-center position-relative bg-color-5c5c5c">
                      <img className="flex-shrink-0 me-3" src={paymentGetwaysimageUser2} alt="image" />
                      <div className="warp">
                        <h4 className="text-light">{item.text_data}</h4>
                        <p className="text-light">{item.description_data}</p>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
     
        <img className="task-shape-2" src={paymentGetwaysimagetaskshape2} alt="image" />
        <img className="team-shape" src={paymentGetwaysimageteamShape} alt="image" />
      </div> */}

      <section className="bg-dark pt-100 pb-70 ">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="why-affiliate-content">
                <div className="section-header left-style getting">
                  <h2 className="text-light ms-2 fs-1">Join Our Partner Program</h2>
                  {/* <p>
                  When a user clicks on the affiliate link and makes a purchase, the affiliates earns a commission or reward. 

                  </p> */}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage1} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Joining free </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage2} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Instant Payout</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage3} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Performance Bonues</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-inner">
                      <div className="why-thumb">
                        <img src={paymentGetwaysimage4} alt="why" />
                      </div>
                      <div className="ms-2 why-content">
                        <h6 className="text-light">Unlimited affiliates</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="/Reseller" className="common-btn ms-2">
                  <span>  Know More <i className="flaticon-right" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={paymentGetwaysimage5} alt="why" />
            </div>
          </div>
        </div>
      </section>

      {/* FAQ */}
      <div className="faq-area ptb-100  bg-color-100f0f ">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-image-">
              <img src={paymentGetwayfaq} alt="faq" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="solution">
                <div className="section-heading">
                  <span className="subtitle">FAQ</span>
                  <h2 className="text-light">Frequently Asked Questions</h2>
                </div>
                <div className="faq-content">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is FintechDaddy payment button?</Accordion.Header>
                      <Accordion.Body>
                      FintechDaddy payment button is a clickable button that allows customers to make a payment directly from a website or app. It is typically designed to be simple and easy to use, with a clear call-to-action and minimal distractions.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How does FintechDaddy payment button work?</Accordion.Header>
                      <Accordion.Body>
                      When a customer clicks on payment button, they are typically directed to a payment gateway page where they can enter their payment information and complete the transaction. FintechDaddy Payment buttons can be used to accept various payment types such as credit cards, debit cards, e-wallets, and bank transfers.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What are the benefits of using FintechDaddy payment button?</Accordion.Header>
                      <Accordion.Body>
                      FintechDaddy Payment buttons can help businesses to increase conversions, improve the customer experience, and provide a more efficient and secure way for customers to make payments. 

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>What payment options can be accepted with FintechDaddy payment button?</Accordion.Header>
                      <Accordion.Body>
                      FintechDaddy Payment buttons can typically accept various payment types such as credit cards, debit cards, e-wallets, and bank transfers.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Is it safe to use FintechDaddy payment button?</Accordion.Header>
                      <Accordion.Body>
                      Yes, FintechDaddy payment button designed with security in mind, using encryption and other security measures to protect sensitive data such as credit card numbers and personal information. However.
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paymentbutton;