import React, { useEffect } from 'react';
import bannerpayment from '../../assets/images/banner1.jpg';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';
import { NavLink } from "react-router-dom";
class SignIn extends React.Component {
  constructor() {
    super()
    this.state = {
      email: null,
      password: null,
      'Payment Gateway': null,
    }
    this.validator = new SimpleReactValidator();
  }

  submit() {
    if (this.validator.allValid()) {
      let res = axios.post(process.env.REACT_APP_API_URL + "/login-partner", {
        method: "POST",
        // dataType: 'JSON',
        email: this.state.email,
        password: this.state.password,
        
        // data: { "email": this.state.email, "password": this.state.password },
      }).then((response) => {
        // console.warn(response.data.user.full_name);
       
        if (response.data.message == 'success') {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: <strong>Login Successfully!</strong>,
            icon: 'success',
            timer: 1500
          }).then((result) => {
            localStorage.setItem('token_store_merchant', response.data.access_token);
            localStorage.setItem('username', response.data.user.full_name);
            localStorage.setItem('MerchantOnboard', response.data.MerchantOnboard);
            localStorage.setItem('user',response.data.user.dropdown1);

            // console.log(url);
            if (response.data.user.dropdown1 == 'partner'){
              window.location.href = 'https://merchant.fintechdaddy.in';
            }
            else if(response.data.user.dropdown1 == 'payout'){
             window.location.href = '/';
            }
           
            else if(response.data.user.dropdown1 == 'affiliate'){
             window.location.href = 'https://affiliate.fintechdaddy.in';
            }
            else if(response.data.user.dropdown1 == 'reseller'){
             window.location.href = 'https://reseller.fintechdaddy.in';
            }
            else if(response.data.user.dropdown1 == 'White label partner'){
              window.location.href = '/';
            }
            // ,(this.email == null && this.password == null && this.dropdown1 == null)
            // window.location.href = '/';
          })
        } else {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: <strong>Invalid Details!</strong>,
            icon: 'error'
          })
        }
      });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();

    }

  }

  render() {
    return (
      <div>
        {/* Start Page Banner Area */}
        {/* <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12">
                <div className="page-banner-heading text-center">
                  <h1 style={{ color: 'white' }}>Login</h1>
                  <ul>
                    <li>
                      <NavLink className="nav-link active" to="/">Home</NavLink>
                      <a href="index.html">Home</a>
                    </li>
                    <li>Login</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* My Account */}
        <div className="profile-authentication-area ptb-70 bg-dark mt-5">
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-12 col-md-12">
                <div className="login-form bg-color-100f0f" style={{ backgroundColor: '#0a0a0a' }}>
                  <h2 className="text-light">Login</h2>
                  <div className='form'>
                    {/* <div className="form-group">
                    <label className="text-light">Username</label>
                    <input type="text" className="form-control" />
                  </div> */}
                    <div className="form-group">
                      <label className="text-light">Email or Phone</label>
                      <input type="text" className="form-control" name="email" onChange={(e) => this.setState({ email: e.target.value })} />
                      {this.validator.message('email', this.state.email, 'required|email')}
                    </div>
                    <div className="form-group">
                      <label className="text-light">Password</label>
                      <input type="text" className="form-control" password="password" onChange={(e) => this.setState({ password: e.target.value })} />
                      {this.validator.message('password', this.state.password, 'required')}

                    </div>
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" htmlFor="checkme">Remember me</label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 lost-your-password text-end">
                        <a href="#" className="lost-your-password">Forgot your password?</a>
                      </div>
                    </div>
                    <button type="button" className="default-btn" onClick={() => this.submit()}>Login</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* My Account */}
      </div >
    );
  }
}

export default SignIn;