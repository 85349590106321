import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import bannerpayment from '../../assets/images/banner1.jpg';
import { NavLink } from "react-router-dom";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [show, setShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleClose = () => {
    setShow(false);
    setIsSubmitted(false);
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: ''
    });
  };

  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {
    // You can perform any validation you want before submitting the form
    if (formData.name && formData.email && formData.message) {
      setIsSubmitted(true);
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FintechDaddy | Contact Support</title>
        <meta name="description" content="Contact FintechDaddy dedicated support team for Personalized assistance, expert
advice & reliable solutions"></meta>
      </Helmet>

      {/* Start Page Banner Area */}
      <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="page-banner-heading text-center">
                <h1 style={{ color: 'white' }}>Contact Us</h1>
                <ul>
                  <li>
                    <NavLink className="nav-link active" to="/">Home</NavLink>
                  </li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Banner Area */}
      <div className="experience-area pt-100 pb-4 bg-color-100f0f">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div>
                <div className="section-heading">
                  <p className="text-light">At FintechDaddy we're here to assist you with all your payment gateway needs. Contact our dedicated support team for personalized assistance and guidance tailored to your business requirements."
                  </p>
                  <div className="button-group gap-4 text-center">
                    <Button className="banner-btn" onClick={handleShow}>Contact Us</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal  */}
          <Modal show={show} onHide={handleClose} className='contact'>
            <Modal.Header closeButton className='bg-color-0b0808 border'>
              <Modal.Title className='text-light ps-3'>Contact Us</Modal.Title>
            </Modal.Header>
            <div className="profile-authentication-area">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="register-form modal-contact bg-color-0b0808">
                    <div className='form row'>
                      <div className="form-group col-md-12">
                        <label className="text-light">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="text-light">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label className="text-light">Phone</label>
                        <input
                          type="number"
                          className="form-control"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label className="text-light">Message</label>
                        <textarea
                          type="text"
                          className="form-control h-auto"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </div>
                      {isSubmitted ? (
                        <div className="form-group col-md-12">
                          <p className="text-success">Thank you for your message! We will get back to you soon.</p>
                        </div>
                      ) : (
                        <div className="form-group col-md-12">
                          <button type="submit" className="default-btn" onClick={handleSubmit}>Submit</button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* Modal close */}

          <p classname='text-center text-light'>or</p>
        </div>
      </div>

      <div className="best-service-area pb-70 bg-color-100f0f">
        <div className="container">
          <div className="">
            <h4 className="text-light mb-3">Contact Us Here:</h4>
            <p className='text-light fs-6'>FINTECHDADDY SOLUTIONS PRIVATE LIMITED</p>
            <p className='text-light fs-6'>1014/1, Shriram park, Khora Colony, Ghaziabad,
Ghaziabad, Uttar Pradesh, 201003</p>
            <p className='text-light fs-6'>Email Id:- <NavLink className='text-light' to="mailto:Gauravsingh@fintechdaddy.com">Gauravsingh@fintechdaddy.com</NavLink><br /></p>
            <p className='text-light fs-6'>Phone No. <NavLink className='text-light' to="tel:+91-8929253949">+91-8929253949</NavLink></p>
          </div>

        </div>
      </div>

    </div>
  );
}

export default ContactUs;