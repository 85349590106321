import React, { useEffect } from 'react';
import bannerpayment from '../../assets/images/banner1.jpg';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';

import { NavLink } from "react-router-dom";
class SignUp extends React.Component {

  constructor() {
    super()
    this.state = {
      email: null,
      password: null,
      reenterpassword: null,
      username: null,
      lastname: null,
      mobile: null,
      Product: null,
      dropdown2: null,
    }
    this.validator = new SimpleReactValidator();
  }
  submit() {
    // alert('demo');
    if (this.validator.allValid()) {
      let res = axios.post("https://api.fintechdaddy.in/all-register", {
        method: "POST",
        // dataType: 'JSON',
        email: this.state.email,
        password: this.state.password,
        reenterpassword: this.state.reenterpassword,
        full_name: this.state.username,
        mobile: this.state.mobile,
        lastname: this.state.lastname,
        dropdown1: this.state.Product,
        dropdown2: this.state.dropdown2,
      
        // data: { "email": this.state.email, "password": this.state.password },
      }).then((response) => {
        
        if (response.data.message == 'success') {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: <strong>Register Successfully!</strong>,
            icon: 'success',
            timer: 1500
          })
          .then((result) => {
            console.log(result);
            localStorage.setItem('token_store_merchant', response.data.access_token);
            localStorage.setItem('username', response.data.user.full_name);
           localStorage.setItem('user',response.data.user.dropdown1);

            console.log(response.data.user.dropdown1);
            if (response.data.user.dropdown1 == 'partner'){
              window.location.href = 'https://merchant.fintechdaddy.in';
            }
            else if(response.data.user.dropdown1 == 'payout'){
             window.location.href = '/';
            }
           
            else if(response.data.user.dropdown1 == 'affiliate'){
             window.location.href = 'https://affiliate.fintechdaddy.in';  
            }
            else if(response.data.user.dropdown1 == 'reseller'){
             window.location.href = 'https://reseller.fintechdaddy.in';
            }
            else if(response.data.user.dropdown1 == 'White label partner'){
              window.location.href = '/';
             }
           
          })
        }
         else {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: response.data.message,
            icon: 'error'
          })
        }
      });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();

    }

  }
  handleOptionChange = (event) => {
    this.setState({ Product: event.target.value });
  };
  render() {

  return (
    <div>
      {/* Start Page Banner Area */}
      {/* <div className="page-banner-area bg-color-f8f8f8" style={{ backgroundImage: `url(${bannerpayment})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="page-banner-heading text-center">
                <h1 style={{ color: 'white' }}>Sign Up</h1>
                <ul>
                  <li>
                    <NavLink className="nav-link active" to="/">Home</NavLink>
                  </li>
                  <li>Sign Up</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* My Account */}
      <div className="profile-authentication-area ptb-70 bg-dark mt-5">
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-12 col-md-12">
              <div className="register-form bg-color-0b0808">
                <h2 className="text-light">Register</h2>
                <div className='form'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="text-light">First Name</label>
                        <input type="text" className="form-control" onChange={(e) => this.setState({ username: e.target.value })} />
                        {this.validator.message('username', this.state.username, 'required')}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="text-light">Last Name</label>
                        <input type="text" className="form-control" onChange={(e) => this.setState({ lastname: e.target.value })} />
                        {this.validator.message('lastname', this.state.lastname, 'required')}

                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className="form-group">
                        <label className="text-light">Email</label>
                        <input type="text" className="form-control" onChange={(e) => this.setState({ email: e.target.value })}/>
                        {this.validator.message('email', this.state.email, 'required|email')}

                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="text-light">Password</label>
                        <input type="text" className="form-control" onChange={(e) => this.setState({ password: e.target.value })}/>
                        {this.validator.message('password', this.state.password, 'required')}

                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="text-light">Re Enter Password</label>
                        <input type="text" className="form-control"  onChange={(e) => this.setState({ reenterpassword: e.target.value })} />
                        {this.validator.message('reenterpassword', this.state.reenterpassword, 'required')}

                      </div>
                    </div>

                    

                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="text-light">Product</label>
                        <select className="form-select form-control py-0" value={this.state.Product}  onChange={this.handleOptionChange} id="dropdown" >
                          <option selected value="partner">Payment Gateway</option>
                          <option value="payout">Payout</option>
                          <option value="affiliate">Become an affiliate</option>
                          <option value="reseller"> Reseller</option>
                          <option>White label partner</option>
                        </select>
                        {this.validator.message('Product', this.state.Product, 'required')}

                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="text-light">Category</label>
                        <select className="form-select form-control py-0" onChange={(e) => this.setState({ dropdown2: e.target.value })}>
                          <option selected>Nature of business</option>
                          <option>E-commerce</option>
                          <option>Services</option>
                          <option>Skill Base Gaming</option>
                        </select>
                      </div>
                    </div>
 
                    <div className='col-md-6'>
                      <div className="form-group">
                        <label className="text-light">Mobile Number</label>
                        <input type="number" className="form-control" onChange={(e) => this.setState({ mobile: e.target.value })}/>
                        {this.validator.message('mobile', this.state.mobile, 'required')}

                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className="form-group my-4">
                        <input type="submit" className="btn default-btn" value={"Verify"} />
                      </div>
                    </div>
                    <button type="button" className="default-btn" onClick={() => this.submit()}>Register Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* My Account */}
      
    </div >
    
  );
  }
}

export default SignUp;